import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
const SERVER_URL = window.location.protocol + "//" + window.location.host;

function useTableHistory(init = {}) {
   const socket = useContext(SocketContext);
   const [tableHistory, setTableHistory] = useState([]);

   useEffect(() => {
      if (tableHistory) {
         // console.log("tableHistory", tableHistory);
      }
   }, [tableHistory]);

   const handlers = useMemo(
      () => ({
         // updateGlobal: (id, tournamentId) => {
         //    axios
         //       .get(SERVER_URL + "/api/updateGlobal/" + id + "/" + tournamentId)
         //       .then((response) => {
         //          handlers.getGlobals();
         //       });
         // },
         addTableGraphics: (p1id = null, p2id = null) => {
            socket.emit("addTableGraphics", p1id, p2id, (response) => {
               alert("H2H/Player Profiles Added!");
            });
         },
         getTableHistory: () => {
            socket.emit("getTableHistory", (response) => {
               setTableHistory(response);
            });
         },
         deleteTableHistoryRow: (e) => {
            const rowId = e.target.value;
            socket.emit("deleteTableHistoryRow", rowId, (response) => {
               console.log(response);
               handlers.getTableHistory();
            });
         },
      }),
      [socket]
   );

   return [tableHistory, handlers];
}

export default useTableHistory;
