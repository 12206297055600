import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import LoaderIcon from "../widgets/LoaderIcon";
import LoadingContentWrapper from "../widgets/LoadingContentWrapper";

import { useTheme } from "@mui/material/styles";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function Top8Player({
   player,
   index,
   wins = 0,
   winner = false,
   bracketPoints,
}) {
   const theme = useTheme();

   const variants = {
      enter: {
         x: 0,
         opacity: 1,
         transition: {
            when: "beforeChildren",
            staggerChildren: 0.1,
         },
      },
      exit: {
         x: 0,
         opacity: 0,
         transition: {
            when: "beforeChildren",
         },
      },
   };

   const flagVariants = {
      enter: {
         scale: 1,
         opacity: 1,
      },
      exit: {
         scale: 2,
         opacity: 0,
      },
   };

   const titleVariants = {
      enter: {
         x: 0,
         opacity: 1,
         transition: {
            easing: "easeOut",
         },
      },
      exit: {
         x: -20,
         opacity: 0,
      },
   };

   const deckVariants = {
      enter: {
         x: 0,
         opacity: 1,
         transition: {
            easing: "easeOut",
         },
      },
      exit: {
         x: -20,
         opacity: 0,
      },
   };

   const scoreVariants = {
      enter: {
         x: 1,
         opacity: 1,
         transition: {
            easing: "easeOut",
            when: "beforeChildren",
         },
      },
      exit: {
         x: 100,
         opacity: 0,
      },
   };

   return (
      <>
         {!player && (
            <Box
               variants={variants}
               component={motion.div}
               sx={{
                  pl: 2,
                  backgroundColor: "rgba(0,0,0,.3)",
                  outline: "1px solid #000",
                  height: 80,
                  width: 535,
                  position: "relative",
                  overflow: "hidden",
               }}
            />
         )}

         {player && (
            <Box
               variants={variants}
               component={motion.div}
               sx={{
                  pl: 2,
                  backgroundColor: index % 2 ? "#dedede" : "#e6e6e6",
                  height: 80,
                  width: 535,
                  position: "relative",
                  overflow: "hidden",
               }}
               key={player.PlayerName}
            >
               <Stack
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='center'
                  spacing={2}
                  sx={{
                     position: "relative",
                     height: "100%",
                     overflow: "hidden",
                  }}
               >
                  {player.CountryFlag && (
                     <Box
                        sx={{
                           width: 64,
                           height: 56,
                           background:
                              "url(" + player.CountryFlag + ") center center",
                           backgroundSize: "contain",
                           backgroundRepeat: "no-repeat",
                        }}
                        variants={flagVariants}
                        component={motion.div}
                     />
                  )}

                  <Stack
                     justifyContent='center'
                     alignItems='flex-start'
                     spacing={0}
                     sx={{ overflow: "hidden" }}
                  >
                     <Typography
                        variant='h4top8'
                        component='div'
                        variants={titleVariants}
                        component={motion.div}
                     >
                        {player.PlayerName}
                     </Typography>
                     <Typography
                        variant='h5top8'
                        component='div'
                        variants={deckVariants}
                        component={motion.div}
                     >
                        {player.DecklistArchetype}
                     </Typography>
                  </Stack>
               </Stack>

               {bracketPoints && (
                  <Box
                     sx={{
                        position: "absolute",
                        top: -1,
                        right: -1,
                        height: 82,
                        width: 90,
                        backgroundColor: winner ? "#e83411" : "#181818",
                        color: "#fff",
                        clipPath: "url(#wins-clip-path)",
                     }}
                     variants={scoreVariants}
                     component={motion.div}
                  >
                     <Stack
                        alignItems='flex-end'
                        justifyContent='center'
                        sx={{ width: 120, height: "100%" }}
                     >
                        <Typography
                           variant='h6top8'
                           component='div'
                           sx={{ pr: 7.2 }}
                           variants={titleVariants}
                           component={motion.div}
                        >
                           {wins}
                        </Typography>
                     </Stack>
                  </Box>
               )}
            </Box>
         )}
      </>
   );
}
