import React, { useState, useContext, useEffect } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Wrapper from "../components/layout/Wrapper";
import UrlToCopy from "../components/widgets/UrlToCopy";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function UrlsControl() {
   return (
      <Wrapper>
         <Stack spacing={2}>
            <Typography variant='h3' component='div'>
               Viewer Control URLs for vMix/Companion:
            </Typography>

            <Stack spacing={2}>
               <Typography variant='h4' component='div' sx={{ pt: 4 }}>
                  LED Bracket
               </Typography>
               <UrlToCopy
                  title='Show'
                  smallTitle
                  path='/api/control/ledbracket/show'
                  open={false}
               />
               <UrlToCopy
                  title='Hide'
                  smallTitle
                  path='/api/control/ledbracket/hide'
                  open={false}
               />
               <UrlToCopy
                  title='Toggle'
                  smallTitle
                  path='/api/control/ledbracket/toggle'
                  open={false}
               />

               <Typography variant='h4' component='div' sx={{ pt: 4 }}>
                  LED Players
               </Typography>
               <UrlToCopy
                  title='Show'
                  smallTitle
                  path='/api/control/ledplayers/show'
                  open={false}
               />
               <UrlToCopy
                  title='Hide'
                  smallTitle
                  path='/api/control/ledplayers/hide'
                  open={false}
               />
               <UrlToCopy
                  title='Toggle'
                  smallTitle
                  path='/api/control/ledplayers/toggle'
                  open={false}
               />

               <Typography variant='h4' component='div' sx={{ pt: 4 }}>
                  LED Versus
               </Typography>
               <UrlToCopy
                  title='Show'
                  smallTitle
                  path='/api/control/ledversus/show'
                  open={false}
               />
               <UrlToCopy
                  title='Hide'
                  smallTitle
                  path='/api/control/ledversus/hide'
                  open={false}
               />
               <UrlToCopy
                  title='Toggle'
                  smallTitle
                  path='/api/control/ledversus/toggle'
                  open={false}
               />

               <Typography variant='h4' component='div' sx={{ pt: 4 }}>
                  LED Versus Top 8
               </Typography>
               <UrlToCopy
                  title='Show'
                  smallTitle
                  path='/api/control/ledtop8/show'
                  open={false}
               />
               <UrlToCopy
                  title='Hide'
                  smallTitle
                  path='/api/control/ledtop8/hide'
                  open={false}
               />
               <UrlToCopy
                  title='Toggle'
                  smallTitle
                  path='/api/control/ledtop8/toggle'
                  open={false}
               />

               <Typography variant='h4' component='div' sx={{ pt: 4 }}>
                  Storyboards
               </Typography>
               <UrlToCopy
                  title='Viewer 1 - Next Slide'
                  smallTitle
                  path='/api/control/customleaderboard1/next'
                  open={false}
               />
               <UrlToCopy
                  title='Viewer 1 - Previous Slide'
                  smallTitle
                  path='/api/control/customleaderboard1/prev'
                  open={false}
               />
               <UrlToCopy
                  title='Viewer 1 - Back to First Slide'
                  smallTitle
                  path='/api/control/customleaderboard1/first'
                  open={false}
               />
               <UrlToCopy
                  title='Viewer 2 - Next Slide'
                  smallTitle
                  path='/api/control/customleaderboard2/next'
                  open={false}
               />
               <UrlToCopy
                  title='Viewer 2 - Previous Slide'
                  smallTitle
                  path='/api/control/customleaderboard2/prev'
                  open={false}
               />
               <UrlToCopy
                  title='Viewer 2 - Back to First Slide'
                  smallTitle
                  path='/api/control/customleaderboard2/first'
                  open={false}
               />

               <Typography variant='h4' component='div' sx={{ pt: 4 }}>
                  Feature Cards
               </Typography>
               <UrlToCopy
                  title='Show'
                  smallTitle
                  path='/api/control/featurecards/show'
                  open={false}
               />
               <UrlToCopy
                  title='Hide'
                  smallTitle
                  path='/api/control/featurecards/hide'
                  open={false}
               />
               <UrlToCopy
                  title='Toggle'
                  smallTitle
                  path='/api/control/featurecards/toggle'
                  open={false}
               />
               <UrlToCopy
                  title='Next Card Group'
                  smallTitle
                  path='/api/control/featurecards/next'
                  open={false}
               />
               <UrlToCopy
                  title='Previous Card Group'
                  smallTitle
                  path='/api/control/featurecards/prev'
                  open={false}
               />

               <Typography variant='h4' component='div' sx={{ pt: 4 }}>
                  Card Overlay
               </Typography>
               <UrlToCopy
                  title='Show'
                  smallTitle
                  path='/api/control/cardoverlay/show'
                  open={false}
               />
               <UrlToCopy
                  title='Hide'
                  smallTitle
                  path='/api/control/cardoverlay/hide'
                  open={false}
               />
               <UrlToCopy
                  title='Toggle'
                  smallTitle
                  path='/api/control/cardoverlay/toggle'
                  open={false}
               />

               <Typography variant='h4' component='div' sx={{ pt: 4 }}>
                  Top 8 Bracket
               </Typography>
               <UrlToCopy
                  title='Show'
                  smallTitle
                  path='/api/control/bracket/show'
                  open={false}
               />
               <UrlToCopy
                  title='Hide'
                  smallTitle
                  path='/api/control/bracket/hide'
                  open={false}
               />
               <UrlToCopy
                  title='Toggle'
                  smallTitle
                  path='/api/control/bracket/toggle'
                  open={false}
               />

               <Typography variant='h4' component='div' sx={{ pt: 4 }}>
                  Top 8 Moving On
               </Typography>
               <UrlToCopy
                  title='Show'
                  smallTitle
                  path='/api/control/top8/show'
                  open={false}
               />
               <UrlToCopy
                  title='Hide'
                  smallTitle
                  path='/api/control/top8/hide'
                  open={false}
               />
               <UrlToCopy
                  title='Toggle'
                  smallTitle
                  path='/api/control/top8/toggle'
                  open={false}
               />

               <Typography variant='h4' component='div' sx={{ pt: 4 }}>
                  Round by Round
               </Typography>
               <UrlToCopy
                  title='Show'
                  smallTitle
                  path='/api/control/roundbyround/show'
                  open={false}
               />
               <UrlToCopy
                  title='Hide'
                  smallTitle
                  path='/api/control/roundbyround/hide'
                  open={false}
               />
               <UrlToCopy
                  title='Toggle'
                  smallTitle
                  path='/api/control/roundbyround/toggle'
                  open={false}
               />

               <Typography variant='h4' component='div' sx={{ pt: 4 }}>
                  Head to Head
               </Typography>
               <UrlToCopy
                  title='Show'
                  smallTitle
                  path='/api/control/headtohead1/show'
                  open={false}
               />
               <UrlToCopy
                  title='Hide'
                  smallTitle
                  path='/api/control/headtohead1/hide'
                  open={false}
               />
               <UrlToCopy
                  title='Toggle'
                  smallTitle
                  path='/api/control/headtohead1/toggle'
                  open={false}
               />
               <UrlToCopy
                  title='Show'
                  smallTitle
                  path='/api/control/headtohead2/show'
                  open={false}
               />
               <UrlToCopy
                  title='Hide'
                  smallTitle
                  path='/api/control/headtohead2/hide'
                  open={false}
               />
               <UrlToCopy
                  title='Toggle'
                  smallTitle
                  path='/api/control/headtohead2/toggle'
                  open={false}
               />

               <Typography variant='h4' component='div' sx={{ pt: 4 }}>
                  Player Profile Output 1
               </Typography>
               <UrlToCopy
                  title='Show'
                  smallTitle
                  path='/api/control/playerprofile/show/1'
                  open={false}
               />
               <UrlToCopy
                  title='Hide'
                  smallTitle
                  path='/api/control/playerprofile/hide/1'
                  open={false}
               />
               <UrlToCopy
                  title='Toggle'
                  smallTitle
                  path='/api/control/playerprofile/toggle/1'
                  open={false}
               />

               <Typography variant='h4' component='div' sx={{ pt: 4 }}>
                  Player Profile Output 2
               </Typography>
               <UrlToCopy
                  title='Show'
                  path='/api/control/playerprofile/show/2'
                  open={false}
               />
               <UrlToCopy
                  title='Hide'
                  path='/api/control/playerprofile/hide/2'
                  open={false}
               />
               <UrlToCopy
                  title='Toggle'
                  path='/api/control/playerprofile/toggle/2'
                  open={false}
               />
            </Stack>
         </Stack>
      </Wrapper>
   );
}
