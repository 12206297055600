import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function useXpression() {
   const socket = useContext(SocketContext);

   const [xpressionGlobals, setXpressionGlobals] = useState();
   const [allPlayers, setAllPlayers] = useState([]);
   const [fMPlayers, setFMPlayers] = useState([]);
   const [allCards, setAllCards] = useState([]);
   const [tickerData, setTickerData] = useState([]);
   const [featureCardGroups, setFeatureCardGroups] = useState([]);
   const [allStoryboards, setAllStoryboards] = useState([]);
   const [allRounds, setAllRounds] = useState([]);

   const [gameState, setGameState] = useState();
   const [players, setPlayers] = useState();

   useEffect(() => {
      handlers.getAllPlayerData();
      handlers.getAllCards();
      handlers.getTickerPlayerData();
      handlers.getFeatureCardGroups();
      handlers.getXpressionGlobals();
      handlers.getAllStoryboards();
      handlers.getAllRounds();
   }, []);

   useEffect(() => {
      // console.log(gameState);
   }, [gameState]);

   useEffect(() => {
      // console.log(allRounds);
   }, [allRounds]);

   useEffect(() => {
      // console.log(players);
   }, [players]);

   const handlers = useMemo(
      () => ({
         getAllPlayerData: () => {
            socket.emit("getAllPlayerData", null, (response) => {
               setAllPlayers(response);
            });
         },
         getAllCards: () => {
            socket.emit("getAllCardNames", (response) => {
               setAllCards(response);
            });
         },
         getTickerPlayerData: () => {
            socket.emit("getXpressionTickerPlayerData", (response) => {
               setTickerData(response);
            });
         },
         getAllRounds: () => {
            socket.emit("getTournamentRounds", (response) => {
               setAllRounds(response);
            });
         },
         getAllStoryboards: () => {
            socket.emit("getAllCustomLeaderboard", (response) => {
               setAllStoryboards(response);
            });
         },
         getFeatureCardGroups: () => {
            socket.emit("getXpressionFeatureCardGroups", (response) => {
               setFeatureCardGroups(response);
            });
         },
         getXpressionGlobals: () => {
            socket.emit("getXpressionGlobals", (response) => {
               setXpressionGlobals(response);
            });
         },
         addXpressionCard: (id, card) => {
            socket.emit("addXpressionCard", id, card, (response) => {
               setFeatureCardGroups(response);
            });
         },
         deleteXpressionCard: (parentRowId, index) => {
            socket.emit(
               "deleteXpressionCard",
               parentRowId,
               index,
               (response) => {
                  setFeatureCardGroups(response);
               }
            );
         },
         setXpressionFeaturedCards: (id) => {
            socket.emit(
               "updateXpressionGlobals",
               { selectedFeaturedCards: id },
               (response) => {
                  setXpressionGlobals(response);
                  handlers.getFeatureCardGroups();
               }
            );
         },
         addXpressionFeaturedGroup: () => {
            socket.emit("addXpressionFeaturedGroup", (response) => {
               setFeatureCardGroups(response);
            });
         },
         deleteXpressionFeaturedGroup: (id) => {
            socket.emit("deleteXpressionFeaturedGroup", id, (response) => {
               setFeatureCardGroups(response);
            });
         },
         updateXpressionGlobals: (updates) => {
            socket.emit("updateXpressionGlobals", updates, (response) => {
               setXpressionGlobals(response);
               handlers.getTickerPlayerData();
            });
         },
         setXpressionTable: (data) => {
            socket.emit("setXpressionTable", data, (response) => {
               console.log(response);
               handlers.getXpressionFMPlayers();
            });
         },
         getXpressionFMPlayers: () => {
            socket.emit("getXpressionFMPlayers", (response) => {
               setFMPlayers(response);
            });
         },
         subscribeFMPlayers: (tableId) => {
            socket.emit("subscribeFMPlayers", (response) => {
               // setGameState((prevState) => {
               //    let newState = { ...prevState };
               //    newState[tableId] = response.gamestate;
               //    return newState;
               // });

               console.log(response);

               // setPlayers((prevState) => {
               //    let newState = { ...prevState };
               //    newState[tableId] = response.players;
               //    return newState;
               // });
            });

            socket.on("dataUpdate", (data) => {
               console.log(data);

               //                if (data.tableId == tableId) {
               //                   // if (data.type === "gamestate" && data.json) {
               //                   //    setGameState((prevState) => {
               //                   //       let newState = { ...prevState };
               //                   //       newState[tableId] = data.json;
               //                   //       return newState;
               //                   //    });
               //                   // }
               //
               //                   if (data.type === "players" && data.json) {
               //                      setPlayers((prevState) => {
               //                         let newState = { ...prevState };
               //                         newState[tableId] = data.json;
               //                         return newState;
               //                      });
               //                   }
               //                }
            });
         },
      }),
      []
   );

   return [
      xpressionGlobals,
      allStoryboards,
      allRounds,
      allPlayers,
      allCards,
      tickerData,
      featureCardGroups,
      fMPlayers,
      players,
      handlers,
   ];
}
