import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import PlayerPanel from "../../components/ledversus/PlayerPanel";

import LEDWrapperViewer from "../../components/layout/LEDWrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

//HOOKS
import useLEDVersusTop8 from "../../hooks/useLEDVersusTop8";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function LEDVersusViewer() {
   const variants = {
      enter: {
         opacity: 1,
         transition: {
            easing: "easeOut",
            duration: 1,
            staggerChildren: 0.08,
         },
      },
      exit: {
         duration: 0.5,
         opacity: 0,
      },
   };

   const lineVariants = {
      enter: {
         opacity: 1,
         transition: {
            easing: "easeOut",
         },
      },
      exit: {
         opacity: 0,
      },
   };

   // USE HOOK
   const [
      title,
      allPlayers,
      selectedPlayers,
      show,
      { getSelectedPlayers, showHide, subscribeLEDVersusTop8Viewer },
   ] = useLEDVersusTop8();

   useEffect(() => {
      subscribeLEDVersusTop8Viewer();
      getSelectedPlayers();
   }, []);

   useEffect(() => {
      console.log(selectedPlayers);
   }, [selectedPlayers]);

   return (
      <LEDWrapperViewer>
         <AnimatePresence>
            {selectedPlayers && (
               <Stack
                  spacing={0}
                  direction='row'
                  alignItems='center'
                  sx={{
                     height: 800,
                     width: "100%",
                  }}
               >
                  <Box
                     sx={{
                        position: "relative",
                        width: 435,
                        boxSizing: "border-box",
                        overflow: "hidden",
                     }}
                     key={"panel1"}
                     variants={variants}
                     initial='exit'
                     exit='exit'
                     animate={show ? "enter" : "exit"}
                     component={motion.div}
                  >
                     {selectedPlayers && selectedPlayers[0] && (
                        <PlayerPanel selectedPlayer={selectedPlayers[0]} />
                     )}
                  </Box>

                  <Box
                     sx={{
                        position: "relative",
                        width: 435,
                        boxSizing: "border-box",
                        overflow: "hidden",
                     }}
                     key={"panel2"}
                     variants={variants}
                     initial='exit'
                     exit='exit'
                     animate={show ? "enter" : "exit"}
                     component={motion.div}
                  >
                     <Stack
                        alignItems='center'
                        spacing={6}
                        sx={{ width: "100%" }}
                     >
                        <Typography
                           variant='h4Viewer'
                           sx={{
                              textTransform: "uppercase",
                              textAlign: "center",
                              lineHeight: "1em",
                              width: "100%",
                              overflow: "hidden",
                           }}
                        ></Typography>
                     </Stack>
                  </Box>

                  <Box
                     sx={{
                        position: "relative",
                        width: 435,
                        boxSizing: "border-box",
                        overflow: "hidden",
                     }}
                     key={"logo"}
                  >
                     <Stack
                        alignItems='center'
                        spacing={6}
                        sx={{ width: "100%" }}
                     >
                        <img
                           src={SERVER_URL + "/images/ui/logo-800.png"}
                           width='60%'
                        />

                        <Typography
                           variant='h3Viewer'
                           sx={{
                              textTransform: "uppercase",
                              textAlign: "center",
                              lineHeight: "1em",
                              fontSize: "3.2em",
                              width: "100%",
                              overflow: "hidden",
                           }}
                        >
                           {title}
                        </Typography>
                     </Stack>
                  </Box>

                  <Box
                     sx={{
                        position: "relative",
                        width: 435,
                        boxSizing: "border-box",
                        overflow: "hidden",
                     }}
                     key={"panel4"}
                     variants={variants}
                     initial='exit'
                     exit='exit'
                     animate={show ? "enter" : "exit"}
                     component={motion.div}
                  >
                     <Stack
                        alignItems='center'
                        spacing={6}
                        sx={{ width: "100%" }}
                     >
                        <Typography
                           variant='h4Viewer'
                           sx={{
                              textTransform: "uppercase",
                              textAlign: "center",
                              lineHeight: "1em",
                              width: "100%",
                              overflow: "hidden",
                           }}
                        ></Typography>
                     </Stack>
                  </Box>

                  <Box
                     sx={{
                        position: "relative",
                        width: 435,
                        boxSizing: "border-box",
                        overflow: "hidden",
                     }}
                     key={"panel5"}
                     variants={variants}
                     initial='exit'
                     exit='exit'
                     animate={show ? "enter" : "exit"}
                     component={motion.div}
                  >
                     {selectedPlayers && selectedPlayers[1] && (
                        <PlayerPanel selectedPlayer={selectedPlayers[1]} />
                     )}
                  </Box>
               </Stack>
            )}
         </AnimatePresence>
      </LEDWrapperViewer>
   );
}
