import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Wrapper from "../components/layout/Wrapper";
import logo from "../images/evoke.svg";

const SERVER_URL = window.location.protocol + "//" + window.location.host;
const TIMECLOCK_PORT = process.env.REACT_APP_TIMECLOCK_PORT;

export default function LandingPage() {
   let navigate = useNavigate();

   return (
      <>
         <Stack
            sx={{
               width: "100%",
               minHeight: 64,
               p: 2,
               backgroundColor: "#001833",
            }}
            alignItems='flex-start'
            justifyContent='center'
         >
            <img src={logo} style={{ width: 300, marginLeft: 10 }} />
         </Stack>
         <Wrapper>
            <Grid
               container
               spacing={2}
               sx={{ width: "100%", fontSize: "1.5vh" }}
            >
               <Grid item xs={12} lg={8}>
                  <Grid
                     container
                     spacing={2}
                     sx={{ width: "100%", fontSize: "1.5vh" }}
                  >
                     <Grid item xs={12}>
                        <Typography
                           variant='h3'
                           component='div'
                           sx={{ textTransform: "uppercase", mt: 4 }}
                        >
                           FEATURE MATCHES
                        </Typography>
                     </Grid>
                     <Grid item xs={6}>
                        <Stack spacing={2}>
                           <Button
                              onClick={() => navigate("/ipad/1")}
                              variant='ipadLarge'
                           >
                              iPad FM
                              <br /> Table A
                           </Button>
                           <Button
                              onClick={() => navigate("/ipad/2")}
                              variant='ipadLarge'
                           >
                              iPad FM
                              <br /> Table B
                           </Button>
                           <Button
                              onClick={() => navigate("/ipad/3")}
                              variant='ipadLarge'
                           >
                              iPad FM
                              <br /> Table C
                           </Button>
                           <Button
                              onClick={() => navigate("/ipad/4")}
                              variant='ipadLarge'
                           >
                              iPad FM
                              <br /> Table D
                           </Button>
                        </Stack>
                     </Grid>
                     <Grid item xs={6}>
                        <Stack spacing={2}>
                           <Button
                              onClick={() => navigate("/featurematches")}
                              variant='ipadLarge'
                              color='secondary'
                           >
                              Feature Match
                              <br />
                              Admin
                           </Button>
                           <Button
                              onClick={() => navigate("/cardsinhand/1/1")}
                              variant='ipadLarge'
                              color='secondary'
                           >
                              Cards In
                              <br /> Hand 1
                           </Button>
                           <Button
                              onClick={() => navigate("/cardsinhand/1/2")}
                              variant='ipadLarge'
                              color='secondary'
                           >
                              Cards In
                              <br /> Hand 2
                           </Button>
                           <Button
                              onClick={() => navigate("/draft/1")}
                              variant='ipadLarge'
                              color='admin'
                           >
                              Draft
                              <br />
                              Admin
                           </Button>
                           <Button
                              onClick={() => navigate("/draftipad/1")}
                              variant='ipadLarge'
                              color='admin'
                           >
                              iPad Draft
                              <br />
                              Picker
                           </Button>
                           {/* <Button
                              onClick={() => navigate("/featurematch/1")}
                              variant='ipadLarge'
                              color='secondary'
                           >
                              Feature
                              <br /> Match A
                           </Button>
                           <Button
                              onClick={() => navigate("/featurematch/2")}
                              variant='ipadLarge'
                              color='secondary'
                           >
                              Feature
                              <br />
                              Match B
                           </Button>
                           <Button
                              onClick={() => navigate("/featurematch/3")}
                              variant='ipadLarge'
                              color='secondary'
                           >
                              Feature
                              <br /> Match C
                           </Button>
                           <Button
                              onClick={() => navigate("/featurematch/4")}
                              variant='ipadLarge'
                              color='secondary'
                           >
                              Feature
                              <br /> Match D
                           </Button> */}
                        </Stack>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item xs={12} lg={4}>
                  <Grid
                     container
                     spacing={2}
                     sx={{ width: "100%", fontSize: "1.5vh" }}
                  >
                     <Grid item xs={12}>
                        <Typography
                           variant='h3'
                           component='div'
                           sx={{ textTransform: "uppercase", mt: 4 }}
                        >
                           Tournament Ops
                        </Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <Stack spacing={2}>
                           <Button
                              href={SERVER_URL + ":" + TIMECLOCK_PORT}
                              variant='ipadLarge'
                              color='tertiary'
                              sx={{ width: "100%" }}
                           >
                              Time Clock
                           </Button>
                        </Stack>
                     </Grid>

                     <Grid item xs={12}>
                        <Typography
                           variant='h3'
                           component='div'
                           sx={{ textTransform: "uppercase", mt: 4 }}
                        >
                           Graphics Producers
                        </Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <Stack spacing={2}>
                           <Button
                              onClick={() => navigate("/overlaymanager")}
                              variant='ipadLarge'
                              color='primary'
                              sx={{ width: "100%" }}
                           >
                              Card Overlay
                           </Button>
                           <Button
                              onClick={() => navigate("/contentbuilder")}
                              variant='ipadLarge'
                              color='primary'
                              sx={{ width: "100%" }}
                           >
                              Content Builder
                           </Button>
                           <Button
                              onClick={() => navigate("/xpression")}
                              variant='ipadLarge'
                              color='primary'
                              sx={{ width: "100%" }}
                           >
                              Xpression
                           </Button>
                        </Stack>
                     </Grid>
                  </Grid>
               </Grid>

               <Grid item xs={12}>
                  <Typography
                     variant='h3'
                     component='div'
                     sx={{ textTransform: "uppercase", mt: 4 }}
                  >
                     Administrators
                  </Typography>
               </Grid>

               <Grid item xs={12}>
                  <Button
                     onClick={() => navigate("/producer")}
                     variant='ipadLarge'
                     color='producer'
                     sx={{ width: "100%" }}
                  >
                     Producer
                  </Button>
               </Grid>

               <Grid item xs={12}>
                  <Button
                     onClick={() => navigate("/admin")}
                     variant='ipadLarge'
                     color='admin'
                     sx={{ width: "100%" }}
                  >
                     Admin
                  </Button>
               </Grid>
            </Grid>
         </Wrapper>
      </>
   );
}
