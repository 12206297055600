import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import PlayerSelect from "./PlayerSelect";
import AdjustDraftTitle from "./AdjustDraftTitle";
import AdjustValue from "./AdjustValue";

import ManaIconsFormatted from "../../components/decklists/ManaIconsFormatted";
import CardImage from "../../components/draft/CardImage";

import ReplaceCard from "../../components/draft/ReplaceCard";

import DraftViewer from "../../routes/viewers/DraftViewer";

//HOOKS
import useDraftHandler from "../../hooks/useDraftHandler";
import useCardHandler from "../../hooks/useCardHandler";
import useGlobals from "../../hooks/useGlobals";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   width: "80%",
   height: "80vh",
   overflowY: "auto",
   transform: "translate(-50%, -50%)",
   bgcolor: "#151515",
   border: "2px solid #333",
   userSelect: "text",
   color: "#fff",
   boxShadow: 24,
   p: 4,
   textAlign: "center",
   zIndex: 1000,
};

export default function DashboardDraftTable({ draftId }) {
   const columnWidth = useRef();
   const [previewOverlay, setPreviewOverlay] = useState(false);
   const [showDecklistExport, setShowDecklistExport] = useState(false);
   const [replaceCard, setReplaceCard] = useState(null);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   // USE HOOKS
   const [globals, { getGlobals }] = useGlobals();

   const [
      player,
      allPlayers,
      allCardNames,
      gameState,
      picks,
      update,
      lastUpdated,
      data,
      loaded,
      setLoaded,
      visible,
      rotation,
      {
         subscribeDraft,
         resetDraft,
         refreshFMPhotos,
         getAllPlayers,
         addPick,
         show,
         removePick,
         replacePick,
         getAllCardNames,
         toggleDraftVisibility,
         toggleDraftRotation,
         draftOverlayDataLoad,
      },
   ] = useDraftHandler();

   const [allCards, notification, { getAllCards }] = useCardHandler();

   const [recent, setRecent] = useState([]);
   const [searchValue, setSearchValue] = useState("");
   const [searchResults, setSearchResults] = useState([]);
   const [filterSets, setFilterSets] = useState(true);
   const [draftFormats, setDraftFormats] = useState();
   const [decklistExport, setDecklistExport] = useState("");

   // MODAL
   const [openRoundTitle, setOpenRoundTitle] = useState(false);
   const handleOpenRoundTitle = () => setOpenRoundTitle(true);
   const handleCloseRoundTitle = () => setOpenRoundTitle(false);

   useEffect(() => {
      subscribeDraft(1);
      getAllPlayers();
      getAllCardNames();
      getGlobals();
   }, []);

   useEffect(() => {
      const formats = _.find(globals, { id: "DraftSets" });
      if (formats) {
         setDraftFormats(_.sortBy(formats.json));
      }
   }, [globals]);

   useEffect(() => {
      // console.log(allPlayers);
   }, [allPlayers]);

   useEffect(() => {
      // console.log(player);
   }, [player]);

   useEffect(() => {
      // console.log(picks);
   }, [picks]);

   const handleResetDraft = () => {
      if (window.confirm("Are you sure you want to reset the Draft?")) {
         resetDraft(draftId);
      }
   };

   const handleTextfieldKeyPress = (e) => {
      if (e.keyCode === 13) {
         e.target.blur();
      }
   };

   const updateRoundTitle = (value) => {
      update(1, { ["RoundTitle"]: value });
      handleCloseRoundTitle();
   };

   const handleAddPick = (draftId, card) => {
      if (card.CardName != "-") {
         draftOverlayDataLoad({
            type: "image",
            value: card.FileName,
         });
      }
      addPick(draftId, card);
   };

   useEffect(() => {
      if (searchValue && searchValue.length >= 3) {
         const search = (searchValue) =>
            allCardNames.filter(
               ({ CardSearchString, Set }) =>
                  CardSearchString.toLowerCase().includes(
                     searchValue.toLowerCase().replace(/[^A-Za-z0-9]/g, "")
                  ) &&
                  (filterSets ? draftFormats.includes(Set.toLowerCase()) : true)
            );
         let result = search(searchValue);
         result = _.orderBy(result, ["CardName"], ["asc"]);

         setSearchResults(result.slice(0, 30));
      }
   }, [searchValue]);

   const addToRecent = (card) => {
      if (card.type === "image") {
         setRecent((prevState) => {
            let oldState = [...prevState];
            let newState;

            const exists = _.find(oldState, function (row) {
               return row.cardName === card.cardName;
            });

            if (!exists) {
               newState = [card, ...oldState];
            } else {
               newState = oldState;
            }

            newState.map((row, i) => {
               row.id = i;
               return row;
            });
            return newState.slice(0, 14);
         });
      }
   };

   const showRecent = () => {
      let recents = recent.map((row) => {
         row = row.card;
         return row;
      });

      recents = _.orderBy(recents, ["CardName"], ["asc"]);
      setSearchResults(recents);
   };

   const clearSearch = () => {
      setSearchValue("");
   };

   const handleSearch = (e) => {
      setSearchValue(e.target.value);
   };

   const toggleFilterSets = () => {
      setFilterSets(!filterSets);
   };

   const exportDraftPicks = () => {
      let decklist = _.chain(picks)
         .map((row) => ({
            id: row.id,
            name: row.card.CardName,
            set: row.card.Set,
         }))
         .orderBy(["id"], ["asc"])
         .value();

      // let decklist = _.chain(picks)
      //    .map("card")
      //    .groupBy("CardName")
      //    .map((cards, name) => ({ cards, name }))
      //    .orderBy(["name"], ["asc"])
      //    .value();
      //
      //       decklist.map((row) => {
      //          row.qty = row.cards.length;
      //          return row;
      //       });

      let decklistExport = "PICKS\n";
      decklist.map((row, i) => {
         decklistExport += i + 1 + ": " + row.name + "\n";
      });

      setDecklistExport(decklistExport);
      showExport();
   };

   const showExport = () => {
      setShowDecklistExport(true);
   };

   const hideExport = () => {
      setShowDecklistExport(false);
   };

   return (
      <Box sx={{ p: 4 }}>
         <Grid container spacing={4}>
            <Grid item xs={2}>
               {gameState && (
                  <Stack spacing={4}>
                     <Stack spacing={2}>
                        <Box sx={{ backgroundColor: "#fff", p: 2 }}>
                           <PlayerSelect
                              allPlayersList={allPlayers}
                              selected={gameState.PlayerId}
                              draftId={draftId}
                              update={update}
                           />
                        </Box>
                        <Button
                           onClick={() => {
                              update(draftId, {
                                 visibleCardsInHand:
                                    !gameState.visibleCardsInHand,
                              });

                              toggleDraftVisibility(false);
                           }}
                           variant={
                              gameState.visibleCardsInHand
                                 ? "contained"
                                 : "outlined"
                           }
                           color={
                              gameState.visibleCardsInHand ? "success" : "alert"
                           }
                        >
                           Draft Picks
                        </Button>

                        <Button
                           onClick={() =>
                              update(draftId, {
                                 visibleScoreBars: !gameState.visibleScoreBars,
                              })
                           }
                           variant={
                              gameState.visibleScoreBars
                                 ? "contained"
                                 : "outlined"
                           }
                           color={
                              gameState.visibleScoreBars ? "success" : "alert"
                           }
                        >
                           Score Bars
                        </Button>
                        <Button
                           onClick={() =>
                              update(draftId, {
                                 visibleProfileImages:
                                    !gameState.visibleProfileImages,
                              })
                           }
                           variant={
                              gameState.visibleProfileImages
                                 ? "contained"
                                 : "outlined"
                           }
                           color={
                              gameState.visibleProfileImages
                                 ? "success"
                                 : "alert"
                           }
                        >
                           Profile Images
                        </Button>
                        <Button
                           onClick={() =>
                              update(draftId, {
                                 visibleCountry: !gameState.visibleCountry,
                              })
                           }
                           variant={
                              gameState.visibleCountry
                                 ? "contained"
                                 : "outlined"
                           }
                           color={
                              gameState.visibleCountry ? "success" : "alert"
                           }
                        >
                           Country
                        </Button>

                        <Box sx={{ pt: 10 }}>
                           <Button
                              onClick={handleResetDraft}
                              variant='contained'
                              color='error'
                              fullWidth
                           >
                              RESET DRAFT
                           </Button>
                        </Box>
                     </Stack>
                  </Stack>
               )}
            </Grid>
            <Grid
               item
               xs={10}
               sx={{ height: 1080 * iframeScale, mb: 4 }}
               ref={columnWidth}
            >
               <Box
                  iframeScale={iframeScale}
                  sx={{
                     position: "relative",
                     width: 1920,
                     height: 1080,
                     transform: "scale(" + iframeScale + ")",
                     transformOrigin: "top left",
                     overflow: "hidden",
                     border: "1px solid #333",
                  }}
                  className='transparentBG'
               >
                  <DraftViewer draftId={draftId} />
               </Box>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>
               <Grid container spacing={4}>
                  <Grid item xs={3}>
                     <Button
                        variant='outlined'
                        color='secondary'
                        onClick={() => refreshFMPhotos(draftId)}
                     >
                        REFRESH PLAYER DATA
                     </Button>
                  </Grid>
                  <Grid item xs sx={{ textAlign: "right" }}>
                     <Button
                        onClick={handleOpenRoundTitle}
                        variant='outlined'
                        color='warning'
                     >
                        Update Round Title
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
            <Grid item xs={2}>
               <Typography
                  variant='h4'
                  component='div'
                  sx={{
                     textAlign: "center",
                     textTransform: "uppercase",
                     backgroundColor: "#ccc",
                     color: "#333",
                     py: 1.5,
                     mb: 2,
                  }}
               >
                  Card
               </Typography>

               {data && (
                  <>
                     {visible === true && (
                        <Button
                           fullWidth
                           sx={{ mb: 2 }}
                           variant='contained'
                           color='error'
                           onClick={() => toggleDraftVisibility(false)}
                        >
                           Hide Card
                        </Button>
                     )}
                     {visible !== true && (
                        <Button
                           fullWidth
                           sx={{ mb: 2 }}
                           variant='outlined'
                           color='success'
                           onClick={() => toggleDraftVisibility(true)}
                        >
                           Show Card
                        </Button>
                     )}

                     <Box sx={{ position: "relative", width: "100%" }}>
                        {!visible && (
                           <Box
                              sx={{
                                 position: "absolute",
                                 width: "100%",
                                 height: "100%",
                                 backgroundColor: "rgba(0,0,0,.6)",
                                 textAlign: "center",
                                 zIndex: "100",
                              }}
                           />
                        )}

                        {visible === true && (
                           <Button
                              fullWidth
                              sx={{ mb: 2 }}
                              variant='outlined'
                              color='success'
                              onClick={() =>
                                 toggleDraftRotation(rotation === 90 ? 0 : 90)
                              }
                           >
                              Rotate
                           </Button>
                        )}

                        {data.type === "image" && (
                           <img
                              src={
                                 SERVER_URL +
                                 "/cardimages/png/" +
                                 encodeURIComponent(data.value) +
                                 ".png"
                              }
                              width='100%'
                              style={{
                                 display: "block",
                                 transformOrigin: "center center",
                                 transform:
                                    "rotate(" +
                                    rotation +
                                    "deg) " +
                                    (rotation
                                       ? "scale(.67) translateX(-50%)"
                                       : ""),
                              }}
                           />
                        )}
                     </Box>
                  </>
               )}
            </Grid>
            <Grid item xs={6}>
               {gameState && picks && (
                  <Stack spacing={1}>
                     <Typography
                        variant='h4'
                        component='div'
                        sx={{
                           textAlign: "center",
                           textTransform: "uppercase",
                           backgroundColor: "#ccc",
                           color: "#333",
                           py: 1.5,
                           position: "relative",
                        }}
                     >
                        DRAFT PICKS
                        <Button
                           sx={{ position: "absolute", left: 10 }}
                           variant='outlined'
                           size='small'
                           onClick={exportDraftPicks}
                        >
                           Export
                        </Button>
                        <Button
                           sx={{ position: "absolute", right: 10 }}
                           onClick={() =>
                              handleAddPick(draftId, {
                                 CardManaCost: "",
                                 CardName: "-",
                                 CardSearchString: "",
                                 FileName: "",
                                 Set: "",
                              })
                           }
                           variant='outlined'
                           color='secondary'
                           size='small'
                        >
                           + Placeholder
                        </Button>
                     </Typography>
                     {gameState &&
                        picks &&
                        picks.map((row, i) => (
                           <React.Fragment key={"pickRow" + row.id}>
                              {picks.length - i == 28 ||
                              picks.length - i == 14 ? (
                                 <Typography
                                    variant='h5'
                                    component='div'
                                    sx={{
                                       textAlign: "center",
                                       textTransform: "uppercase",
                                       backgroundColor: "#222",
                                       color: "#ccc",
                                       py: 1.5,
                                    }}
                                 >
                                    PACK
                                 </Typography>
                              ) : (
                                 ""
                              )}
                              <Stack spacing={2} direction='row'>
                                 <Box
                                    sx={{
                                       fontSize: ".8em",
                                       backgroundColor: "#333",
                                       color: "#ccc",
                                       width: "85%",
                                       overflow: "hidden",
                                       borderRadius: 1,
                                    }}
                                    key={"cardRow-" + row.id}
                                 >
                                    <Stack
                                       direction='row'
                                       sx={{ width: "100%" }}
                                       alignItems='center'
                                       justifyContent='flex-end'
                                       spacing={2}
                                    >
                                       <Box
                                          sx={{
                                             fontWeight: "bold",
                                             pl: 2,
                                             textAlign: "center",
                                          }}
                                       >
                                          {picks.length - i}
                                       </Box>
                                       <Box
                                          sx={{
                                             minWidth: 90,
                                             width: 90,
                                             height: 70,
                                             position: "relative",
                                             overflow: "hidden",
                                          }}
                                       >
                                          <CardImage
                                             key={"cardImage-" + row.card.id}
                                             id={row.card.CardSearchString}
                                             name={row.card.CardName}
                                             borderRadius={0.5}
                                             showName={false}
                                             fileName={row.card.FileName}
                                          />
                                       </Box>
                                       <Stack
                                          spacing={0.5}
                                          sx={{
                                             width: "100%",
                                          }}
                                       >
                                          <Box
                                             sx={{
                                                width: "100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                             }}
                                          >
                                             {row.card.CardName}
                                          </Box>
                                          <Box sx={{ width: "100%" }}>
                                             <ManaIconsFormatted
                                                iconWidth={12}
                                                mana={row.card.CardManaCost}
                                             />
                                          </Box>
                                       </Stack>
                                       <Stack spacing={1} sx={{ p: 1 }}>
                                          <Button
                                             variant='outlined'
                                             color='secondary'
                                             size='small'
                                             fullWidth
                                             onClick={() =>
                                                setReplaceCard(row.id)
                                             }
                                          >
                                             REPLACE
                                          </Button>
                                          <Button
                                             variant='outlined'
                                             color='error'
                                             size='small'
                                             fullWidth
                                             onClick={() =>
                                                removePick(draftId, row.id)
                                             }
                                          >
                                             X
                                          </Button>
                                       </Stack>
                                    </Stack>
                                 </Box>
                                 <Button
                                    variant={
                                       row.card.CardName != "-"
                                          ? "outlined"
                                          : "disabled"
                                    }
                                    color='warning'
                                    size='small'
                                    sx={{ width: "10%" }}
                                    onClick={() => {
                                       if (!gameState.visibleCardsInHand) {
                                          update(draftId, {
                                             visibleCardsInHand: true,
                                          });
                                       }
                                       draftOverlayDataLoad({
                                          type: "image",
                                          value: row.card.FileName,
                                       });
                                       setTimeout(() => {
                                          toggleDraftVisibility(true);
                                       }, 200);
                                    }}
                                 >
                                    Show
                                 </Button>
                              </Stack>
                           </React.Fragment>
                        ))}
                  </Stack>
               )}
            </Grid>
            <Grid item xs={4}>
               <Box sx={{ mb: 2 }}>
                  <Typography
                     variant='h4'
                     component='div'
                     sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                        backgroundColor: "#ccc",
                        color: "#333",
                        py: 1.5,
                        mb: 2,
                     }}
                  >
                     Card Search
                  </Typography>
                  <Typography
                     variant='h5'
                     component='div'
                     sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                     }}
                  >
                     Max. Result 30 cards — type at least 3 characters
                  </Typography>
               </Box>

               <Box>
                  <TextField
                     fullWidth
                     placeholder='Enter text here'
                     value={searchValue}
                     inputProps={{
                        style: { fontSize: 24, textAlign: "center" },
                     }}
                     onClick={(event) => {
                        event.target.select();
                     }}
                     onChange={handleSearch}
                  />
               </Box>

               <Stack
                  direction='row'
                  alignItems='stretch'
                  justifyContent='center'
                  sx={{ color: "#fff", my: 2 }}
                  spacing={2}
               >
                  <Button
                     onClick={toggleFilterSets}
                     size='small'
                     variant={filterSets ? "contained" : "outlined"}
                     color={filterSets ? "error" : "secondary"}
                  >
                     DRAFT MODE
                     <br />({draftFormats && draftFormats.join(", ")})
                  </Button>
                  <Button
                     size='small'
                     onClick={showRecent}
                     variant='contained'
                     color='success'
                  >
                     SHOW RECENT CARDS
                  </Button>
                  <Typography
                     variant='h5'
                     component='div'
                     sx={{
                        py: 2,
                        textAlign: "center",
                        textTransform: "uppercase",
                     }}
                  >
                     {searchResults.length} Results
                  </Typography>

                  <Button
                     onClick={() => setSearchResults([])}
                     sx={{ ml: 4 }}
                     size='small'
                     variant='outlined'
                     color='error'
                  >
                     CLEAR
                  </Button>
               </Stack>
               <Stack spacing={1}>
                  {searchResults &&
                     searchResults.map((card, i) => (
                        <Stack
                           spacing={2}
                           direction='row'
                           key={"searchResult" + i}
                        >
                           <Box
                              sx={{
                                 fontSize: ".8em",
                                 backgroundColor: "#333",
                                 color: "#ccc",
                                 width: "70%",
                                 borderRadius: 1,
                              }}
                              key={"cardSearchRow-" + card.id}
                           >
                              <Stack
                                 direction='row'
                                 sx={{ width: "100%" }}
                                 alignItems='center'
                                 justifyContent='flex-end'
                                 spacing={3}
                              >
                                 <Box
                                    sx={{
                                       width: 100,
                                       height: 70,
                                       position: "relative",
                                       overflow: "hidden",
                                    }}
                                 >
                                    <CardImage
                                       key={"cardSearchImage-" + card.ID}
                                       id={card.CardSearchString}
                                       name={card.CardName}
                                       borderRadius={0.5}
                                       showName={false}
                                       fileName={card.FileName}
                                    />
                                 </Box>
                                 <Stack
                                    spacing={0.5}
                                    sx={{
                                       width: "100%",
                                    }}
                                 >
                                    <Box
                                       sx={{
                                          width: "100%",
                                          textAlign: "left",
                                          fontWeight: "bold",
                                       }}
                                    >
                                       {card.CardName}
                                    </Box>
                                    <ManaIconsFormatted
                                       iconWidth={12}
                                       mana={card.CardManaCost}
                                    />
                                 </Stack>
                              </Stack>
                           </Box>
                           <Button
                              id={card.CardSearchString}
                              variant='contained'
                              color='success'
                              onClick={() => {
                                 handleAddPick(draftId, card);
                                 addToRecent({
                                    type: "image",
                                    cardName: card.CardName,
                                    value: card.FileName,
                                    card: card,
                                 });
                              }}
                              sx={{ width: "15%" }}
                           >
                              Add
                           </Button>
                           <Button
                              variant='outlined'
                              color='warning'
                              onClick={() => {
                                 if (!gameState.visibleCardsInHand) {
                                    update(draftId, {
                                       visibleCardsInHand: true,
                                    });
                                 }
                                 draftOverlayDataLoad({
                                    type: "image",
                                    value: card.FileName,
                                 });
                                 setTimeout(() => {
                                    toggleDraftVisibility(true);
                                 }, 200);
                              }}
                              sx={{ width: "15%" }}
                           >
                              Show
                           </Button>
                        </Stack>
                     ))}
               </Stack>
            </Grid>
         </Grid>

         <Modal open={replaceCard ? true : false}>
            <Box sx={style}>
               <ReplaceCard
                  allCardNames={allCardNames}
                  replaceId={replaceCard}
                  draftFormats={draftFormats}
                  replacePick={(replaceId, card) => {
                     replacePick(draftId, replaceId, card);
                     setReplaceCard();
                  }}
                  close={() => setReplaceCard()}
               />
            </Box>
         </Modal>

         <Modal open={openRoundTitle} onClose={handleCloseRoundTitle}>
            <Box sx={style}>
               <AdjustDraftTitle
                  save={updateRoundTitle}
                  cancel={handleCloseRoundTitle}
               />
            </Box>
         </Modal>

         <Modal open={showDecklistExport} onClose={hideExport}>
            <Box sx={style}>
               <IconButton
                  aria-label='close'
                  color='error'
                  sx={{ position: "absolute", top: 10, right: 10, zIndex: 100 }}
                  onClick={hideExport}
               >
                  <CloseIcon />
               </IconButton>

               <Typography
                  variant='h4'
                  component='div'
                  sx={{
                     textAlign: "center",
                     textTransform: "uppercase",
                     color: "#ccc",
                     py: 1.5,
                  }}
               >
                  All Draft Picks
               </Typography>

               <Typography
                  variant='h5'
                  component='div'
                  sx={{
                     textAlign: "center",
                     textTransform: "uppercase",
                     color: "#ccc",
                     mb: 2,
                  }}
               >
                  Copy to text editor for editing before MTG Melee upload
               </Typography>

               <textArea
                  onClick={(event) => {
                     event.target.select();
                  }}
                  style={{ height: 600, width: 600, userSelect: "text" }}
               >
                  {decklistExport}
               </textArea>
            </Box>
         </Modal>
      </Box>
   );
}
