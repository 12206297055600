import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import LEDWrapperViewer from "../../components/layout/LEDWrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

import NameBackground from "../../images/bkgnd-top8-slash.svg";

//HOOKS
import useLEDPlayers from "../../hooks/useLEDPlayers";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function LEDPlayersViewer() {
   const variants = {
      enter: {
         opacity: 1,
         transition: {
            easing: "easeOut",
            duration: 1,
            staggerChildren: 0.08,
         },
      },
      exit: {
         duration: 0.5,
         opacity: 0,
      },
   };

   const lineVariants = {
      enter: {
         opacity: 1,
         transition: {
            easing: "easeOut",
         },
      },
      exit: {
         opacity: 0,
      },
   };

   // USE HOOK
   const [
      title,
      allPlayers,
      LEDPlayers,
      show,
      runTimestamp,
      { getAllPlayers, getLEDPlayers, showHide, subscribeLEDPlayersViewer },
   ] = useLEDPlayers();

   useEffect(() => {
      subscribeLEDPlayersViewer();
      getLEDPlayers();
   }, []);

   return (
      <LEDWrapperViewer>
         <AnimatePresence>
            <Box>
               <Stack
                  spacing={2}
                  direction='row'
                  alignItems='center'
                  sx={{
                     height: "1080px",
                     width: "100%",
                  }}
               >
                  <Stack
                     sx={{
                        width: "100%",
                        pb: 20,
                     }}
                     direction='row'
                     justifyContent='center'
                     alignItems='stretch'
                  >
                     {LEDPlayers &&
                        _.chunk(LEDPlayers, 2).map((rows, i) => (
                           <React.Fragment key={"firstchunks" + i}>
                              {i < 2 && (
                                 <Stack
                                    alignItems='flex-start'
                                    spacing={6}
                                    sx={{
                                       position: "relative",
                                       width: 435,
                                       px: 8,
                                    }}
                                    key={"playerfirstcolumns-" + i}
                                    variants={variants}
                                    initial='exit'
                                    exit='exit'
                                    animate={show ? "enter" : "exit"}
                                    component={motion.div}
                                 >
                                    {rows.map((row, i) => (
                                       <Stack
                                          key={"playerfirstcolumnrows-" + i}
                                       >
                                          <Box
                                             sx={{
                                                backgroundColor: "#000",
                                                width: "100%",
                                                height: 250,
                                                overflow: "hidden",
                                             }}
                                          >
                                             <img
                                                src={row.playerPhoto}
                                                width='100%'
                                                style={{ marginTop: -15 }}
                                             />
                                          </Box>

                                          <Stack
                                             direction='row'
                                             spacing={0}
                                             alignItems='center'
                                             justifyContent='center'
                                             sx={{
                                                backgroundColor: "#000",
                                                minHeight: 90,
                                             }}
                                          >
                                             <Typography
                                                variant='h4topnamesviewer'
                                                sx={{
                                                   textTransform: "uppercase",
                                                   textAlign: "center",
                                                   lineHeight: "1em",
                                                   width: "100%",
                                                   ml: 2,
                                                }}
                                             >
                                                {row.FirstName ? (
                                                   row.FirstName
                                                ) : (
                                                   <>&nbsp;</>
                                                )}
                                                <br />
                                                {row.LastName ? (
                                                   row.LastName
                                                ) : (
                                                   <>&nbsp;</>
                                                )}
                                             </Typography>
                                             <Box
                                                sx={{
                                                   width: 70,
                                                   height: "100%",
                                                   ml: "0 !important",
                                                   background:
                                                      "url(" +
                                                      NameBackground +
                                                      ") top left no-repeat",
                                                   backgroundSize: "cover",
                                                }}
                                             />
                                          </Stack>
                                       </Stack>
                                    ))}
                                 </Stack>
                              )}
                           </React.Fragment>
                        ))}
                     <Stack
                        justifyContent='center'
                        alignItems='center'
                        spacing={6}
                        sx={{
                           position: "relative",
                           width: 435,
                        }}
                        key={"logo"}
                     >
                        <img
                           src={SERVER_URL + "/images/ui/logo-800.png"}
                           width='70%'
                        />

                        <Typography
                           variant='h3Viewer'
                           sx={{
                              textTransform: "uppercase",
                              textAlign: "center",
                              lineHeight: "1em",
                              width: "100%",
                              overflow: "hidden",
                           }}
                        >
                           {title}
                        </Typography>
                     </Stack>

                     {LEDPlayers &&
                        _.chunk(LEDPlayers, 2).map((rows, i) => (
                           <React.Fragment key={"lastchunks" + i}>
                              {i >= 2 && (
                                 <Stack
                                    alignItems='flex-start'
                                    spacing={6}
                                    sx={{
                                       position: "relative",
                                       width: 435,
                                       px: 8,
                                    }}
                                    key={"playerlastcolumns-" + i}
                                    variants={variants}
                                    initial='exit'
                                    exit='exit'
                                    animate={show ? "enter" : "exit"}
                                    component={motion.div}
                                 >
                                    {rows.map((row, i) => (
                                       <Stack
                                          key={"playerlastcolumnrows-" + i}
                                          sx={{ width: "100%" }}
                                       >
                                          <Box
                                             sx={{
                                                backgroundColor: "#000",
                                                width: "100%",
                                                height: 250,
                                                overflow: "hidden",
                                             }}
                                          >
                                             <img
                                                src={row.playerPhoto}
                                                width='100%'
                                                style={{ marginTop: -15 }}
                                             />
                                          </Box>
                                          <Stack
                                             direction='row'
                                             spacing={0}
                                             alignItems='center'
                                             justifyContent='center'
                                             sx={{
                                                backgroundColor: "#000",
                                                minHeight: 90,
                                             }}
                                          >
                                             <Typography
                                                variant='h4topnamesviewer'
                                                sx={{
                                                   textTransform: "uppercase",
                                                   textAlign: "center",
                                                   lineHeight: "1em",
                                                   width: "100%",
                                                   ml: 2,
                                                }}
                                             >
                                                {row.FirstName ? (
                                                   row.FirstName
                                                ) : (
                                                   <>&nbsp;</>
                                                )}
                                                <br />
                                                {row.LastName ? (
                                                   row.LastName
                                                ) : (
                                                   <>&nbsp;</>
                                                )}
                                             </Typography>
                                             <Box
                                                sx={{
                                                   width: 70,
                                                   height: "100%",
                                                   ml: "0 !important",
                                                   background:
                                                      "url(" +
                                                      NameBackground +
                                                      ") top left no-repeat",
                                                   backgroundSize: "cover",
                                                }}
                                             />
                                          </Stack>
                                       </Stack>
                                    ))}
                                 </Stack>
                              )}
                           </React.Fragment>
                        ))}
                  </Stack>
               </Stack>
            </Box>
         </AnimatePresence>
      </LEDWrapperViewer>
   );
}
