import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import PlayerProfilePreview from "./PlayerProfilePreview";

//HOOKS
import usePlayerProfiles from "../../hooks/usePlayerProfiles";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function PlayerProfileViewer({ previewOutput }) {
   let { output } = useParams();

   const [
      notification,
      allPlayerProfiles,
      playerProfile,
      show,
      runTimestamp,
      { subscribePlayerProfileViewer, getPlayerProfile },
   ] = usePlayerProfiles();

   useEffect(() => {
      subscribePlayerProfileViewer(previewOutput ? previewOutput : output);
   }, []);

   useEffect(() => {
      // console.log(show);
   }, [show]);

   return (
      <>
         {playerProfile && (
            <>
               <PlayerProfilePreview
                  preview={false}
                  iframeScale={1}
                  data={playerProfile}
                  show={show}
                  key={runTimestamp}
               />
            </>
         )}
      </>
   );
}
