import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ManaIconsFormatted from "../decklists/ManaIconsFormatted";

import { useTheme } from "@mui/material/styles";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function PlayerCardsInHand({ playerPosition, cardsInHand }) {
   const theme = useTheme();

   return (
      <>
         {cardsInHand && (
            <Box
               key={"playerId" + playerPosition + "_cardsInHand"}
               component={motion.div}
               initial={{ opacity: 0 }}
               animate={{
                  opacity: 1,
                  transition: { delay: 0.2 },
               }}
               exit={{ opacity: 0 }}
            >
               <AnimatePresence>
                  {cardsInHand.map((row, i) => (
                     <Box
                        layout
                        key={"card" + row.card.id + "-" + row.card.FileName}
                        component={motion.div}
                        sx={{
                           position: "relative",
                           fontFamily: "GothamNarrow-Medium",
                           px: 1.2,
                           pt: 0.75,
                           pb: 0.5,
                           mb: 0.38,
                           fontSize: "1rem",
                           lineHeight: "1rem",
                           color: theme.palette.text.white,
                           backgroundColor: "#1f1f1f",
                        }}
                        key={"cardsInHand" + row.id}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ layout: { duration: 0.4 } }}
                     >
                        <Stack direction='row' spacing={1} alignItems='center'>
                           <Box
                              sx={{
                                 whiteSpace: "nowrap",
                                 width: "100%",
                                 overflow: "hidden",
                                 maskImage:
                                    "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%)",
                                 maskRepeat: "no-repeat",
                              }}
                           >
                              {row.card.Name}
                           </Box>

                           <Box
                              sx={{
                                 position: "absolute",
                                 right: 5,
                                 zIndex: 10,
                                 whiteSpace: "nowrap",
                                 overflow: "hidden",
                                 maskImage:
                                    "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 25px, rgba(0,0,0,1) 100%)",
                                 maskRepeat: "no-repeat",
                                 backgroundColor: "#1f1f1f",
                                 pl: "25px",
                              }}
                           >
                              <ManaIconsFormatted
                                 iconWidth={15}
                                 mana={row.card.Mana}
                              />
                           </Box>
                        </Stack>
                     </Box>
                  ))}
               </AnimatePresence>
            </Box>
         )}
      </>
   );
}
