import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import WrapperViewer from "../../components/layout/WrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

//HOOKS
import useStandingsHandler from "../../hooks/useStandingsHandler";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function StandingsViewer({ data }) {
   const [standings, setStandings] = useState();

   useEffect(() => {
      setStandings(data);
   }, [data]);

   return (
      <WrapperViewer>
         <Box
            sx={{
               position: "absolute",
               top: 40,
               right: 100,
               height: 200,
            }}
         >
            <img src={SERVER_URL + "/images/ui/logo-800.png"} width='125' />
         </Box>

         <Stack
            sx={{
               position: "absolute",
               top: 40,
               left: 100,
               height: 200,
            }}
            justifyContent='center'
         >
            <Typography
               variant='h1Viewer'
               component='div'
               sx={{ textTransform: "uppercase" }}
            >
               Standings
            </Typography>

            {standings && standings.rows && standings.rows.length > 0 && (
               <Typography
                  variant='h2Viewer'
                  component='div'
                  sx={{ textTransform: "uppercase" }}
               >
                  {standings.rows[0].Round}
               </Typography>
            )}
         </Stack>

         <AnimatePresence>
            {standings && standings.rows && standings.rows.length > 0 && (
               <Stack
                  direction='row'
                  spacing={3}
                  sx={{
                     position: "absolute",
                     width: 1720,
                     top: 260,
                     left: 100,
                  }}
                  alignItems='flex-start'
                  key={standings.key}
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{
                     opacity: 1,
                     transition: { delay: 0.5 },
                  }}
                  exit={{
                     opacity: 0,
                  }}
                  transition={{
                     easing: "easeOut",
                     duration: 0.5,
                  }}
               >
                  <Table variant='standings'>
                     <TableHead>
                        <TableRow>
                           <TableCell width='90' sx={{ textAlign: "center" }}>
                              RANK
                           </TableCell>
                           <TableCell>NAME</TableCell>
                           <TableCell width='90' sx={{ textAlign: "center" }}>
                              WIN
                           </TableCell>
                           <TableCell width='90' sx={{ textAlign: "center" }}>
                              LOSS
                           </TableCell>
                           <TableCell width='90' sx={{ textAlign: "center" }}>
                              DRAW
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {standings.rows.map((row, i) => (
                           <React.Fragment key={"rowFirstCol" + i}>
                              {i < 8 && (
                                 <TableRow>
                                    <TableCell sx={{ textAlign: "center" }}>
                                       {row.Rank}
                                    </TableCell>
                                    <TableCell
                                       sx={{
                                          position: "relative",
                                          overflow: "hidden",
                                       }}
                                    >
                                       <Box
                                          className='fadeAway'
                                          sx={{
                                             position: "absolute",
                                             top: 0,
                                             right: -2,
                                             width: "100%",
                                             height: "100%",
                                             zIndex: 100,
                                          }}
                                       />
                                       <Stack
                                          direction='row'
                                          spacing={2}
                                          sx={{
                                             position: "relative",
                                             textTransform: "uppercase",
                                          }}
                                       >
                                          <Box
                                             sx={{
                                                width: 50,
                                                height: 32,
                                                fontSize: 10,
                                                textAlign: "center",
                                             }}
                                          >
                                             {row.CountryFlag && (
                                                <Box
                                                   sx={{
                                                      width: "100%",
                                                      height: "100%",
                                                      background:
                                                         "url(" +
                                                         row.CountryFlag +
                                                         ") center center",
                                                      backgroundSize: "contain",
                                                      backgroundRepeat:
                                                         "no-repeat",
                                                   }}
                                                />
                                             )}
                                          </Box>
                                          <Box
                                             sx={{
                                                position: "absolute",
                                                left: 50,
                                                width: 425,
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                             }}
                                          >
                                             {row.FirstName} {row.LastName}
                                          </Box>
                                       </Stack>
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                       {row.MatchWins}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                       {row.MatchLosses}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                       {row.MatchDraws}
                                    </TableCell>
                                 </TableRow>
                              )}
                           </React.Fragment>
                        ))}
                     </TableBody>
                  </Table>

                  <Table variant='standings'>
                     <TableHead>
                        <TableRow>
                           <TableCell width='90' sx={{ textAlign: "center" }}>
                              RANK
                           </TableCell>
                           <TableCell>NAME</TableCell>
                           <TableCell width='90' sx={{ textAlign: "center" }}>
                              WIN
                           </TableCell>
                           <TableCell width='90' sx={{ textAlign: "center" }}>
                              LOSS
                           </TableCell>
                           <TableCell width='90' sx={{ textAlign: "center" }}>
                              DRAW
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {standings.rows.map((row, i) => (
                           <React.Fragment key={"rowSecondCol" + i}>
                              {i >= 8 && (
                                 <TableRow>
                                    <TableCell
                                       sx={{
                                          textAlign: "center",
                                       }}
                                    >
                                       {row.Rank}
                                    </TableCell>
                                    <TableCell
                                       sx={{
                                          position: "relative",
                                          overflow: "hidden",
                                       }}
                                    >
                                       <Box
                                          className='fadeAway'
                                          sx={{
                                             position: "absolute",
                                             top: 0,
                                             right: -2,
                                             width: "100%",
                                             height: "100%",
                                             zIndex: 100,
                                          }}
                                       />
                                       <Stack
                                          direction='row'
                                          spacing={2}
                                          sx={{
                                             position: "relative",
                                             textTransform: "uppercase",
                                          }}
                                       >
                                          <Box
                                             sx={{
                                                width: 50,
                                                height: 32,
                                                fontSize: 10,
                                                textAlign: "center",
                                             }}
                                          >
                                             {row.CountryFlag && (
                                                <Box
                                                   sx={{
                                                      width: "100%",
                                                      height: "100%",
                                                      background:
                                                         "url(" +
                                                         row.CountryFlag +
                                                         ") center center",
                                                      backgroundSize: "contain",
                                                      backgroundRepeat:
                                                         "no-repeat",
                                                   }}
                                                />
                                             )}
                                          </Box>
                                          <Box
                                             sx={{
                                                position: "absolute",
                                                left: 50,
                                                width: 425,
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                             }}
                                          >
                                             {row.FirstName} {row.LastName}
                                          </Box>
                                       </Stack>
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                       {row.MatchWins}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                       {row.MatchLosses}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                       {row.MatchDraws}
                                    </TableCell>
                                 </TableRow>
                              )}
                           </React.Fragment>
                        ))}
                     </TableBody>
                  </Table>
               </Stack>
            )}
         </AnimatePresence>
      </WrapperViewer>
   );
}
