import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import DebugOverlay from "../components/widgets/DebugOverlay";
import NotificationWidget from "../components/widgets/NotificationWidget";
import DataGrid from "../components/cardlibrary/DataGrid";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import Wrapper from "../components/layout/Wrapper";

//HOOKS
import useCardHandler from "../hooks/useCardHandler";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: "90%",
   height: "80%",
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
   overflowY: "scroll",
   overflowX: "hidden",
};

export default function CardLibrary() {
   const [open, setOpen] = React.useState(false);

   const handleOpen = () => setOpen(true);
   const handleClose = () => {
      setPreviewCard();
      setOpen(false);
   };

   const [previewCard, setPreviewCard] = useState();

   const [legality, setLegality] = useState();

   // USE HOOK
   const [allCards, notification, { getAllCards }] = useCardHandler();

   useEffect(() => {
      getAllCards();
   }, []);

   useEffect(() => {
      if (previewCard) {
         handleOpen();
      }
   }, [previewCard]);

   useEffect(() => {
      if (legality) {
         getAllCards(legality ? { legality: legality } : {});
      }
   }, [legality]);

   const handlePreviewCard = (cardId) => {
      let cardInfo = _.find(allCards, { ID: parseInt(cardId) });
      setPreviewCard(cardInfo);
   };

   return (
      <Wrapper>
         <Typography
            variant='h3'
            component='div'
            sx={{ textTransform: "uppercase", mb: 2 }}
         >
            Card Database
         </Typography>

         {allCards && (
            <>
               <DataGrid
                  key={"legality_" + legality}
                  height={1000}
                  data={allCards}
                  type='cardOverview'
                  preview={handlePreviewCard}
               />
            </>
         )}

         <NotificationWidget notification={notification} />

         {previewCard && (
            <Modal open={open} onClose={handleClose}>
               <Box sx={modalStyle}>
                  <Typography
                     variant='h4'
                     component='h4'
                     sx={{ color: "#333" }}
                  >
                     Card Preview
                     <Button
                        onClick={handleClose}
                        variant='outlined'
                        sx={{ float: "right" }}
                     >
                        Close
                     </Button>
                  </Typography>
                  <Typography sx={{ mt: 6 }}>
                     <Stack direction='row' spacing={8} sx={{ mt: 3 }}>
                        <Box>
                           <img
                              src={
                                 SERVER_URL +
                                 "/cardimages/png/" +
                                 encodeURIComponent(previewCard.FileName) +
                                 ".png"
                              }
                              style={{ maxWidth: 400, pointerEvents: "all" }}
                           />
                        </Box>

                        <Table>
                           <TableBody>
                              {Object.entries(previewCard).map(
                                 ([key, val], i) => (
                                    <React.Fragment key={"data" + i}>
                                       {key !== "legalities" && (
                                          <TableRow key={"datarow-" + i}>
                                             <TableCell
                                                sx={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#ccc",
                                                   borderBottom:
                                                      "1px solid #fff",
                                                   width: 200,
                                                }}
                                             >
                                                {key}
                                             </TableCell>
                                             <TableCell>{val}</TableCell>
                                          </TableRow>
                                       )}
                                    </React.Fragment>
                                 )
                              )}
                           </TableBody>
                        </Table>
                     </Stack>
                  </Typography>
               </Box>
            </Modal>
         )}
      </Wrapper>
   );
}
