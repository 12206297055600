import React, { useState, useContext, useEffect } from "react";
import {
   BrowserRouter as Router,
   Routes,
   Route,
   useNavigate,
} from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";
import logo from "../images/evoke.svg";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import CopyToClipboardButton from "../components/widgets/CopyToClipboardButton";

// ROUTES
import ProducerDashboard from "./ProducerDashboard";

import HeadToHead from "./HeadToHead";
import HeadToHeadEditor from "./HeadToHeadEditor";
import PlayerProfiles from "./PlayerProfiles";
import PlayerProfileEditor from "./PlayerProfileEditor";
import RoundByRound from "./RoundByRound";
import RoundByRoundEditor from "./RoundByRoundEditor";
import Decklists from "./Decklists";
import StandingsManager from "./StandingsManager";
import Standings from "./Standings";
import DatabaseManagement from "./DatabaseManagement";

import CustomLeaderboard from "./CustomLeaderboard";

import FeatureMatchAdminCombined from "./FeatureMatchAdminCombined";
import FeatureMatchAdmin from "./FeatureMatchAdmin";
import TableHistory from "./TableHistory";

import DraftAdmin from "./DraftAdmin";
import DraftIpad from "./DraftIpad";

import Top8Bracket from "./Top8";
import Top8MovingOn from "./Top8MovingOn";

import PlayerPhotos from "./PlayerPhotos";
import CardLibrary from "./CardLibrary";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

const drawerWidth = 240;

export default function Producer(props) {
   let navigate = useNavigate();

   const { window } = props;
   const [mobileOpen, setMobileOpen] = React.useState(false);

   const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
   };

   const drawer = (
      <Box>
         <List>
            {[{ title: "Overview", url: "/" }].map((menuItem, index) => (
               <ListItem key={menuItem.title} disablePadding>
                  <ListItemButton
                     onClick={() => navigate("/producer" + menuItem.url)}
                  >
                     <ListItemText primary={menuItem.title} />
                  </ListItemButton>
               </ListItem>
            ))}
         </List>
         <Divider sx={{ backgroundColor: "#444" }} />
         <List>
            {[
               { title: "Decklists", url: "/decklists" },
               { title: "Standings", url: "/standings" },
               // { title: "Storyboards", url: "/storyboards" },
               { title: "Head to Head", url: "/headtohead" },
               { title: "Player Profiles", url: "/playerprofiles" },
               { title: "Round by Round", url: "/roundbyround" },
               { title: "Top 8", url: "/top8" },
               { title: "Bracket", url: "/bracket" },
            ].map((menuItem, index) => (
               <ListItem key={menuItem.title} disablePadding>
                  <ListItemButton
                     onClick={() => navigate("/producer" + menuItem.url)}
                  >
                     <ListItemText primary={menuItem.title} />
                  </ListItemButton>
               </ListItem>
            ))}
         </List>
         <Divider sx={{ backgroundColor: "#444" }} />
         <List>
            {[
               { title: "Card Library", url: "/cardlibrary" },
               { title: "Player Photos", url: "/playerphotos" },
            ].map((menuItem, index) => (
               <ListItem key={menuItem.title} disablePadding>
                  <ListItemButton
                     onClick={() => navigate("/producer" + menuItem.url)}
                  >
                     <ListItemText primary={menuItem.title} />
                  </ListItemButton>
               </ListItem>
            ))}
         </List>
      </Box>
   );

   const container =
      window !== undefined ? () => window().document.body : undefined;

   return (
      <Box sx={{ display: "flex" }}>
         <CssBaseline />
         <AppBar
            position='fixed'
            sx={{
               width: { sm: `calc(100% - ${drawerWidth}px)` },
               ml: { sm: `${drawerWidth}px` },
               backgroundColor: "#001833",
            }}
         >
            <Toolbar
               sx={{
                  minHeight: 64,
               }}
            >
               <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  edge='start'
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
               >
                  <MenuIcon />
               </IconButton>
               <img src={logo} style={{ width: 300, marginLeft: 10 }} />
            </Toolbar>
         </AppBar>
         <Box
            component='nav'
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label='mailbox folders'
         >
            <Drawer
               container={container}
               variant='temporary'
               open={mobileOpen}
               onClose={handleDrawerToggle}
               ModalProps={{
                  keepMounted: true,
               }}
               sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                     boxSizing: "border-box",
                     width: drawerWidth,
                     backgroundColor: "#11151a",
                     color: "#ccc",
                  },
               }}
            >
               <Stack
                  sx={{
                     width: "100%",
                     minHeight: 64,
                     backgroundColor: "#103701",
                  }}
                  alignItems='center'
                  justifyContent='center'
               >
                  <Typography variant='h5' component='div'>
                     PRODUCER
                  </Typography>
               </Stack>

               {drawer}
            </Drawer>
            <Drawer
               variant='permanent'
               sx={{
                  display: { xs: "none", sm: "block" },
                  "& .MuiDrawer-paper": {
                     boxSizing: "border-box",
                     width: drawerWidth,
                     backgroundColor: "#11151a",
                     color: "#ccc",
                  },
               }}
               open
            >
               <Stack
                  sx={{
                     width: "100%",
                     minHeight: 64,
                     backgroundColor: "#103701",
                  }}
                  alignItems='center'
                  justifyContent='center'
               >
                  <Typography variant='h5' component='div'>
                     PRODUCER
                  </Typography>
               </Stack>
               {drawer}
            </Drawer>
         </Box>
         <Box
            component='main'
            sx={{
               flexGrow: 1,
               p: 0,
               width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
         >
            <Typography
               variant='h5'
               component='div'
               sx={{ textTransform: "uppercase", mb: 0 }}
            >
               Producer
            </Typography>

            <Routes>
               <Route path='/' element={<ProducerDashboard />} />

               <Route
                  path='/headtohead'
                  element={<HeadToHead parent='producer' />}
               />
               <Route
                  path='/featurematches'
                  element={<FeatureMatchAdminCombined />}
               />
               <Route
                  path='/featurematch/:tableId'
                  element={<FeatureMatchAdmin />}
               />

               <Route path='/tablehistory' element={<TableHistory />} />

               <Route path='/draft/1' element={<DraftAdmin />} />
               <Route path='/draftipad/1' element={<DraftIpad />} />

               <Route
                  path='/headtohead/:id'
                  element={<HeadToHeadEditor parent='producer' />}
               />
               <Route
                  path='/playerprofiles'
                  element={<PlayerProfiles parent='producer' />}
               />
               <Route
                  path='/playerprofile/:id'
                  element={<PlayerProfileEditor parent='producer' />}
               />
               <Route
                  path='/decklists'
                  element={<Decklists parent='producer' />}
               />
               <Route
                  path='/standings'
                  element={<Standings parent='producer' />}
               />
               <Route
                  path='/standings/:id'
                  element={<StandingsManager parent='producer' />}
               />
               <Route
                  path='/storyboards'
                  element={<CustomLeaderboard parent='producer' />}
               />
               <Route
                  path='/cardlibrary'
                  element={<CardLibrary parent='producer' />}
               />
               <Route
                  path='/playerphotos'
                  element={<PlayerPhotos parent='producer' />}
               />

               <Route
                  path='/roundbyround'
                  element={<RoundByRound parent='producer' />}
               />
               <Route
                  path='/RoundByRound/:id'
                  element={<RoundByRoundEditor parent='producer' />}
               />

               <Route
                  path='/top8'
                  element={<Top8MovingOn parent='producer' />}
               />

               <Route
                  path='/bracket'
                  element={<Top8Bracket parent='producer' />}
               />

               <Route
                  path='/database'
                  element={<DatabaseManagement parent='producer' />}
               />
            </Routes>
         </Box>
      </Box>
   );
}
