import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ManaIconsFormatted from "../components/decklists/ManaIconsFormatted";
import CardImage from "../components/featurematch/CardImage";
import Wrapper from "../components/layout/Wrapper";
import CopyToClipboardButton from "../components/widgets/CopyToClipboardButton";

import PlayerDataEditor from "../components/playerprofiles/PlayerDataEditor";
import StandingsSelect from "../components/playerprofiles/StandingsSelect";

import DashboardTable from "../components/featurematch/DashboardTable";
import NotificationWidget from "../components/widgets/NotificationWidget";

import PlayerProfilePreview from "./viewers/PlayerProfilePreview";
import Downloader from "../components/widgets/Downloader";

//HOOKS
import usePlayerProfiles from "../hooks/usePlayerProfiles";
import useDatabaseManagement from "../hooks/useDatabaseManagement";

// DOWNLOADER
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

function base64Resize(sourceBase64, scale, fileName) {
   const _scale = scale;
   var img = document.createElement("img");
   img.setAttribute("src", sourceBase64);

   img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width * _scale;
      canvas.height = img.height * _scale;

      var ctx = canvas.getContext("2d");
      var cw = canvas.width;
      var ch = canvas.height;
      var maxW = img.width * _scale;
      var maxH = img.height * _scale;

      var iw = img.width;
      var ih = img.height;
      var scl = Math.min(maxW / iw, maxH / ih);
      var iwScaled = iw * scl;
      var ihScaled = ih * scl;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx.drawImage(img, 0, 0, iwScaled, ihScaled);

      canvas.toBlob(function (blob) {
         saveAs(blob, fileName + ".png");
      }, "image/png");
   };
}

export default function PlayerProfileEditor({ parent = "" }) {
   let { id } = useParams();

   const columnWidth = useRef();
   const [downloadVisibility, setDownloadVisibility] = useState(false);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   const [
      notification,
      allPlayerProfiles,
      playerProfile,
      show,
      runTimestamp,
      {
         update,
         getPlayerProfile,
         getAllPlayerProfiles,
         addPlayerProfile,
         playerProfileViewerLoad,
         playerProfileViewerShowHide,
      },
   ] = usePlayerProfiles();

   const [dbLog, previewData, dbNotification, { getPlayerData }] =
      useDatabaseManagement();

   useEffect(() => {
      getPlayerProfile(id);
   }, []);

   useEffect(() => {
      if (downloadVisibility) {
         window.devicePixelRatio = 2;
         html2canvas(document.getElementById("downloadContent"), {
            useCORS: true,
            logging: false,
            backgroundColor: null,
         })
            .then((canvas) => {
               base64Resize(canvas.toDataURL(), 0.5, downloadVisibility);
            })
            .then(() => {
               setDownloadVisibility(false);
            });
      }
   }, [downloadVisibility]);

   useEffect(() => {
      // console.log(playerProfile);
   }, [playerProfile]);

   const handleShowHide = (e) => {
      playerProfileViewerShowHide(e.target.name, e.target.value);
   };

   const handleGetPlayerData = (e) => {
      getPlayerData(e.target.id).then((response) => {
         getPlayerProfile(id);
      });
   };

   const handlePlayerChange = (updates) => {
      let newDataFields = playerProfile.DataFields;
      let clearDecks = {
         DataFields: {
            ...newDataFields,
            PlayerDecklist: "",
         },
      };
      update(id, { ...updates, ...clearDecks });
   };

   const handleChangeStandings = (e) => {
      update(id, { [e.id]: e.value });
   };

   const handleDataFieldUpdate = (e) => {
      let newDataFields = playerProfile.DataFields;

      if (isNaN(e.target.id)) {
         update(id, {
            DataFields: {
               ...newDataFields,
               [e.target.id]: e.target.value,
            },
         });
      } else {
         newDataFields.data[e.target.id] = {
            title: e.target.value,
            field: e.target.value,
         };
         update(id, { DataFields: newDataFields });
      }
   };

   const handleBulletpointUpdate = (e) => {
      let newDataFields = playerProfile.DataFields;

      newDataFields.bulletpoints[e.target.id] = {
         title: e.target.value,
         field: e.target.value,
         checkmark: false,
      };

      update(id, { DataFields: newDataFields });
   };

   const handleBulletpointCheckmarkUpdate = (e) => {
      let newDataFields = playerProfile.DataFields;

      newDataFields.bulletpoints[e.target.id] = {
         title: e.target.value,
         field: e.target.value,
         checkmark: !newDataFields.bulletpoints[e.target.id].checkmark,
      };

      update(id, { DataFields: newDataFields });
   };

   const handleDownload = async (event) => {
      setDownloadVisibility(event.target.value);
   };

   const handleLoadSlide = (e) => {
      playerProfileViewerLoad(e.target.name, e.target.id);
   };

   return (
      <Wrapper>
         <Stack direction='row' alignItems='center' sx={{ mb: 2 }}>
            <Typography
               variant='h3'
               component='div'
               sx={{ textTransform: "uppercase", width: "100%" }}
            >
               Player Profile
            </Typography>

            {parent != "producer" && (
               <>
                  <Button
                     variant='contained'
                     color='success'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     id={id}
                     name={1}
                     onClick={handleLoadSlide}
                  >
                     LOAD 1
                  </Button>
                  <Button
                     variant='contained'
                     color='success'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     id={id}
                     name={2}
                     onClick={handleLoadSlide}
                  >
                     LOAD 2
                  </Button>
               </>
            )}

            {playerProfile &&
               playerProfile.Player &&
               playerProfile.Player.PlayerName && (
                  <Downloader
                     enabled={true}
                     downloadName={
                        "PlayerProfile_" +
                        playerProfile.Player.PlayerName.replace(
                           /[^A-Za-z0-9]/g,
                           ""
                        ) +
                        "_" +
                        Date.now()
                     }
                  >
                     <PlayerProfilePreview show={true} data={playerProfile} />
                  </Downloader>
               )}
         </Stack>

         <Grid container spacing={3} sx={{ mb: 8 }}>
            <Grid
               item
               xs={8}
               ref={columnWidth}
               sx={{ height: 1080 * iframeScale, position: "relative" }}
            >
               <Box
                  sx={{
                     position: "relative",
                     width: 1920,
                     height: 1080,
                     transform: "scale(" + iframeScale + ")",
                     transformOrigin: "top left",
                     overflow: "hidden",
                     border: "1px solid #333",
                  }}
                  className='transparentBG'
               >
                  <PlayerProfilePreview data={playerProfile} show={true} pr />
               </Box>
            </Grid>
            {playerProfile && (
               <Grid item xs={4}>
                  <Stack
                     sx={{
                        backgroundColor: "#fff",
                        textAlign: "center",
                        p: 4,
                     }}
                  >
                     <Box sx={{ mb: 4 }}>
                        <Typography
                           variant='h5'
                           color='text.dark'
                           component='div'
                           sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                              mb: 2,
                           }}
                        >
                           Selected Bullet Points
                        </Typography>
                        <Stack spacing={1}>
                           {playerProfile.DataFields &&
                              playerProfile.DataFields.bulletpoints.map(
                                 (row, i) => (
                                    <Box key={"field" + i}>
                                       {row.title}
                                       {row.field && (
                                          <>
                                             {parent != "producer" && (
                                                <Button
                                                   size='small'
                                                   variant='outlined'
                                                   color='error'
                                                   sx={{ ml: 2 }}
                                                   id={i}
                                                   value=''
                                                   onClick={
                                                      handleBulletpointUpdate
                                                   }
                                                >
                                                   Clear
                                                </Button>
                                             )}{" "}
                                          </>
                                       )}
                                    </Box>
                                 )
                              )}
                        </Stack>
                     </Box>
                     <Box sx={{ mb: 4 }}>
                        <Typography
                           variant='h5'
                           color='text.dark'
                           component='div'
                           sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                              mb: 2,
                           }}
                        >
                           Selected Data Fields
                        </Typography>
                        <Stack spacing={1}>
                           {playerProfile.DataFields &&
                              playerProfile.DataFields.data.map((row, i) => (
                                 <Box key={"field" + i}>
                                    {row.title}
                                    {row.field && (
                                       <>
                                          {" "}
                                          {parent != "producer" && (
                                             <Button
                                                size='small'
                                                variant='outlined'
                                                color='error'
                                                sx={{ ml: 2 }}
                                                id={i}
                                                value=''
                                                onClick={handleDataFieldUpdate}
                                             >
                                                Clear
                                             </Button>
                                          )}
                                       </>
                                    )}
                                 </Box>
                              ))}
                        </Stack>
                     </Box>
                     <Box sx={{ mb: 4 }}>
                        <Typography
                           variant='h5'
                           color='text.dark'
                           component='div'
                           sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                              mb: 2,
                           }}
                        >
                           Selected Decklists
                        </Typography>
                        <Stack spacing={1}>
                           {playerProfile.DataFields.PlayerDecklist && (
                              <Box>
                                 Player 1:{" "}
                                 {playerProfile.DataFields &&
                                    playerProfile.DataFields.PlayerDecklist}
                                 <Button
                                    size='small'
                                    variant='outlined'
                                    color='error'
                                    sx={{ ml: 2 }}
                                    id={"PlayerDecklist"}
                                    value=''
                                    onClick={handleDataFieldUpdate}
                                 >
                                    Clear
                                 </Button>
                              </Box>
                           )}
                        </Stack>
                     </Box>
                     <Box>
                        <Typography
                           variant='h5'
                           color='text.dark'
                           component='div'
                           sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                           }}
                        >
                           Standings Data
                        </Typography>

                        <StandingsSelect
                           selected={
                              playerProfile.StandingsId
                                 ? playerProfile.StandingsId
                                 : 0
                           }
                           callback={handleChangeStandings}
                        />
                     </Box>
                  </Stack>
               </Grid>
            )}
         </Grid>

         {playerProfile && parent != "producer" && (
            <Stack>
               <Grid container spacing={4}>
                  <Grid item xs={8} sx={{ textAlign: "center" }}>
                     <PlayerDataEditor
                        playerId={playerProfile.PlayerId}
                        activeDataFields={playerProfile.DataFields}
                        data={playerProfile.PlayerData}
                        handleBulletpointUpdate={handleBulletpointUpdate}
                        handleBulletpointCheckmarkUpdate={
                           handleBulletpointCheckmarkUpdate
                        }
                        handleDataFieldUpdate={handleDataFieldUpdate}
                        handlePlayerChange={handlePlayerChange}
                        handleGetPlayerData={handleGetPlayerData}
                     />
                  </Grid>
               </Grid>
            </Stack>
         )}

         {notification && (
            <NotificationWidget
               notification={notification.message}
               key={"notification" + notification.date}
            />
         )}
      </Wrapper>
   );
}
