import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import DebugOverlay from "../../components/widgets/DebugOverlay";
import WrapperViewer from "../../components/layout/WrapperViewer";

import PlayerCardsInHand from "../../components/featurematch/PlayerCardsInHand";
import ScorebarPlayer from "../../components/featurematch/ScorebarPlayer";
import ScorebarLifetotal from "../../components/featurematch/ScorebarLifetotal";
import ScorebarPoison from "../../components/featurematch/ScorebarPoison";
import ScorebarGameround from "../../components/featurematch/ScorebarGameround";
import Bullets from "../../components/featurematch/Bullets";

import PlayerPhoto from "../../components/featurematch/PlayerPhoto";
import PlayerFlag from "../../components/featurematch/PlayerFlag";
import PlayerStandings from "../../components/featurematch/PlayerStandings";

//HOOKS
import useFeatureMatchHandler from "../../hooks/useFeatureMatchHandler";

import { useTheme } from "@mui/material/styles";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function FeatureMatch({ tableId }) {
   let { id } = useParams();
   const theme = useTheme();

   // USE SOCKETS HOOK
   const [
      players,
      allPlayers,
      gameState,
      cardsInHand,
      update,
      lastUpdated,
      saveRequired,
      needsSave,
      { subscribeFeatureMatch, getAllPlayers },
   ] = useFeatureMatchHandler();

   useEffect(() => {
      subscribeFeatureMatch(id ? id : tableId);
   }, []);

   useEffect(() => {
      // getFeatureMatchData(tableId);
   }, [gameState]);

   useEffect(() => {
      console.log(players);
   }, [players]);

   return (
      <>
         <Box
            sx={{
               cursor: "none",
            }}
            key={"featureMatch" + tableId}
         >
            <WrapperViewer>
               <AnimatePresence>
                  {gameState && !gameState.visibleCardsInHand && (
                     <>
                        <Box
                           key={"logo"}
                           component={motion.div}
                           initial={{ scale: 1.2, opacity: 0 }}
                           animate={{ scale: 1, opacity: 1 }}
                           exit={{ scale: 1.2, opacity: 0 }}
                           transition={{ easing: "easeInOut", delay: 0 }}
                           sx={{
                              position: "absolute",
                              width: 356,
                              height: 450,
                              top: 315,
                              zIndex: 500,
                           }}
                        >
                           <img
                              src={SERVER_URL + "/images/ui/logo-800.png"}
                              width='70%'
                              style={{
                                 position: "absolute",
                                 top: "50%",
                                 left: "50%",
                                 transform: "translateX(-50%) translateY(-50%)",
                              }}
                           />
                        </Box>

                        <Box
                           key={"backgroundImage"}
                           component={motion.div}
                           initial={{ opacity: 0 }}
                           animate={{ opacity: 1 }}
                           exit={{ opacity: 0, transition: { delay: 0.3 } }}
                           transition={{ easing: "easeInOut" }}
                           sx={{
                              position: "absolute",
                              width: 339,
                              height: 512,
                              top: 284,
                              left: 9,
                              zIndex: 20,
                              background:
                                 "url(" +
                                 SERVER_URL +
                                 "/images/ui/overlay-center-cover.png" +
                                 ")",
                           }}
                        />
                     </>
                  )}
               </AnimatePresence>

               {gameState && gameState.visibleSidebar && (
                  <Box
                     sx={{
                        position: "absolute",
                        width: 356,
                        height: 1080,
                        top: 0,
                        left: 0,
                        zIndex: 10,
                        backgroundImage:
                           "url('" +
                           SERVER_URL +
                           "/images/ui/overlay-frame.png')",
                     }}
                  >
                     {/* CARDS IN HAND */}
                     <AnimatePresence>
                        {cardsInHand && gameState.visibleCardsInHand && (
                           <Stack
                              key={"cardsInHandTitle"}
                              initial={{ opacity: 0 }}
                              animate={{
                                 opacity: 1,
                                 transition: { duration: 0.6, delay: 0.3 },
                              }}
                              exit={{
                                 opacity: 0,
                                 transition: { duration: 0.4 },
                              }}
                              component={motion.div}
                              alignItems='center'
                              justifyContent='center'
                              sx={{
                                 color: theme.palette.text.dark,
                                 position: "absolute",
                                 width: 280,
                                 top: 528,
                                 height: 25,
                                 pt: 0.2,
                                 left: 38,
                                 zIndex: 10,
                                 backgroundColor: "#2c2c2c",
                                 outline: "4px solid #222",
                              }}
                           >
                              <Typography
                                 component='div'
                                 variant='fmPlayerDeck'
                                 sx={{
                                    textTransform: "uppercase",
                                    textAlign: "center",
                                 }}
                              >
                                 Cards In Hand
                              </Typography>
                           </Stack>
                        )}
                     </AnimatePresence>

                     <AnimatePresence>
                        {cardsInHand && gameState.visibleCardsInHand && (
                           <>
                              <Box
                                 sx={{
                                    position: "absolute",
                                    width: 356,
                                    height: 230,
                                    top: 288,
                                    left: 0,
                                    zIndex: 10,
                                    px: 3,
                                    overflow: "hidden",
                                 }}
                              >
                                 {cardsInHand && (
                                    <PlayerCardsInHand
                                       playerPosition={2}
                                       cardsInHand={cardsInHand["2"]}
                                    />
                                 )}
                              </Box>

                              {cardsInHand["2"].length > 8 && (
                                 <Stack
                                    alignItems='center'
                                    justifyContent='center'
                                    key={"cardsInHandExtra2"}
                                    initial={{ opacity: 0 }}
                                    animate={{
                                       opacity: 1,
                                       transition: {
                                          duration: 0.6,
                                          delay: 0.3,
                                       },
                                    }}
                                    exit={{
                                       opacity: 0,
                                    }}
                                    component={motion.div}
                                    sx={{
                                       position: "absolute",
                                       top: 270,
                                       right: 24,
                                       zIndex: 10,
                                       pt: 0,
                                       pb: 0.2,
                                       px: 1,
                                       color: "#ccc",
                                    }}
                                 >
                                    <Box sx={{ fontSize: 10 }}>
                                       Total Cards: {cardsInHand["2"].length}
                                    </Box>
                                 </Stack>
                              )}

                              <Box
                                 sx={{
                                    position: "absolute",
                                    width: 356,
                                    height: 230,
                                    top: 564,
                                    left: 0,
                                    zIndex: 10,
                                    px: 3,
                                    overflow: "hidden",
                                 }}
                              >
                                 {cardsInHand && (
                                    <PlayerCardsInHand
                                       playerPosition={1}
                                       cardsInHand={cardsInHand["1"]}
                                    />
                                 )}
                              </Box>

                              {cardsInHand["1"].length > 8 && (
                                 <Stack
                                    alignItems='center'
                                    justifyContent='center'
                                    key={"cardsInHandExtra1"}
                                    initial={{ opacity: 0 }}
                                    animate={{
                                       opacity: 1,
                                       transition: {
                                          duration: 0.6,
                                          delay: 0.3,
                                       },
                                    }}
                                    exit={{
                                       opacity: 0,
                                    }}
                                    component={motion.div}
                                    sx={{
                                       position: "absolute",
                                       top: 797,
                                       right: 24,
                                       zIndex: 10,
                                       pt: 0,
                                       pb: 0.2,
                                       px: 1,
                                       color: "#ccc",
                                    }}
                                 >
                                    <Box sx={{ fontSize: 10 }}>
                                       Total Cards: {cardsInHand["1"].length}
                                    </Box>
                                 </Stack>
                              )}
                           </>
                        )}
                     </AnimatePresence>

                     {/* P2 Flag */}
                     <AnimatePresence>
                        {gameState && gameState.visibleCountry && (
                           <Box
                              component={motion.div}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ easing: "easeInOut" }}
                              sx={{
                                 position: "absolute",
                                 zIndex: 200,
                                 top: 212,
                                 left: "50%",
                                 transform: "translateX(-50%)",
                              }}
                           >
                              <PlayerFlag
                                 players={players}
                                 playerPosition={2}
                                 gameState={gameState}
                              />
                           </Box>
                        )}
                     </AnimatePresence>

                     {/* P1 Flag */}
                     <AnimatePresence>
                        {gameState && gameState.visibleCountry && (
                           <Box
                              component={motion.div}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ easing: "easeInOut" }}
                              sx={{
                                 position: "absolute",
                                 zIndex: 200,
                                 top: 832,
                                 left: "50%",
                                 transform: "translateX(-50%)",
                              }}
                           >
                              <PlayerFlag
                                 players={players}
                                 playerPosition={1}
                                 gameState={gameState}
                              />
                           </Box>
                        )}
                     </AnimatePresence>

                     {/* P2 Standings */}
                     <AnimatePresence>
                        {gameState && gameState.visibleStandings && (
                           <Typography
                              variant='fmStandings'
                              component='div'
                              component={motion.div}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ easing: "easeInOut" }}
                              sx={{
                                 position: "absolute",
                                 top: 235,
                                 left: 35,
                              }}
                           >
                              <PlayerStandings
                                 players={players}
                                 playerPosition={2}
                                 gameState={gameState}
                              />
                           </Typography>
                        )}
                     </AnimatePresence>

                     {/* P1 Standings */}
                     <AnimatePresence>
                        {gameState && gameState.visibleStandings && (
                           <Typography
                              variant='fmStandings'
                              component='div'
                              component={motion.div}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ easing: "easeInOut" }}
                              sx={{
                                 position: "absolute",
                                 bottom: 235,
                                 left: 35,
                              }}
                           >
                              <PlayerStandings
                                 players={players}
                                 playerPosition={1}
                                 gameState={gameState}
                              />
                           </Typography>
                        )}
                     </AnimatePresence>

                     {/* P2 Bullets */}
                     <AnimatePresence>
                        {gameState && gameState.visibleWins && (
                           <Box
                              component={motion.div}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ easing: "easeInOut" }}
                              sx={{
                                 position: "absolute",
                                 top: 255,
                                 zIndex: 100,
                                 left: "50%",
                                 transform: "translateX(-50%)",
                              }}
                           >
                              <Bullets
                                 bestof={gameState.BestOf}
                                 wins={gameState.WinsP2}
                              />
                           </Box>
                        )}
                     </AnimatePresence>

                     {/* P1 Bullets */}
                     <AnimatePresence>
                        {gameState && gameState.visibleWins && (
                           <Box
                              component={motion.div}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ easing: "easeInOut" }}
                              sx={{
                                 position: "absolute",
                                 top: 800,
                                 zIndex: 100,
                                 left: "50%",
                                 transform: "translateX(-50%)",
                              }}
                           >
                              <Bullets
                                 bestof={gameState.BestOf}
                                 wins={gameState.WinsP1}
                              />
                           </Box>
                        )}
                     </AnimatePresence>
                  </Box>
               )}

               {/* PROFILE IMAGES */}
               <AnimatePresence>
                  {gameState && gameState.visibleProfileImages && (
                     <>
                        <Box
                           sx={{
                              position: "absolute",
                              width: 356,
                              height: 230,
                              top: 0,
                              left: 0,
                              zIndex: -1,
                           }}
                        >
                           <PlayerPhoto
                              players={players}
                              playerPosition={2}
                              gameState={gameState}
                              lastUpdated={lastUpdated}
                           />
                        </Box>

                        <Box
                           sx={{
                              position: "absolute",
                              width: 356,
                              height: 230,
                              bottom: 0,
                              left: 0,
                              zIndex: -1,
                           }}
                        >
                           <PlayerPhoto
                              players={players}
                              playerPosition={1}
                              gameState={gameState}
                              lastUpdated={lastUpdated}
                           />
                        </Box>
                     </>
                  )}
               </AnimatePresence>

               <AnimatePresence>
                  {gameState && gameState.visibleTopLogo && (
                     <Box
                        component={motion.div}
                        initial={{ y: -200, opacity: 1 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -200, opacity: 0 }}
                        transition={{ easing: "easeInOut" }}
                        sx={{
                           position: "absolute",
                           width: 379,
                           height: 167,
                           right: -80,
                           zIndex: 100,
                           backgroundImage:
                              "url('" +
                              SERVER_URL +
                              "/images/ui/overlay-bkgnd-corner-logo.png')",
                        }}
                     >
                        <Grid
                           container
                           direction='row'
                           justifyContent='center'
                           alignItems='center'
                           sx={{ width: "100%", height: "100%" }}
                        >
                           <Grid item sx={{ mt: 0.7, ml: -2 }}>
                              <img
                                 src={SERVER_URL + "/images/ui/logo-800.png"}
                                 width={110}
                              />
                           </Grid>
                        </Grid>
                     </Box>
                  )}
               </AnimatePresence>

               <AnimatePresence>
                  {gameState && gameState.visibleScoreBars && (
                     <>
                        <Box
                           component={motion.div}
                           initial={{ y: -100, opacity: 1 }}
                           animate={{ y: 0, opacity: 1 }}
                           exit={{ y: -100, opacity: 0 }}
                           transition={{ easing: "easeInOut" }}
                           sx={{
                              position: "absolute",
                              width: 1564,
                              height: 74,
                              right: 0,
                              backgroundColor: "#1a1a1a",
                           }}
                        >
                           {players && (
                              <>
                                 <ScorebarPlayer
                                    players={players}
                                    playerPosition={2}
                                    gameState={gameState}
                                 />

                                 <ScorebarLifetotal
                                    playerPosition={2}
                                    lifetotal={gameState.LifeTotalP2}
                                    topBottom='top'
                                 />

                                 <ScorebarPoison
                                    playerPosition={2}
                                    poison={gameState.PoisonP2}
                                 />

                                 <AnimatePresence>
                                    {gameState.DeliriumP2 && (
                                       <Box
                                          key={"openingHandP2"}
                                          initial={{ opacity: 0 }}
                                          animate={{
                                             opacity: 1,
                                             transition: {
                                                duration: 0.6,
                                                delay: 0.3,
                                             },
                                          }}
                                          exit={{
                                             opacity: 0,
                                             transition: { duration: 0.4 },
                                          }}
                                          component={motion.div}
                                          sx={{
                                             position: "absolute",
                                             top: 78,
                                             py: 1,
                                             px: 3.5,
                                             left: 4,
                                             zIndex: 100,
                                             backgroundColor: "#2c2c2c",
                                             outline: "4px solid #222",
                                          }}
                                       >
                                          <Typography
                                             component='div'
                                             variant='fmOpeningHand'
                                             sx={{
                                                fontSize: "1.4em",
                                                textTransform: "uppercase",
                                             }}
                                          >
                                             <b>DELIRIUM</b>
                                          </Typography>
                                       </Box>
                                    )}
                                 </AnimatePresence>
                              </>
                           )}
                        </Box>

                        <Box
                           component={motion.div}
                           initial={{ y: 1155, opacity: 1 }}
                           animate={{ y: 1006, opacity: 1 }}
                           exit={{ y: 1155, opacity: 0 }}
                           transition={{ easing: "easeInOut" }}
                           sx={{
                              position: "absolute",
                              width: 1564,
                              height: 74,
                              right: 0,
                              zIndex: 9,
                              backgroundColor: "#1a1a1a",
                           }}
                        >
                           {players && (
                              <>
                                 <ScorebarPlayer
                                    players={players}
                                    playerPosition={1}
                                    gameState={gameState}
                                 />

                                 <ScorebarLifetotal
                                    playerPosition={1}
                                    lifetotal={gameState.LifeTotalP1}
                                    topBottom='bottom'
                                 />

                                 <ScorebarPoison
                                    playerPosition={1}
                                    poison={gameState.PoisonP1}
                                 />

                                 <ScorebarGameround>
                                    {gameState.RoundTitle}
                                 </ScorebarGameround>

                                 <AnimatePresence>
                                    {gameState.DeliriumP1 && (
                                       <Box
                                          key={"openingHandP1"}
                                          initial={{ opacity: 0 }}
                                          animate={{
                                             opacity: 1,
                                             transition: {
                                                duration: 0.6,
                                                delay: 0.3,
                                             },
                                          }}
                                          exit={{
                                             opacity: 0,
                                             transition: { duration: 0.4 },
                                          }}
                                          component={motion.div}
                                          sx={{
                                             position: "absolute",
                                             bottom: 78,
                                             py: 1,
                                             px: 3.5,
                                             left: 4,
                                             zIndex: 100,
                                             backgroundColor: "#2c2c2c",
                                             outline: "4px solid #222",
                                          }}
                                       >
                                          <Typography
                                             component='div'
                                             variant='fmOpeningHand'
                                             sx={{
                                                fontSize: "1.4em",
                                                textTransform: "uppercase",
                                             }}
                                          >
                                             <b>DELIRIUM</b>
                                          </Typography>
                                       </Box>
                                    )}
                                 </AnimatePresence>
                              </>
                           )}
                        </Box>
                     </>
                  )}
               </AnimatePresence>
            </WrapperViewer>
         </Box>
      </>
   );
}
