import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import LEDBracket from "./LEDBracket";
import LEDPlayers from "./LEDPlayers";
import LEDVersus from "./LEDVersus";
import LEDVersusTop8 from "./LEDVersusTop8";

import Wrapper from "../components/layout/Wrapper";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

function CustomTabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && <Box sx={{ py: 4 }}>{children}</Box>}
      </div>
   );
}

CustomTabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   };
}

export default function LEDWall({ parent = "" }) {
   let navigate = useNavigate();

   const [value, setValue] = React.useState(0);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   return (
      <Wrapper>
         <Stack spacing={2} sx={{ width: "100%" }} alignItems='center'>
            <Typography
               variant='h3'
               component='div'
               sx={{ textTransform: "uppercase", width: "100%" }}
            >
               LED Wall
            </Typography>

            <Box sx={{ width: "100%" }}>
               <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                     value={value}
                     onChange={handleChange}
                     aria-label='basic tabs example'
                  >
                     <Tab label='Bracket' {...a11yProps(0)} />
                     <Tab label='Players' {...a11yProps(1)} />
                     <Tab label='Versus' {...a11yProps(2)} />
                     <Tab label='Versus Top 8' {...a11yProps(3)} />
                  </Tabs>
               </Box>
               <CustomTabPanel value={value} index={0}>
                  <LEDBracket />
               </CustomTabPanel>
               <CustomTabPanel value={value} index={1}>
                  <LEDPlayers />
               </CustomTabPanel>
               <CustomTabPanel value={value} index={2}>
                  <LEDVersus />
               </CustomTabPanel>
               <CustomTabPanel value={value} index={3}>
                  <LEDVersusTop8 />
               </CustomTabPanel>
            </Box>
         </Stack>
      </Wrapper>
   );
}
