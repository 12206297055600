import React, { useState, useRef, useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function CardImage({
   id,
   width,
   name,
   qty,
   fileName,
   show,
   setFeaturedCardPos,
   borderRadius = 2.8,
   showName = true,
}) {
   const cardRef = useRef();

   function handleCardPosition(elem) {
      var position = {
         x: 0,
         y: 0,
         width: 0,
         height: 0,
      };

      position.x = elem.offsetLeft;
      position.y = elem.offsetTop;
      position.width = elem.getBoundingClientRect().width;
      position.height = elem.getBoundingClientRect().height;

      setFeaturedCardPos(position);
   }

   return (
      <Grid
         item
         id={"card" + id}
         sx={{
            width: width,
            color: "#fff",
            textAlign: "center",
            position: "relative",
            px: 0.5,
         }}
         key={name}
         ref={(element) => {
            cardRef.current = element;
         }}
         onClick={(el) => {
            show(fileName);
            handleCardPosition(cardRef.current);
         }}
      >
         {!fileName && (
            <>
               {showName && (
                  <Typography
                     variant='h5DecklistNoImage'
                     component='div'
                     sx={{
                        fontSize: 12,
                        width: "80%",
                        zIndex: 100,
                        top: "50%",
                        left: "50%",
                        transform: "translateX(-50%) translateY(-50%)",
                        position: "absolute",
                        color: "#fff",
                        fontWeight: "bold",
                     }}
                  >
                     {name}
                  </Typography>
               )}
               {qty && (
                  <Typography
                     variant='h5DecklistQty'
                     component='div'
                     sx={{
                        fontSize: 18,
                        lineHeight: "30px",
                        zIndex: 100,
                        bottom: 15,
                        left: "50%",
                        transform: "translateX(-50%)",
                        position: "absolute",
                        color: "#fff",
                        backgroundColor: "#1f1e1e",
                        borderRadius: 100,
                        width: 30,
                        height: 30,
                     }}
                  >
                     {qty}
                  </Typography>
               )}
               <img
                  width='100%'
                  src={SERVER_URL + "/images/card-back.png"}
                  style={{ display: "block" }}
               />
            </>
         )}

         {fileName && (
            <>
               {qty && (
                  <Typography
                     variant='h5DecklistQty'
                     component='div'
                     sx={{
                        fontSize: 18,
                        lineHeight: "30px",
                        zIndex: 100,
                        bottom: 15,
                        left: "50%",
                        transform: "translateX(-50%)",
                        position: "absolute",
                        color: "#fff",
                        backgroundColor: "#1f1e1e",
                        borderRadius: 100,
                        width: 30,
                        height: 30,
                     }}
                  >
                     {qty}
                  </Typography>
               )}

               <Box
                  sx={{
                     borderRadius: borderRadius,
                     overflow: "hidden",
                  }}
               >
                  <img
                     src={
                        SERVER_URL +
                        "/cardimages/jpg/" +
                        encodeURIComponent(fileName) +
                        ".jpg"
                     }
                     width='100%'
                     style={{ display: "block" }}
                  />
               </Box>
            </>
         )}
      </Grid>
   );
}
