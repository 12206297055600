import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { saveAs } from "file-saver";

import DownloadIcon from "@mui/icons-material/Download";
import ManaIconsFormatted from "../decklists/ManaIconsFormatted";

// MODAL
import Modal from "@mui/material/Modal";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: "90%",
   height: "90%",
   overflow: "scroll",
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
};

const SERVER_URL = window.location.protocol + "//" + window.location.host;
const TOURNAMENT_FORMAT_KEY = process.env.REACT_APP_TOURNAMENT_FORMAT_KEY;

function getBool(val) {
   if (val) {
      if (val == "y") {
         val = 1;
      }

      return !!JSON.parse(String(val).toLowerCase());
   }
}

export default function PreviewData({ data, callback }) {
   const [columns, setColumns] = useState();
   const [previewData, setPreviewData] = useState();

   useEffect(() => {
      if (
         data &&
         Array.isArray(data.response.data) &&
         data.response.data.length > 0
      ) {
         let previewColumns = [];

         switch (data.message) {
            case "getTournamentPlayers":
               previewColumns = [
                  {
                     field: "ID",
                     headerName: "Player ID",
                     width: 100,
                     editable: false,
                  },
                  {
                     field: "TournamentId",
                     headerName: "Tournament ID",
                     width: 100,
                     editable: false,
                  },
                  {
                     field: "PlayerNameLastFirst",
                     headerName: "Name",
                     width: 300,
                     editable: false,
                  },
                  {
                     field: "Decklists",
                     headerName: "Decklists (Original)",
                     width: 600,
                     editable: false,
                     renderCell: (params) => (
                        <>
                           {params.row.Decklists.map((decklist, i) => (
                              <>
                                 <Box
                                    sx={{
                                       mr: 0.5,
                                       fontWeight: "bold",
                                    }}
                                 >
                                    {decklist.Format}:
                                 </Box>
                                 <Box
                                    sx={{
                                       mr: 2,
                                    }}
                                 >
                                    <>{decklist.Name}</>
                                 </Box>
                              </>
                           ))}
                        </>
                     ),
                  },
                  {
                     field: "DecklistsMetadata",
                     headerName: "Primary Decklist Override (Metadata)",
                     width: 600,
                     editable: false,
                     renderCell: (params) => (
                        <>
                           {params.row.Decklists.map((decklist, i) => (
                              <>
                                 {decklist.Format == "Standard" && (
                                    <>
                                       <Box
                                          sx={{
                                             mr: 0.5,
                                             fontWeight: "bold",
                                          }}
                                       >
                                          {decklist.Format}:
                                       </Box>
                                       <Box
                                          sx={{
                                             mr: 2,
                                             color:
                                                params.row[
                                                   TOURNAMENT_FORMAT_KEY +
                                                      " Deck"
                                                ] != decklist.Name
                                                   ? "red"
                                                   : "",
                                          }}
                                       >
                                          {
                                             params.row[
                                                TOURNAMENT_FORMAT_KEY + " Deck"
                                             ]
                                          }
                                       </Box>
                                    </>
                                 )}
                              </>
                           ))}
                        </>
                     ),
                  },
               ];
               break;
            case "getCurrentStandings":
               previewColumns = [
                  {
                     field: "Rank",
                     headerName: "Rank",
                     width: 100,
                     editable: false,
                  },
                  {
                     field: "PlayerNameLastFirst",
                     headerName: "Name",
                     width: 400,
                     editable: false,
                  },
                  {
                     field: "COUNTRY",
                     headerName: "Country",
                     width: 200,
                     editable: false,
                  },
                  {
                     field: "Record",
                     headerName: "Record",
                     width: 100,
                     editable: false,
                  },
                  {
                     field: "Points",
                     headerName: "Points",
                     width: 100,
                     editable: false,
                  },
               ];
               break;
            case "getTournamentDecklists":
               previewColumns = [
                  {
                     field: "ID",
                     headerName: "ID",
                     width: 100,
                     editable: false,
                  },
                  {
                     field: "PlayerId",
                     headerName: "Player ID",
                     width: 100,
                     editable: false,
                  },
                  {
                     field: "PlayerNameLastFirst",
                     headerName: "Name",
                     width: 250,
                     editable: false,
                  },
                  {
                     field: "Format",
                     headerName: "Format",
                     width: 100,
                     editable: false,
                  },
                  {
                     field: "Archetype",
                     headerName: "Archetype (Original)",
                     width: 400,
                     editable: false,
                     renderCell: (params) => (
                        <>
                           <Stack direction='row' spacing={0.5}>
                              <Box
                                 sx={{
                                    width: 20,
                                    height: 20,
                                    opacity: getBool(params.row.IsWhite)
                                       ? 1
                                       : 0.1,
                                 }}
                              >
                                 <img
                                    src={SERVER_URL + "/images/mana/white.png"}
                                    width='100%'
                                 />
                              </Box>
                              <Box
                                 sx={{
                                    width: 20,
                                    height: 20,
                                    opacity: getBool(params.row.IsBlue)
                                       ? 1
                                       : 0.1,
                                 }}
                              >
                                 <img
                                    src={SERVER_URL + "/images/mana/blue.png"}
                                    width='100%'
                                 />
                              </Box>
                              <Box
                                 sx={{
                                    width: 20,
                                    height: 20,
                                    opacity: getBool(params.row.IsBlack)
                                       ? 1
                                       : 0.1,
                                 }}
                              >
                                 <img
                                    src={SERVER_URL + "/images/mana/black.png"}
                                    width='100%'
                                 />
                              </Box>
                              <Box
                                 sx={{
                                    width: 20,
                                    height: 20,
                                    opacity: getBool(params.row.IsRed)
                                       ? 1
                                       : 0.1,
                                 }}
                              >
                                 <img
                                    src={SERVER_URL + "/images/mana/red.png"}
                                    width='100%'
                                 />
                              </Box>
                              <Box
                                 sx={{
                                    width: 20,
                                    height: 20,
                                    opacity: getBool(params.row.IsGreen)
                                       ? 1
                                       : 0.1,
                                 }}
                              >
                                 <img
                                    src={SERVER_URL + "/images/mana/green.png"}
                                    width='100%'
                                 />
                              </Box>
                              <Box
                                 sx={{
                                    width: 20,
                                    height: 20,
                                    opacity: getBool(params.row.IsColorless)
                                       ? 1
                                       : 0.1,
                                 }}
                              >
                                 <img
                                    src={
                                       SERVER_URL + "/images/mana/colorless.png"
                                    }
                                    width='100%'
                                 />
                              </Box>
                              {!params.value && <Box sx={{ pl: 2 }}>None</Box>}
                              {params.value && (
                                 <Box sx={{ pl: 2 }}>{params.value}</Box>
                              )}
                           </Stack>
                        </>
                     ),
                  },
                  {
                     field: TOURNAMENT_FORMAT_KEY + " DECK",
                     headerName: "Archetype (Metadata Override)",
                     width: 400,
                     editable: false,
                     renderCell: (params) => (
                        <>
                           <Stack direction='row' spacing={0.5}>
                              <Box
                                 sx={{
                                    width: 20,
                                    height: 20,
                                    opacity: getBool(
                                       params.row[
                                          TOURNAMENT_FORMAT_KEY +
                                             " DECK IsWhite"
                                       ]
                                    )
                                       ? 1
                                       : 0.1,
                                 }}
                              >
                                 <img
                                    src={SERVER_URL + "/images/mana/white.png"}
                                    width='100%'
                                 />
                              </Box>
                              <Box
                                 sx={{
                                    width: 20,
                                    height: 20,
                                    opacity: getBool(
                                       params.row[
                                          TOURNAMENT_FORMAT_KEY + " DECK IsBlue"
                                       ]
                                    )
                                       ? 1
                                       : 0.1,
                                 }}
                              >
                                 <img
                                    src={SERVER_URL + "/images/mana/blue.png"}
                                    width='100%'
                                 />
                              </Box>
                              <Box
                                 sx={{
                                    width: 20,
                                    height: 20,
                                    opacity: getBool(
                                       params.row[
                                          TOURNAMENT_FORMAT_KEY +
                                             " DECK IsBlack"
                                       ]
                                    )
                                       ? 1
                                       : 0.1,
                                 }}
                              >
                                 <img
                                    src={SERVER_URL + "/images/mana/black.png"}
                                    width='100%'
                                 />
                              </Box>
                              <Box
                                 sx={{
                                    width: 20,
                                    height: 20,
                                    opacity: getBool(
                                       params.row[
                                          TOURNAMENT_FORMAT_KEY + " DECK IsRed"
                                       ]
                                    )
                                       ? 1
                                       : 0.1,
                                 }}
                              >
                                 <img
                                    src={SERVER_URL + "/images/mana/red.png"}
                                    width='100%'
                                 />
                              </Box>
                              <Box
                                 sx={{
                                    width: 20,
                                    height: 20,
                                    opacity: getBool(
                                       params.row[
                                          TOURNAMENT_FORMAT_KEY +
                                             " DECK IsGreen"
                                       ]
                                    )
                                       ? 1
                                       : 0.1,
                                 }}
                              >
                                 <img
                                    src={SERVER_URL + "/images/mana/green.png"}
                                    width='100%'
                                 />
                              </Box>
                              <Box
                                 sx={{
                                    width: 20,
                                    height: 20,
                                    opacity: getBool(
                                       params.row[
                                          TOURNAMENT_FORMAT_KEY +
                                             " DECK IsColorless"
                                       ]
                                    )
                                       ? 1
                                       : 0.1,
                                 }}
                              >
                                 <img
                                    src={
                                       SERVER_URL + "/images/mana/colorless.png"
                                    }
                                    width='100%'
                                 />
                              </Box>
                              {!params.value && <Box sx={{ pl: 2 }}>None</Box>}
                              {params.value &&
                                 params.row.Format == "Standard" && (
                                    <Box
                                       sx={{
                                          pl: 2,
                                          color:
                                             params.row.Archetype !=
                                             params.value
                                                ? "red"
                                                : "",
                                       }}
                                    >
                                       {params.value}
                                    </Box>
                                 )}
                              {params.value &&
                                 params.row.Format != "Standard" && (
                                    <Box
                                       sx={{
                                          pl: 2,
                                       }}
                                    >
                                       None
                                    </Box>
                                 )}
                           </Stack>
                        </>
                     ),
                  },
               ];
               break;
            default:
               previewColumns = Object.keys(data.response.data[0]).map(
                  (column) => {
                     return { key: column, title: column, width: 100 };
                  }
               );
         }

         setColumns(previewColumns);

         setPreviewData(data);
      }
   }, [data]);

   const handleCallback = (e) => {
      callback(e.target.value);
   };

   const handleClose = () => {
      setPreviewData();
   };

   return (
      <>
         {previewData && columns.length > 0 && (
            <Modal open={previewData ? true : false} onClose={handleClose}>
               <Box sx={style}>
                  <Stack direction='row'>
                     <Typography
                        variant='h4'
                        component='div'
                        sx={{ color: "#000", whiteSpace: "nowrap", mr: 4 }}
                     >
                        {previewData.response.message} | {previewData.message}
                     </Typography>
                     <Box sx={{ width: "100%" }}>
                        <Button
                           variant='contained'
                           color='error'
                           sx={{ whiteSpace: "nowrap", mr: 2 }}
                           onClick={() => {
                              callback(previewData.message);
                              handleClose();
                           }}
                        >
                           SAVE
                        </Button>
                     </Box>

                     <Button
                        variant='outlined'
                        sx={{ whiteSpace: "nowrap" }}
                        onClick={handleClose}
                     >
                        CANCEL
                     </Button>
                  </Stack>

                  <Typography
                     id='modal-modal-title'
                     variant='h5'
                     component='div'
                     sx={{ color: "#000" }}
                  >
                     {previewData.response.rowCount && (
                        <>Total Rows: {previewData.response.rowCount}</>
                     )}
                  </Typography>
                  <Typography id='modal-data' sx={{ mt: 2, height: "90%" }}>
                     {previewData.response.data && (
                        <DataGrid
                           getRowId={(row) => row.ID}
                           sx={{ backgroundColor: "#efefef" }}
                           rows={previewData.response.data.filter((row) => {
                              return row.ID !== null;
                           })}
                           rowHeight={40}
                           disableColumnFilter={false}
                           columns={columns}
                           rowsPerPageOptions={[100, 200, 300, 1000, 5000]}
                           components={{ Toolbar: GridToolbar }}
                        />
                     )}
                  </Typography>
               </Box>
            </Modal>
         )}
      </>
   );
}
