import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function LEDWrapperViewer({
   children,
   preview,
   videobackground,
}) {
   return (
      <Box
         sx={{
            width: 2160,
            height: 1080,
            position: "relative",
            overflow: "hidden",
         }}
      >
         {children}

         {videobackground && (
            <video
               autoPlay
               preload='none'
               loop
               muted
               id='videobg'
               style={{
                  position: "absolute",
                  width: 2160,
                  height: 1080,
                  top: 0,
                  left: 0,
                  width: "100%",
                  zIndex: -1,
               }}
            >
               <source
                  src={SERVER_URL + "/videos/background-black-corner.mp4"}
                  type='video/mp4'
               />
            </video>
         )}
      </Box>
   );
}
