import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ManaIconsFormatted from "../decklists/ManaIconsFormatted";

import { useTheme } from "@mui/material/styles";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function DraftPicks({ picks }) {
   const theme = useTheme();

   const [pack, setPack] = useState(1);
   const [packCards, setPackCards] = useState([]);

   useEffect(() => {
      if (picks.length <= 14) {
         setPack(1);
         setPackCards(picks.slice(0, 14));
      } else if (picks.length >= 15 && picks.length <= 28) {
         setPack(2);
         setPackCards(picks.slice(0, picks.length - 14));
      } else if (picks.length >= 29) {
         setPack(3);
         setPackCards(picks.slice(0, picks.length - 28));
      }
   }, [picks]);

   return (
      <Stack
         key={"draftPicks" + pack}
         initial={{ opacity: 0 }}
         animate={{
            opacity: 1,
            transition: {
               duration: 0.6,
               delay: 0.3,
            },
         }}
         exit={{
            opacity: 0,
            transition: { duration: 0.4 },
         }}
         component={motion.div}
         alignItems='center'
         justifyContent='center'
         sx={{
            color: theme.palette.text.dark,
            position: "absolute",
            width: 280,
            top: 290,
            height: 25,
            pt: 0.2,
            left: 38,
            zIndex: 10,
            backgroundColor: "#2c2c2c",
            outline: "4px solid #222",
         }}
      >
         <Typography
            component='div'
            variant='fmPlayerDeck'
            sx={{
               textTransform: "uppercase",
               textAlign: "center",
            }}
         >
            Draft Picks - Pack {pack}
         </Typography>

         <Box
            sx={{
               position: "absolute",
               width: 287,
               height: 404,
               top: 50,
               left: -4,
               zIndex: 10,
               overflow: "hidden",
            }}
         >
            <Box
               key={"draftPicks"}
               component={motion.div}
               initial={{ opacity: 0 }}
               animate={{
                  opacity: 1,
                  transition: { delay: 0.2 },
               }}
               exit={{ opacity: 0 }}
            >
               <AnimatePresence>
                  {packCards.map((row, i) => (
                     <Box
                        layout
                        key={"card" + row.card.id + "-" + row.card.FileName}
                        component={motion.div}
                        sx={{
                           position: "relative",
                           fontFamily: "GothamNarrow-Medium",
                           px: 1.2,
                           pt: 0.75,
                           pb: 0.5,
                           mb: 0.38,
                           fontSize: "1rem",
                           lineHeight: "1rem",
                           color: theme.palette.text.white,
                           backgroundColor: "#1f1f1f",
                        }}
                        key={"cardsInHand" + row.id}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ layout: { duration: 0.4 } }}
                     >
                        <Stack direction='row' spacing={1} alignItems='center'>
                           <Box
                              sx={{
                                 whiteSpace: "nowrap",
                                 width: "100%",
                                 overflow: "hidden",
                                 maskImage:
                                    "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%)",
                                 maskRepeat: "no-repeat",
                              }}
                           >
                              {row.card.CardName}
                           </Box>

                           <Box
                              sx={{
                                 position: "absolute",
                                 right: 5,
                                 zIndex: 10,
                                 whiteSpace: "nowrap",
                                 overflow: "hidden",
                                 maskImage:
                                    "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 25px, rgba(0,0,0,1) 100%)",
                                 maskRepeat: "no-repeat",
                                 backgroundColor: "#1f1f1f",
                                 pl: "25px",
                              }}
                           >
                              <ManaIconsFormatted
                                 iconWidth={15}
                                 mana={row.card.CardManaCost}
                              />
                           </Box>
                        </Stack>
                     </Box>
                  ))}
               </AnimatePresence>
            </Box>
         </Box>
      </Stack>
   );
}
