import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function useRoundByRound() {
   const socket = useContext(SocketContext);

   const [allRoundByRound, setAllRoundByRound] = useState();
   const [roundByRound, setRoundByRound] = useState();
   const [show, setShow] = useState(true);
   const [runTimestamp, setRunTimestamp] = useState(Date.now());

   const handlers = useMemo(
      () => ({
         update: (id, updates) => {
            socket.emit("updateRoundByRound", id, updates, (response) => {
               socket.emit(
                  "roundByRoundUpsert",
                  updates.PlayerId,
                  (response) => {
                     handlers.getRoundByRound(id);
                  }
               );
            });
         },
         addRoundByRound: (callback) => {
            socket.emit("addRoundByRound", (response) => {
               handlers.getAllRoundByRound();
            });
         },
         deleteRoundbyRound: (id) => {
            socket.emit("deleteRoundByRound", id, (response) => {
               setAllRoundByRound(response);
            });
         },
         getAllRoundByRound: () => {
            socket.emit("getAllRoundByRound", (response) => {
               setAllRoundByRound(response);
            });
         },
         getRoundByRound: (id) => {
            if (id) {
               socket.emit("getRoundByRound", id, (response) => {
                  setRoundByRound(response);
               });
            }
         },
         upsertRoundByRound: (id, playerId) => {
            if (playerId) {
               socket.emit("roundByRoundUpsert", playerId, (response) => {
                  handlers.getRoundByRound(id);
               });
            }
         },
         roundByRoundViewerLoad: (id) => {
            if (id) {
               socket.emit("roundByRoundViewerLoad", id);
               socket.emit("roundByRoundViewerShowHide", "show");
            }
         },
         roundByRoundViewerShowHide: (action) => {
            socket.emit("roundByRoundViewerShowHide", action);
         },
         subscribeRoundByRoundViewer: () => {
            socket.emit("subscribeRoundByRoundViewer", (response) => {
               console.log(response);
            });

            socket.on("roundByRoundViewerLoad", (data) => {
               setRoundByRound(data);
            });

            socket.on("roundByRoundViewerShowHide", (action) => {
               switch (action) {
                  case "show":
                     setRunTimestamp(Date.now());
                     setShow(true);
                     break;
                  case "hide":
                     setShow(false);
                     break;
                  case "toggle":
                     setShow((prevState) => {
                        return !prevState;
                     });
                     break;
               }
            });
         },
      }),
      []
   );

   return [allRoundByRound, roundByRound, show, runTimestamp, handlers];
}
