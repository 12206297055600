import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function useLEDVersusTop8() {
   const socket = useContext(SocketContext);

   const [title, setTitle] = useState("");
   const [allPlayers, setAllPlayers] = useState([]);
   const [selectedPlayers, setSelectedPlayers] = useState([]);
   const [show, setShow] = useState(true);

   const handlers = useMemo(
      () => ({
         getAllPlayers: () => {
            socket.emit("getAllPlayers", (response) => {
               if (response) {
                  setAllPlayers(response);
               }
            });
         },
         getSelectedPlayers: () => {
            socket.emit("getLEDVersusTop8", (response) => {
               if (response) {
                  setTitle(response.title);
                  setSelectedPlayers(response.players);
               }
            });
         },
         updateTitle: (id, data) => {
            socket.emit("updateGlobal", id, data);
         },
         update: (id, playerId) => {
            socket.emit("updateLEDVersusTop8", {
               id: id,
               playerId: playerId,
               decklistId: 0,
               champion: false,
            });
         },
         updateDeck: (id, decklistId) => {
            socket.emit("updateLEDVersusTop8", {
               id: id,
               decklistId: decklistId,
            });
         },
         updateChampion: (id, value) => {
            socket.emit("updateLEDVersusTop8", {
               id: id,
               champion: value,
            });
         },
         load: () => {
            socket.emit("LEDVersusTop8ViewerLoad");
         },
         showHide: (action) => {
            socket.emit("LEDVersusTop8ViewerShowHide", action);
         },
         subscribeLEDVersusTop8Viewer: () => {
            socket.emit("subscribeLEDVersusTop8Viewer", (response) => {
               console.log(response);
            });

            socket.on("LEDVersusTop8ViewerLoad", (players) => {
               handlers.getSelectedPlayers();
            });

            socket.on("LEDVersusTop8ViewerShowHide", (action) => {
               switch (action) {
                  case "show":
                     setShow(true);
                     break;
                  case "hide":
                     setShow(false);
                     break;
                  case "toggle":
                     setShow((prevState) => {
                        return !prevState;
                     });
                     break;
               }
            });
         },
      }),
      []
   );

   return [title, allPlayers, selectedPlayers, show, handlers];
}
