import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import WrapperIpad from "../components/layout/WrapperIpad";
import PlayerIpadControls from "../components/featurematch/PlayerIpadControls";

//HOOKS
import useFeatureMatchHandler from "../hooks/useFeatureMatchHandler";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function DashboardTable() {
   let { tableId } = useParams();

   // USE SOCKETS HOOK
   const [
      players,
      allPlayers,
      gameState,
      cardsInHand,
      update,
      lastUpdated,
      saveRequired,
      needsSave,
      { subscribeFeatureMatch, saveUpdates, getAllPlayers, cancelUpdates },
   ] = useFeatureMatchHandler();

   useEffect(() => {
      subscribeFeatureMatch(tableId);
      getAllPlayers();
   }, [tableId]);

   const playerName = (playerId) => {
      const playerData = _.find(players, {
         id: playerId,
      });

      if (playerData) {
         return playerData.PlayerName;
      } else {
         return "";
      }
   };

   return (
      <WrapperIpad key={"ipadInterface" + tableId}>
         <Typography
            variant='h6'
            component='div'
            sx={{
               textAlign: "center",
               textTransform: "uppercase",
               position: "fixed",
               bottom: 20,
               right: 20,
               color: "#555",
               zIndex: 100,
            }}
         >
            Feature Match Table {tableId}
         </Typography>

         <Box sx={{ width: "100%", height: "100%" }}>
            {saveRequired && (
               <Stack
                  direction='row'
                  spacing={2}
                  sx={{
                     width: "100%",
                     position: "absolute",
                     top: 0,
                     zIndex: 100,
                     p: 2,
                  }}
               >
                  <Button
                     variant='contained'
                     color='error'
                     sx={{
                        fontSize: "2vw",
                     }}
                     onClick={() => saveUpdates(tableId)}
                     fullWidth
                  >
                     SAVE CHANGES
                  </Button>

                  <Button
                     variant='contained'
                     color='secondary'
                     sx={{
                        fontSize: "2vw",
                     }}
                     onClick={() => cancelUpdates(tableId)}
                  >
                     CANCEL
                  </Button>
               </Stack>
            )}

            {gameState && (
               <Grid container sx={{ p: 0, width: "100%", height: "100%" }}>
                  <Grid
                     item
                     xs={6}
                     sx={{
                        backgroundColor: "#111",
                        textAlign: "center",
                        overflow: "hidden",
                        position: "relative",
                     }}
                  >
                     <PlayerIpadControls
                        gameState={gameState}
                        player={2}
                        tableId={tableId}
                        playerName={playerName(gameState["PlayerIdP2"])}
                        callback={update}
                     />
                  </Grid>
                  <Grid
                     item
                     xs={6}
                     sx={{
                        backgroundColor: "#000",
                        textAlign: "center",
                        position: "relative",
                     }}
                  >
                     <PlayerIpadControls
                        gameState={gameState}
                        player={1}
                        tableId={tableId}
                        playerName={playerName(gameState["PlayerIdP1"])}
                        callback={update}
                     />
                  </Grid>
               </Grid>
            )}
         </Box>
      </WrapperIpad>
   );
}
