import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function useDatabaseManagement() {
   const socket = useContext(SocketContext);

   const [log, setLog] = useState([]);
   const [previewData, setPreviewData] = useState();
   const [notification, setNotification] = useState();

   socket.on("message", (message) => {
      setNotification(message);
   });

   const handlers = useMemo(
      () => ({
         clearPreview: () => {
            setPreviewData();
         },
         preview: (endpoint) => {
            axios
               .get(SERVER_URL + "/api/melee/preview/" + endpoint)
               .then((response) => {
                  // console.log(response);
                  setPreviewData({
                     message: endpoint,
                     timestamp: Date.now(),
                     response: response.data,
                  });
               });
         },
         insert: (endpoint) => {
            axios
               .get(SERVER_URL + "/api/melee/insert/" + endpoint)
               .then((response) => {
                  setLog((prevState) => [
                     {
                        message: endpoint,
                        timestamp: Date.now(),
                        response: response,
                     },
                     ...prevState,
                  ]);
               });
         },
         upsert: (endpoint) => {
            axios
               .get(SERVER_URL + "/api/melee/upsert/" + endpoint)
               .then((response) => {
                  setLog((prevState) => [
                     {
                        message: endpoint,
                        timestamp: Date.now(),
                        response: response,
                     },
                     ...prevState,
                  ]);
               });
         },
         overwrite: (endpoint) => {
            axios
               .get(SERVER_URL + "/api/melee/truncate/" + endpoint)
               .then((response) => {
                  setLog((prevState) => [
                     {
                        message: endpoint,
                        timestamp: Date.now(),
                        response: response,
                     },
                     ...prevState,
                  ]);
               });
         },
         refreshTournament: () => {
            axios
               .get(SERVER_URL + "/melee/refreshTournament")
               .then((response) => {
                  setLog((prevState) => [
                     {
                        message: "Import Success",
                        timestamp: Date.now(),
                        response: {
                           data: response.data,
                        },
                     },
                     ...prevState,
                  ]);
                  return response;
               });
         },
         updateAll: () => {
            const meleeEndpoints = [
               "getTournament",
               "getTournamentDecklists",
               "getTournamentPlayers",
            ];

            _.each(meleeEndpoints, (endpoint) => {
               axios
                  .get(SERVER_URL + "/api/melee/truncate/" + endpoint)
                  .then((response) => {
                     setLog((prevState) => [
                        {
                           message: endpoint,
                           timestamp: Date.now(),
                           response: response,
                        },
                        ...prevState,
                     ]);
                  });
            });
         },
         initNewTournament: () => {
            axios.get(SERVER_URL + "/api/initTournament").then((response) => {
               // console.log(response, log);
               setLog((prevState) => [...response.data, ...prevState]);
            });
         },
         getPlayerData: async (playerId) => {
            return axios
               .get(SERVER_URL + "/melee/api/insert/player/" + playerId)
               .then((response) => {
                  return response;
               });
         },
         importCardsJSON: () => {
            setNotification("loading");
            socket.emit("importCardsJSON", (response) => {
               setNotification(response);
            });
         },
         importScryfallJSON: () => {
            setNotification("loading");
            socket.emit("importScryfallJSON", (response) => {
               setNotification(response);
            });
         },
         importCardImages: () => {
            setNotification("loading");
            socket.emit("importCardImages", (response) => {
               setNotification(response);
            });
         },
         importStandings: (truncate = false) => {
            const apiPath = truncate
               ? "/melee/api/truncate/insert/standing/list/current"
               : "/melee/api/insert/standing/list/current";

            return axios.get(SERVER_URL + apiPath).then((response) => {
               setLog((prevState) => [
                  {
                     message: "Import Success",
                     timestamp: Date.now(),
                     response: {
                        data: response.data,
                     },
                  },
                  ...prevState,
               ]);
            });
         },
         importDecklists: (truncate = false) => {
            const apiPath = truncate
               ? "/melee/api/truncate/insert/decklist/list"
               : "/melee/api/insert/decklist/list";

            return axios.get(SERVER_URL + apiPath).then((response) => {
               setLog((prevState) => [
                  {
                     message: "Import Success",
                     timestamp: Date.now(),
                     response: {
                        data: response.data,
                     },
                  },
                  ...prevState,
               ]);
               return response;
            });
         },
         importPlayers: (truncate = false) => {
            const apiPath = truncate
               ? "/melee/api/truncate/insert/player/list"
               : "/melee/api/insert/player/list";

            return axios.get(SERVER_URL + apiPath).then((response) => {
               setLog((prevState) => [
                  {
                     message: "Import Success",
                     timestamp: Date.now(),
                     response: {
                        data: response.data,
                     },
                  },
                  ...prevState,
               ]);
               return response;
            });
         },
         checkForMissingImages: () => {
            socket.emit("checkForMissingImages", (response) => {
               const logResponse = { data: response };
               setLog((prevState) => [
                  {
                     message:
                        "MTG Melee Decklists Images not found in Database!",
                     timestamp: Date.now(),
                     response: {
                        data: [
                           {
                              message: response.length + " missing images.",
                              missing: logResponse,
                           },
                        ],
                     },
                  },
                  ...prevState,
               ]);
            });
         },
         checkPlayerFlags: () => {
            socket.emit("checkPlayerFlags", (response) => {
               const logResponse = { data: response };
               setLog((prevState) => [
                  {
                     message: "Missing Player Flags",
                     timestamp: Date.now(),
                     response: {
                        data: [{ message: response }],
                     },
                  },
                  ...prevState,
               ]);
            });
         },
         checkForDuplicateCards: () => {
            socket.emit("checkForDuplicateCards", (response) => {
               const logResponse = { data: response };
               setLog((prevState) => [
                  {
                     message: "Duplicate Main Deck Card Rows",
                     timestamp: Date.now(),
                     response: {
                        data: [{ message: response }],
                     },
                  },
                  ...prevState,
               ]);
            });
         },
         checkForDuplicateSideboard: () => {
            socket.emit("checkForDuplicateSideboard", (response) => {
               const logResponse = { data: response };
               setLog((prevState) => [
                  {
                     message: "Duplicate Sideboard Deck Card Rows",
                     timestamp: Date.now(),
                     response: {
                        data: [{ message: response }],
                     },
                  },
                  ...prevState,
               ]);
            });
         },
      }),
      []
   );

   return [log, previewData, notification, handlers];
}
