import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function DataGridContainer({
   data,
   height,
   disableColumnFilter,
   handleLoadIframe,
   callback,
   preview,
}) {
   let columns;
   let initialState;

   columns = [
      {
         field: "id",
         headerName: "Id",
         editable: false,
         width: 50,
         filterable: false,
      },
      {
         field: "table",
         headerName: "Table",
         editable: false,
         width: 150,
         filterable: true,
      },
      {
         field: "title",
         headerName: "Title",
         editable: false,
         width: 150,
         filterable: true,
      },
      {
         field: "add",
         headerName: "Add",
         editable: false,
         width: 200,
         filterable: false,
         renderCell: (params) => (
            <Button
               fullWidth
               variant='outlined'
               color='error'
               onClick={() =>
                  preview(params.row.player1.id, params.row.player2.id)
               }
            >
               ADD H2H /<br />
               PLAYER PROFILE
            </Button>
         ),
      },
      {
         field: "p1",
         headerName: "Player 1",
         editable: false,
         width: 250,
         filterable: false,
         renderCell: (params) => (
            <Box>
               {params.row.player1 ? params.row.player1.Name : "No Player"}
               <br />
               <i>
                  {params.row.player1 && params.row.player1.decklist
                     ? params.row.player1.decklist.Archetype +
                       " | " +
                       params.row.player1.decklist.Format
                     : "No Decklist"}
               </i>
            </Box>
         ),
      },
      {
         field: "p2",
         headerName: "Player 2",
         editable: false,
         width: 250,
         filterable: false,
         renderCell: (params) => (
            <Box>
               {params.row.player2 ? params.row.player2.Name : "No Player"}
               <br />
               <i>
                  {params.row.player2 && params.row.player2.decklist
                     ? params.row.player2.decklist.Archetype +
                       " | " +
                       params.row.player2.decklist.Format
                     : "No Decklist"}
               </i>
            </Box>
         ),
      },
      {
         field: "createdAt",
         headerName: "Saved At",
         editable: false,
         width: 200,
         filterable: false,
      },
   ];

   initialState = {
      sorting: {
         sortModel: [{ field: "createdAt", sort: "desc" }],
      },
      columns: {
         columnVisibilityModel: {
            id: false,
         },
      },
   };

   const handleCallback = (e) => {
      callback(e.target.id, e.target.value);
   };

   return (
      <>
         <Box sx={{ height: height ? height : 400, width: "100%" }}>
            <DataGrid
               getRowId={(row) => row.id}
               initialState={initialState}
               sx={{ backgroundColor: "#efefef" }}
               rows={data}
               rowHeight={120}
               disableColumnFilter={disableColumnFilter}
               columns={columns}
               pageSize={8}
               components={{ Toolbar: GridToolbar }}
               componentsProps={{
                  toolbar: {
                     printOptions: {
                        disableToolbarButton: true,
                     },
                  },
               }}
               disableSelectionOnClick
            />
         </Box>
      </>
   );
}
