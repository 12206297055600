import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import WrapperViewer from "../../components/layout/WrapperViewer";

import PlayerShield from "../../components/headtohead/PlayerShield";
import PlayerPhoto from "../../components/headtohead/PlayerPhoto";
import PlayerData from "../../components/headtohead/PlayerData";
import PlayerDecklists from "../../components/headtohead/PlayerDecklists";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function HeadToHeadPreview({ data, show }) {
   const theme = useTheme();

   useEffect(() => {
      // console.log(data);
   }, [data]);

   return (
      <WrapperViewer>
         {/* LOGO */}
         <Box
            key='logo'
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            component={motion.div}
            sx={{
               position: "absolute",
               bottom: 40,
               left: 100,
               zIndex: 100,
            }}
         >
            <img src={SERVER_URL + "/images/ui/logo-800.png"} width='140' />
         </Box>

         {/* HEAD TO HEAD GRAPHIC */}
         <AnimatePresence>
            {data && show && (
               <React.Fragment key={"slide" + data.id}>
                  {/* P1 SHIELD */}
                  <Box
                     initial={{ opacity: 0, x: -100 }}
                     animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                           delay: 0.1,
                           easing: "easeOut",
                           duration: 0.6,
                        },
                     }}
                     exit={{
                        opacity: 0,
                        x: -100,
                        transition: {
                           delay: 0.1,
                           easing: "easeIn",
                           duration: 0.3,
                        },
                     }}
                     component={motion.div}
                     sx={{
                        position: "absolute",
                        top: 50,
                        left: 20,
                        width: 698,
                        zIndex: 100,
                     }}
                  >
                     <PlayerShield data={data.Player1Data} />
                     <PlayerPhoto photo={data.Player1Photo} />
                  </Box>

                  {/* P2 SHIELD */}

                  <Box
                     initial={{ opacity: 0, x: 100 }}
                     animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                           delay: 0.1,
                           easing: "easeOut",
                           duration: 0.6,
                        },
                     }}
                     exit={{
                        opacity: 0,
                        x: 100,
                        transition: {
                           delay: 0.1,
                           easing: "easeIn",
                           duration: 0.3,
                        },
                     }}
                     component={motion.div}
                     sx={{
                        position: "absolute",
                        top: 50,
                        right: 20,
                        width: 698,
                        zIndex: 100,
                     }}
                  >
                     <PlayerShield data={data.Player2Data} />
                     <PlayerPhoto photo={data.Player2Photo} />
                  </Box>

                  {/* CONTENT */}
                  <Stack
                     initial={{ opacity: 0, y: 100 }}
                     animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                           delay: 0.5,
                           easing: "easeOut",
                           duration: 0.4,
                        },
                     }}
                     exit={{
                        opacity: 0,
                        y: 100,
                        transition: {
                           delay: 0,
                           easing: "easeOut",
                           duration: 0.2,
                        },
                     }}
                     component={motion.div}
                     sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 450,
                        width: 1020,
                        height: 980,
                        zIndex: 10,
                     }}
                  >
                     <Box
                        sx={{
                           color: "#fff",
                           background: "transparent",
                        }}
                     >
                        <Typography variant='h1headtohead' component='div'>
                           HEAD TO HEAD
                        </Typography>
                     </Box>
                     <Stack
                        sx={{
                           height: 890,
                           zIndex: 10,
                           background: theme.palette.background.gradient5050,
                        }}
                     >
                        <Box
                           sx={{
                              py: 0.7,
                              background: theme.palette.background.gradient,
                           }}
                        ></Box>
                        <Box
                           sx={{
                              py: 5,
                              px: 25,
                           }}
                        >
                           {/* COLUMNS */}
                           <Stack
                              direction='row'
                              spacing={3}
                              justifyContent='center'
                              alignItems='stretch'
                              sx={{
                                 width: "100%",
                                 textAlign: "center",
                              }}
                           >
                              {/* COLUMN 1 */}
                              <Stack
                                 spacing={6}
                                 justifyContent='flex-start'
                                 alignItems='stretch'
                                 sx={{
                                    width: "100%",
                                 }}
                              >
                                 <PlayerData
                                    data={data.Player1Data}
                                    dataFields={data.DataFields}
                                 />

                                 <Stack
                                    direction='row'
                                    spacing={4}
                                    justifyContent='center'
                                    alignItems='center'
                                    sx={{
                                       height: 65,
                                       width: "100%",
                                    }}
                                 >
                                    {data.Player1Data.HOF &&
                                       parseInt(data.Player1Data.HOF) === 1 && (
                                          <img
                                             src={
                                                SERVER_URL +
                                                "/images/icons/hof.png"
                                             }
                                             width='30%'
                                          />
                                       )}

                                    {data.Player1Data.WORLDS &&
                                       parseInt(data.Player1Data.WORLDS) ===
                                          1 && (
                                          <img
                                             src={
                                                SERVER_URL +
                                                "/images/icons/wc.png"
                                             }
                                             width='30%'
                                          />
                                       )}
                                 </Stack>
                              </Stack>

                              {/* COLUMN 2 */}
                              <Stack
                                 spacing={6}
                                 justifyContent='flex-start'
                                 alignItems='stretch'
                                 sx={{
                                    width: "100%",
                                 }}
                              >
                                 <PlayerData
                                    data={data.Player2Data}
                                    dataFields={data.DataFields}
                                 />

                                 <Stack
                                    direction='row'
                                    spacing={4}
                                    justifyContent='center'
                                    alignItems='center'
                                    sx={{
                                       width: "100%",
                                       height: 65,
                                    }}
                                 >
                                    {data.Player2Data.HOF &&
                                       parseInt(data.Player2Data.HOF) === 1 && (
                                          <img
                                             src={
                                                SERVER_URL +
                                                "/images/icons/hof.png"
                                             }
                                             width='30%'
                                          />
                                       )}

                                    {data.Player2Data.WORLDS &&
                                       parseInt(data.Player2Data.WORLDS) ===
                                          1 && (
                                          <img
                                             src={
                                                SERVER_URL +
                                                "/images/icons/wc.png"
                                             }
                                             width='30%'
                                          />
                                       )}
                                 </Stack>
                              </Stack>
                           </Stack>
                        </Box>

                        {/* DECKLIST */}
                        <Stack
                           direction='row'
                           justifyContent='center'
                           alignItems='flex-start'
                           sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              height: 250,
                              width: "100%",
                              textAlign: "center",
                           }}
                        >
                           <Box
                              sx={{
                                 width: "100%",
                                 height: "100%",
                              }}
                           >
                              {/* COLUMN 1 */}
                              <Stack
                                 spacing={4}
                                 alignItems='center'
                                 justifyContent='center'
                                 sx={{
                                    width: "100%",
                                    height: "100%",
                                 }}
                              >
                                 {/* DECKLISTS */}
                                 <PlayerDecklists data={data.Player1Decklist} />
                              </Stack>
                           </Box>

                           <Box
                              sx={{
                                 width: "100%",
                                 height: "100%",
                              }}
                           >
                              {/* COLUMN 2 */}
                              <Stack
                                 spacing={4}
                                 alignItems='center'
                                 justifyContent='center'
                                 sx={{
                                    width: "100%",
                                    height: "100%",
                                 }}
                              >
                                 {/* DECKLISTS */}
                                 <PlayerDecklists data={data.Player2Decklist} />
                              </Stack>
                           </Box>
                        </Stack>
                     </Stack>
                  </Stack>
               </React.Fragment>
            )}
         </AnimatePresence>
      </WrapperViewer>
   );
}
