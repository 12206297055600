import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ImagePreloader from "../../components/layout/ImagePreloader";
import CardImage from "../../components/decklists/CardImage";
import ManaIcons from "../../components/decklists/ManaIcons";
import WrapperViewer from "../../components/layout/WrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

import RotateRightIcon from "@mui/icons-material/RotateRight";
import CloseIcon from "@mui/icons-material/Close";

import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

//HOOKS
import useDecklistHandler from "../../hooks/useDecklistHandler";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

function preloadImage(src) {
   return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
         resolve(img);
      };
      img.onerror = img.onabort = function () {
         reject(src);
      };
      img.src = src;
   });
}

export default function DecklistPreview({
   iframeScale,
   preview,
   controls = false,
   decklistId,
   sideboardVisible,
   setZoomCard,
   setZoomSideboardCard,
   zoomCard,
   zoomSideboardCard,
   zoomCardPos,
   setZoomCardPos,
   cardRotation,
   setCardRotation,
}) {
   const theme = useTheme();
   const cardImageContainer = useRef();
   const sideboardContainer = useRef();

   const [visible, setVisible] = useState(false);
   const [card, setCard] = useState();

   const [cardContainerPos, setCardContainerPos] = useState({
      x: 0,
      y: 0,
   });

   const [sideboardCardVisible, setSideboardCardVisible] = useState(false);
   const [sideboardCard, setSideboardCard] = useState();

   const [loaded, setLoaded] = useState();

   const [preloadSuccess, setPreloadSuccess] = useState(false);
   const [preloadImageObj, setPreloadImageObj] = useState();

   // USE HOOK

   const [
      liveData,
      decklist,
      decklistCards,
      sideboardCards,
      allDecklists,
      { getDecklist, getAllDecklists },
   ] = useDecklistHandler();

   useEffect(() => {
      if (decklistId) {
         getDecklist(decklistId);
         hide();
         sideboardHide();
      }
   }, [decklistId]);

   useEffect(() => {
      if (decklist) {
         let preloadObject = {};
         decklist.MainDeck.map((card) => {
            if (card.FileName) {
               preloadObject[card.CardSearchString] = {
                  loaded: false,
                  fileName: card.FileName,
               };
            }
         });
         setPreloadImageObj(preloadObject);
      }
   }, [decklist]);

   useEffect(() => {
      if (!zoomCard) {
         setVisible(false);
      }
   }, [zoomCard]);

   useEffect(() => {
      if (!zoomSideboardCard) {
         setSideboardCardVisible(false);
      }
   }, [zoomSideboardCard]);

   useEffect(() => {
      {
         cardImageContainer.current &&
            setCardContainerPos({
               x: cardImageContainer.current.offsetLeft,
               y: cardImageContainer.current.offsetTop,
            });
      }
   }, [cardImageContainer.current]);

   // PRELOADER
   useEffect(() => {
      if (zoomCard) {
         preloadImage(
            SERVER_URL +
               "/cardimages/png/" +
               encodeURIComponent(zoomCard) +
               ".png"
         )
            .then((response) => {
               // console.log("preloaded!");

               setLoaded(true);
               setVisible(true);
            })
            .catch((error) => {
               // console.log(error);
               setLoaded(false);
               // console.log("image unloaded!");
            });
      } else {
         setLoaded(false);
      }
   }, [zoomCard]);

   useEffect(() => {
      if (zoomSideboardCard) {
         preloadImage(
            SERVER_URL +
               "/cardimages/png/" +
               encodeURIComponent(zoomSideboardCard) +
               ".png"
         )
            .then((response) => {
               // console.log("preloaded!");

               setLoaded(true);
               setSideboardCardVisible(true);
            })
            .catch((error) => {
               // console.log(error);
               setLoaded(false);
               // console.log("image unloaded!");
            });
      } else {
         setLoaded(false);
      }
   }, [zoomSideboardCard]);

   const calcCardSize = (cardsUnique) => {
      let width = "8.8%";

      if (cardsUnique > 44) {
         width = "7%";
      }

      if (cardsUnique <= 44 && cardsUnique > 30) {
         width = "9%";
      }

      if (cardsUnique <= 30 && cardsUnique > 24) {
         width = "10%";
      }

      if (cardsUnique <= 24 && cardsUnique > 16) {
         width = "11.8%";
      }

      if (cardsUnique <= 16) {
         width = "12.5%";
      }

      return width;
   };

   const calcSideboardCardSize = (cardsUnique) => {
      let width = "12.5%";

      if (cardsUnique <= 8) {
         width = "12.5%";
      }

      if (cardsUnique == 9) {
         width = "11%";
      }

      if (cardsUnique >= 10) {
         width = "12.5%";
      }

      return width;
   };

   const calcSideboardHeight = (cardsUnique) => {
      let height = 350;

      if (cardsUnique <= 8) {
         height = 350;
      }

      if (cardsUnique == 9) {
         height = 320;
      }

      if (cardsUnique >= 10) {
         height = 640;
      }

      return height;
   };

   const toggleCardRotation = () => {
      setCardRotation((prevState) => {
         return !prevState;
      });
   };

   const show = (featuredCard) => {
      setZoomCard(featuredCard);
   };

   const hide = () => {
      setVisible(false);
      if (setCardRotation) {
         setCardRotation(false);
      }
   };

   const sideboardShow = (featuredCard) => {
      setZoomSideboardCard(featuredCard);
   };

   const sideboardHide = () => {
      setSideboardCardVisible(false);
      if (setCardRotation) {
         setCardRotation(false);
      }
   };

   const handlePreload = (val) => {
      setPreloadSuccess(val);
   };

   return (
      <>
         <Box
            id='previewWindow'
            sx={{
               position: "absolute",
               top: 0,
               left: 0,
               width: 1920,
               height: 1080,
               zIndex: 10,
            }}
         >
            <Box
               sx={{
                  position: "absolute",
                  top: 40,
                  right: 100,
                  height: 200,
               }}
            >
               <img src={SERVER_URL + "/images/ui/logo-800.png"} width='125' />
            </Box>
            {decklist && (
               <WrapperViewer>
                  <AnimatePresence
                     onExitComplete={() => {
                        preview && setZoomCard();
                     }}
                  >
                     {zoomCard && zoomCardPos && visible && (
                        <>
                           <Box
                              component={motion.div}
                              initial={{ opacity: 0 }}
                              animate={{
                                 opacity: 1,
                              }}
                              exit={{
                                 opacity: 0,
                                 transition: {
                                    duration:
                                       JSON.parse(preview) === true ? 0 : 0.3,
                                    delay:
                                       JSON.parse(preview) === true ? 0 : 0.3,
                                 },
                              }}
                              transition={{
                                 duration:
                                    JSON.parse(preview) === true ? 0 : 0.3,
                              }}
                              alignItems='center'
                              justifyContent='center'
                              sx={{
                                 position: "absolute",
                                 top: 0,
                                 left: 0,
                                 backgroundColor: "rgba(0,0,0,.5)",
                                 width: 1920,
                                 height: 1080,
                                 zIndex: 1000,
                                 pointerEvents: zoomCard ? "auto" : "none",
                              }}
                           />
                           <Box
                              component={motion.div}
                              initial={{
                                 opacity: 0,
                                 x:
                                    zoomCardPos.x /
                                    (iframeScale ? iframeScale : 1),
                                 y:
                                    zoomCardPos.y /
                                       (iframeScale ? iframeScale : 1) +
                                    175,
                                 width:
                                    zoomCardPos.width /
                                    (iframeScale ? iframeScale : 1),
                                 height:
                                    zoomCardPos.height /
                                    (iframeScale ? iframeScale : 1),
                              }}
                              animate={{
                                 opacity: 1,
                                 x: 638,
                                 y: 90,
                                 width: 644,
                                 height: 900,
                              }}
                              exit={{
                                 opacity: 0,
                                 x:
                                    zoomCardPos.x /
                                    (iframeScale ? iframeScale : 1),
                                 y:
                                    zoomCardPos.y /
                                       (iframeScale ? iframeScale : 1) +
                                    175,
                                 width:
                                    zoomCardPos.width /
                                    (iframeScale ? iframeScale : 1),
                                 height:
                                    zoomCardPos.height /
                                    (iframeScale ? iframeScale : 1),
                              }}
                              transition={{
                                 duration:
                                    JSON.parse(preview) === true ? 0 : 0.3,
                              }}
                              sx={{
                                 zIndex: 1500,
                                 position: "absolute",
                              }}
                           >
                              {preview && controls && (
                                 <>
                                    <Button
                                       sx={{
                                          position: "absolute",
                                          px: 4,
                                          py: 2,
                                          top: -70,
                                          right: 20,
                                          border: "1px solid #fff",
                                          zIndex: 100,
                                          pointerEvents: "auto",
                                       }}
                                       variant='contained'
                                       color='error'
                                       onClick={toggleCardRotation}
                                    >
                                       <RotateRightIcon />
                                    </Button>

                                    <Button
                                       sx={{
                                          position: "absolute",
                                          px: 4,
                                          py: 2,
                                          top: -70,
                                          left: 20,
                                          border: "1px solid #fff",
                                          zIndex: 100,
                                          pointerEvents: "auto",
                                       }}
                                       variant='contained'
                                       color='error'
                                       onClick={hide}
                                    >
                                       <CloseIcon />
                                    </Button>
                                 </>
                              )}
                              <Box
                                 component={motion.div}
                                 initial={{
                                    rotate: 0,
                                 }}
                                 animate={{
                                    rotate: cardRotation ? 90 : 0,
                                    transition: {
                                       delay: 0,
                                       duration: 0.5,
                                       ease: "easeInOut",
                                    },
                                 }}
                              >
                                 <img
                                    src={
                                       SERVER_URL +
                                       "/cardimages/png/" +
                                       zoomCard +
                                       ".png"
                                    }
                                    style={{
                                       width: "100%",
                                       height: "100%",
                                       zIndex: 1,
                                    }}
                                 />
                              </Box>
                           </Box>
                        </>
                     )}
                  </AnimatePresence>

                  <AnimatePresence
                     onExitComplete={() => {
                        preview && setZoomSideboardCard();
                     }}
                  >
                     {zoomSideboardCard &&
                        zoomCardPos &&
                        sideboardCardVisible && (
                           <>
                              <Box
                                 component={motion.div}
                                 initial={{ opacity: 0 }}
                                 animate={{ opacity: 1 }}
                                 exit={{
                                    opacity: 0,
                                    transition: {
                                       duration:
                                          JSON.parse(preview) === true
                                             ? 0
                                             : 0.5,
                                       delay:
                                          JSON.parse(preview) === true
                                             ? 0
                                             : 0.3,
                                    },
                                 }}
                                 transition={{
                                    duration:
                                       JSON.parse(preview) === true ? 0 : 0.5,
                                 }}
                                 alignItems='center'
                                 justifyContent='center'
                                 sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    backgroundColor: "rgba(0,0,0,.5)",
                                    width: 1920,
                                    height: 1080,
                                    zIndex: 1000,
                                    pointerEvents: zoomSideboardCard
                                       ? "auto"
                                       : "none",
                                 }}
                              />
                              <Box
                                 component={motion.div}
                                 initial={{
                                    opacity: 0,
                                    x: 638,
                                    y: 150,
                                    width: 644,
                                    height: 900,
                                 }}
                                 animate={{
                                    opacity: 1,
                                    x: 638,
                                    y: 90,
                                 }}
                                 exit={{
                                    opacity: 0,
                                    x: 638,
                                    y: 150,
                                 }}
                                 transition={{
                                    duration:
                                       JSON.parse(preview) === true ? 0 : 0.3,
                                 }}
                                 sx={{ zIndex: 1500, position: "absolute" }}
                              >
                                 {preview && controls && (
                                    <>
                                       <Button
                                          sx={{
                                             position: "absolute",
                                             px: 4,
                                             py: 2,
                                             top: -70,
                                             right: 20,
                                             border: "1px solid #fff",
                                             zIndex: 100,
                                             pointerEvents: "auto",
                                          }}
                                          variant='contained'
                                          color='error'
                                          onClick={toggleCardRotation}
                                       >
                                          <RotateRightIcon />
                                       </Button>

                                       <Button
                                          sx={{
                                             position: "absolute",
                                             px: 4,
                                             py: 2,
                                             top: -70,
                                             left: 20,
                                             border: "1px solid #fff",
                                             zIndex: 100,
                                             pointerEvents: "auto",
                                          }}
                                          variant='contained'
                                          color='error'
                                          onClick={sideboardHide}
                                       >
                                          <CloseIcon />
                                       </Button>
                                    </>
                                 )}
                                 <Box
                                    component={motion.div}
                                    initial={{
                                       rotate: 0,
                                    }}
                                    animate={{
                                       rotate: cardRotation ? 90 : 0,
                                       transition: {
                                          delay: 0,
                                          duration: 0.5,
                                          ease: "easeInOut",
                                       },
                                    }}
                                 >
                                    <img
                                       src={
                                          SERVER_URL +
                                          "/cardimages/png/" +
                                          zoomSideboardCard +
                                          ".png"
                                       }
                                       style={{
                                          width: "100%",
                                          height: "100%",
                                          zIndex: 1,
                                       }}
                                    />
                                 </Box>
                              </Box>
                           </>
                        )}
                  </AnimatePresence>

                  <AnimatePresence exitBeforeEnter>
                     {decklist && preloadSuccess && (
                        <Box key={decklistId}>
                           <Stack
                              sx={{
                                 position: "absolute",
                                 top: 40,
                                 left: 100,
                                 height: 200,
                              }}
                              justifyContent='center'
                           >
                              <Box
                                 component={motion.div}
                                 initial={{
                                    opacity: 0,
                                 }}
                                 animate={{
                                    opacity: 1,
                                    transition: {
                                       easing: "easeOut",
                                       delay: 0.1,
                                       duration:
                                          JSON.parse(preview) === true
                                             ? 0
                                             : 0.5,
                                    },
                                 }}
                                 exit={{
                                    opacity: 0,
                                    transition: {
                                       easing: "easeOut",
                                       delay: 0.5,
                                       duration:
                                          JSON.parse(preview) === true
                                             ? 0
                                             : 0.5,
                                    },
                                 }}
                              >
                                 <Typography
                                    variant='h1Viewer'
                                    component='div'
                                    sx={{ textTransform: "uppercase" }}
                                 >
                                    {decklist.PlayerName}
                                 </Typography>

                                 <Stack direction='row' spacing={2}>
                                    <ManaIcons
                                       width={45}
                                       mana={{
                                          IsWhite: decklist.IsWhite,
                                          IsBlue: decklist.IsBlue,
                                          IsBlack: decklist.IsBlack,
                                          IsRed: decklist.IsRed,
                                          IsGreen: decklist.IsGreen,
                                          IsColorless: decklist.IsColorless,
                                       }}
                                    />

                                    <Typography
                                       variant='h2Viewer'
                                       component='div'
                                       sx={{ textTransform: "uppercase" }}
                                    >
                                       {decklist.Archetype &&
                                          decklist.Archetype}
                                    </Typography>
                                 </Stack>
                              </Box>
                           </Stack>

                           {/* CARDS */}
                           <Stack
                              direction='row'
                              alignItems='center'
                              sx={{
                                 position: "absolute",
                                 top: 220,
                                 left: 0,
                                 px: 14,
                                 fontSize: 24,
                                 height: 850,
                                 width: "100%",
                              }}
                              component={motion.div}
                              initial={{
                                 opacity: 0,
                              }}
                              animate={{
                                 opacity: 1,
                                 transition: {
                                    easing: "easeOut",
                                    delay: 0.2,
                                    duration:
                                       JSON.parse(preview) === true ? 0 : 0.5,
                                 },
                              }}
                              exit={{
                                 opacity: 0,
                                 transition: {
                                    easing: "easeIn",
                                    duration:
                                       JSON.parse(preview) === true ? 0 : 0.5,
                                    delay: 0.5,
                                 },
                              }}
                           >
                              <Grid
                                 key='cardImageContainer'
                                 ref={cardImageContainer}
                                 container
                                 spacing={0}
                                 justifyContent='center'
                                 sx={{
                                    position: "relative",
                                 }}
                              >
                                 {decklist &&
                                    decklistCards &&
                                    decklistCards.map((card, i) => (
                                       <CardImage
                                          key={"card" + i + "-" + card.id}
                                          id={card.CardSearchString}
                                          width={calcCardSize(
                                             decklistCards.length
                                          )}
                                          name={card.CardName}
                                          qty={card.Quantity}
                                          fileName={card.FileName}
                                          show={show}
                                          iframeScale={iframeScale}
                                          parentContainer={cardContainerPos}
                                          setZoomCardPos={setZoomCardPos}
                                          toggleCardRotation={
                                             toggleCardRotation
                                          }
                                          preview={preview}
                                          controls={controls}
                                       />
                                    ))}
                              </Grid>
                           </Stack>
                        </Box>
                     )}
                  </AnimatePresence>

                  {/* SIDEBOARD */}
                  <AnimatePresence exitBeforeEnter>
                     {sideboardVisible && (
                        <Box key={decklistId + "-sideboard"}>
                           <Box
                              as={motion.div}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{
                                 delay: JSON.parse(preview) === true ? 0 : 0.3,
                                 duration:
                                    JSON.parse(preview) === true ? 0 : 0.3,
                                 easing: "easeOut",
                              }}
                              sx={{
                                 position: "fixed",
                                 top: 0,
                                 left: 0,
                                 width: 1920,
                                 height: 1080,
                                 zIndex: 150,
                                 backgroundColor: "rgba(0,0,0,.3)",
                              }}
                           />
                           <Box
                              as={motion.div}
                              initial={{ y: 1080 }}
                              animate={{ y: 0 }}
                              exit={{ y: 1080 }}
                              transition={{
                                 duration:
                                    JSON.parse(preview) === true ? 0 : 0.5,
                                 easing: "easeOut",
                              }}
                              sx={{
                                 position: "absolute",
                                 width: 1920,
                                 height: 1080,
                                 bottom: 0,
                                 zIndex: 200,
                              }}
                           >
                              <Grid
                                 key='sideboardContainer'
                                 ref={sideboardContainer}
                                 container
                                 spacing={0}
                                 justifyContent='center'
                                 sx={{
                                    position: "absolute",
                                    zIndex: 200,
                                    bottom: 0,
                                 }}
                              >
                                 <Grid
                                    item
                                    xs={12}
                                    sx={{
                                       height: 100,
                                       background:
                                          theme.palette.background
                                             .gradientshadow,
                                       position: "relative",
                                       zIndex: 50,
                                       top: "2px",
                                    }}
                                 />
                                 <Grid
                                    item
                                    xs={12}
                                    sx={{
                                       height: calcSideboardHeight(
                                          sideboardCards
                                             ? sideboardCards.length
                                             : decklist.SideboardUnique
                                       ),
                                       textAlign: "center",
                                       position: "relative",
                                       backgroundColor: "#151515",
                                       zIndex: 100,
                                    }}
                                 >
                                    <Box
                                       sx={{
                                          height: 10,
                                          background:
                                             theme.palette.background.gradient,
                                       }}
                                    />
                                    <Typography
                                       variant='h4'
                                       component='div'
                                       sx={{
                                          zIndex: 100,
                                          width: "200px",
                                          pt: 1,
                                          pb: 0.4,
                                          left: "50%",
                                          transform:
                                             "translateX(-50%) translateY(-60%)",
                                          position: "absolute",
                                          border: "10px solid #423f41",
                                          backgroundColor: "#000",
                                       }}
                                    >
                                       SIDEBOARD
                                    </Typography>
                                    <Grid
                                       container
                                       spacing={0}
                                       justifyContent='center'
                                       sx={{
                                          px: 15,
                                          pt: 4,
                                          pb: 2,
                                          position: "relative",
                                       }}
                                    >
                                       {decklist &&
                                          sideboardCards &&
                                          sideboardCards.map((card, i) => (
                                             <CardImage
                                                key={
                                                   "sideboardcard" +
                                                   i +
                                                   "-" +
                                                   card.id
                                                }
                                                id={card.CardSearchString}
                                                width={calcSideboardCardSize(
                                                   sideboardCards.length
                                                )}
                                                name={card.CardName}
                                                qty={card.Quantity}
                                                fileName={card.FileName}
                                                show={sideboardShow}
                                                parentContainer={
                                                   sideboardContainer
                                                }
                                                iframeScale={iframeScale}
                                                setZoomCardPos={setZoomCardPos}
                                                toggleCardRotation={
                                                   toggleCardRotation
                                                }
                                                preview={preview}
                                                controls={controls}
                                             />
                                          ))}
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Box>
                     )}
                  </AnimatePresence>
               </WrapperViewer>
            )}
         </Box>
         <Box
            id='background'
            sx={{
               position: "absolute",
               top: 0,
               left: 0,
               width: 1920,
               height: 1080,
               zIndex: 1,
            }}
         />

         <ImagePreloader
            key={preloadImageObj}
            images={preloadImageObj}
            callback={handlePreload}
         />
      </>
   );
}
