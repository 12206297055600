import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useTheme } from "@mui/material/styles";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function PlayerPhoto({ player, gameState, lastUpdated }) {
   return (
      <>
         {player && (
            <>
               {player.PlayerPhoto && (
                  <>
                     <Box
                        component={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ easing: "easeInOut" }}
                        sx={{
                           position: "relative",
                           width: "100%",
                           height: "100%",
                           overflow: "hidden",
                           backgroundSize: "90%",
                           backgroundRepeat: "no-repeat",
                           backgroundPosition: "center -20px",
                           backgroundImage:
                              "url(" +
                              SERVER_URL +
                              "/uploads/playerphotos/" +
                              player.PlayerPhoto +
                              "?" +
                              lastUpdated +
                              ")",
                        }}
                     />
                     <Box
                        component={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ easing: "easeInOut" }}
                        sx={{
                           position: "absolute",
                           top: 0,
                           left: 0,
                           zIndex: -1,
                           width: "100%",
                           height: "100%",
                           background:
                              "linear-gradient(163deg, rgba(23,23,23,1) 0%, rgba(48,47,48,1) 100%)",
                        }}
                     />
                  </>
               )}
               {!player.PlayerPhoto && (
                  <>
                     <Box
                        component={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ easing: "easeInOut" }}
                        sx={{
                           position: "relative",
                           width: "100%",
                           height: "100%",
                           overflow: "hidden",
                           backgroundSize: "cover",
                           backgroundRepeat: "no-repeat",
                           backgroundPosition: "center",
                           backgroundImage:
                              "url(" + SERVER_URL + "/images/nophoto.png)",
                        }}
                     />
                     <Box
                        component={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ easing: "easeInOut" }}
                        sx={{
                           position: "absolute",
                           top: 0,
                           left: 0,
                           zIndex: -1,
                           width: "100%",
                           height: "100%",
                           background:
                              "linear-gradient(163deg, rgba(23,23,23,1) 0%, rgba(48,47,48,1) 100%)",
                        }}
                     />
                  </>
               )}
            </>
         )}
      </>
   );
}
