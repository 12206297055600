import { useState } from "react";

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

import axios from "axios";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   height: "90%",
   width: 500,
   transform: "translate(-50%, -50%)",
   width: "90%",
   bgcolor: "#333",
   border: "2px solid #000",
   boxShadow: 24,
   borderRadius: 5,
   p: 4,
   zIndex: 1000,
};

export default function Uploader({ callback }) {
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const handleSubmit = () => {
      handleClose();
      callback();
   };

   // DROPZONE
   const getUploadParams = ({ file, meta }) => {
      const body = new FormData();
      body.append("EntryFile", file);
      return { url: SERVER_URL + "/api/upload/playerphotos", body };
   };

   const handleChangeStatus = ({ meta, file }, status) => {
      // console.log(status, meta, file);
   };

   const handleUploaded = (files, allFiles) => {
      // console.log(files.map((f) => f.meta));
      allFiles.forEach((f) => f.remove());
   };

   const SubmitButton = ({ files }) => {
      const _disabled =
         files.some((f) =>
            ["preparing", "getting_upload_params", "uploading"].includes(
               f.meta.status
            )
         ) ||
         !files.some((f) =>
            ["headers_received", "done"].includes(f.meta.status)
         );

      return (
         <Stack direction='row' alignItems='center' sx={{ mt: 4, mb: 1 }}>
            <Typography variant='h4' component='div' sx={{ width: "100%" }}>
               UPLOAD RESULTS
            </Typography>
            <Button
               color='error'
               variant='contained'
               disabled={_disabled}
               onClick={handleSubmit}
            >
               SAVE
            </Button>
         </Stack>
      );
   };

   const Layout = ({
      input,
      previews,
      submitButton,
      dropzoneProps,
      files,
      extra: { maxFiles },
   }) => {
      return (
         <Stack sx={{ height: "95%" }}>
            <Stack
               alignItems='center'
               justifyContent='center'
               sx={{
                  border: 0,
                  backgroundColor: "#444",
                  position: "relative",
                  p: 4,
               }}
            >
               <Box sx={{ width: 300, pb: 3 }}>
                  {files.length < maxFiles && input}
               </Box>
            </Stack>

            <div>{files.length > 0 && submitButton}</div>
            {files.length > 0 && (
               <>
                  <Box
                     sx={{
                        overflow: "scroll",
                        height: "100%",
                        border: "1px solid #666",
                     }}
                  >
                     {previews}
                  </Box>
               </>
            )}
         </Stack>
      );
   };

   const Preview = ({ meta }) => {
      const { name, percent, status } = meta;
      return (
         <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{
               width: "100%",
               p: 1,
               borderBottom: "1px solid #666",
               color: "#ccc",
            }}
            spacing={4}
         >
            <Box sx={{ overflow: "scroll", width: "60%" }}>{name}</Box>
            <Box sx={{ width: "20%" }}>{Math.round(percent)}%</Box>
            <Box sx={{ width: "20%" }}>{status}</Box>
         </Stack>
      );
   };

   return (
      <>
         <Button variant='outlined' onClick={handleOpen}>
            + UPLOAD PHOTOS
         </Button>

         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
         >
            <Box sx={modalStyle}>
               <IconButton
                  aria-label='close'
                  color='error'
                  sx={{ position: "absolute", top: 10, right: 10, zIndex: 100 }}
                  onClick={handleSubmit}
               >
                  <CloseIcon />
               </IconButton>

               <Typography
                  id='modal-title'
                  variant='h6'
                  component='h2'
                  sx={{ pb: 2 }}
               >
                  UPLOAD FILE
               </Typography>

               <Dropzone
                  LayoutComponent={Layout}
                  PreviewComponent={Preview}
                  inputContent='Click to Upload PNG Images'
                  SubmitButtonComponent={SubmitButton}
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  onSubmit={handleSubmit}
                  maxSizeBytes={1500000}
                  accept='image/png'
               />
            </Box>
         </Modal>
      </>
   );
}
