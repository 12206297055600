import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// IMAGES
import h2hShield from "../../images/frame-h2hshield.png";
import playerShieldFlagBkgnd from "../../images/frame-playershield-flag-bkgnd.jpg";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function PlayerShield({ data }) {
   const forceLineBreak = (text) => {
      if (text) {
         let textArray = text.split(" ");
         let divider = textArray.length === 2 ? "<br />" : " ";
         let response = textArray.join(divider);
         return <div dangerouslySetInnerHTML={{ __html: response }} />;
      }
   };

   return (
      <>
         {data && (
            <Box
               sx={{
                  position: "absolute",
                  top: 505,
                  left: 114,
                  width: 471,
                  zIndex: 100,
                  textAlign: "center",
               }}
            >
               <Typography
                  variant='h1headtoheadplayer'
                  component='div'
                  sx={{ textTransform: "uppercase" }}
               >
                  {forceLineBreak(data.PlayerName)}
               </Typography>

               <Typography
                  variant='h2headtoheadplayer'
                  component='div'
                  sx={{ mt: 1 }}
               >
                  {data.TwitterHandle}
               </Typography>
            </Box>
         )}
         <Box
            sx={{
               position: "absolute",
               top: 0,
               left: 0,
               zIndex: 50,
            }}
         >
            <img src={h2hShield} />
         </Box>

         {data && data.CountryFlag && (
            <Box
               sx={{
                  position: "absolute",
                  top: 425,
                  left: 349,
                  width: 97,
                  height: 60,
                  zIndex: 50,
                  transform: "translateX(-50px)",
                  backgroundSize: "100% 100%",
                  backgroundPosition: "center",
                  backgroundImage: "url(" + playerShieldFlagBkgnd + ")",
                  p: "4px",
               }}
            >
               <img src={data.CountryFlag} width='100%' height='100%' />
            </Box>
         )}

         {data && data.CountryFlag && (
            <Box
               component={motion.div}
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               exit={{ opacity: 0 }}
               transition={{ easing: "easeInOut" }}
               sx={{
                  position: "absolute",
                  zIndex: 200,
                  top: 466,
                  left: 140,
                  width: 170,
               }}
            >
               <Typography
                  variant='fmPlayerPronoun'
                  component='div'
                  sx={{ textTransform: "uppercase" }}
               >
                  {data.PREFERRED_PRONOUNS}
               </Typography>
            </Box>
         )}
      </>
   );
}
