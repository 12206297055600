import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function PlayerBulletPoints({ data }) {
   const theme = useTheme();

   return (
      <>
         {data && (
            <Stack
               sx={{
                  listStyleType: "none",
                  fontFamily: "GothamNarrow-Book",
                  fontSize: 32,
                  lineHeight: 1.1,
                  color: "#fff",
                  width: "100%",
                  textAlign: "left",
               }}
               spacing={4}
            >
               {data.DataFields &&
                  data.DataFields.bulletpoints.map((row, i) => (
                     <>
                        {row.title && data.PlayerData && (
                           <Stack
                              direction='row'
                              alignItems='flex-start'
                              justifyContent='flex-start'
                           >
                              <Box
                                 width={20}
                                 sx={{
                                    fontSize: 50,
                                    lineHeight: "36px",
                                    position: "relative",
                                 }}
                              >
                                 {row.checkmark ? (
                                    <img
                                       src={
                                          SERVER_URL +
                                          "/images/ui/checkmark.png"
                                       }
                                       width='70'
                                       style={{
                                          display: "block",
                                          position: "absolute",
                                          transform:
                                             "translateX(-80%) translateY(-30%)",
                                       }}
                                    />
                                 ) : (
                                    ""
                                 )}
                              </Box>
                              <Box width='100%'>
                                    {row.title == "BULLET_1" && <b>How Qualified:&nbsp;</b>}
                                    {row.title == "BULLET_2" && <b>Season Goal:&nbsp;</b>} 
                                    {data.PlayerData[row.title]}
                              </Box>
                           </Stack>
                        )}
                     </>
                  ))}
            </Stack>
         )}
      </>
   );
}
