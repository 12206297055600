import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import WrapperViewer from "../../components/layout/WrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

import StandingsPreview from "./StandingsPreview";

//HOOKS
import useStandingsHandler from "../../hooks/useStandingsHandler";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function StandingsViewer() {
   // USE HOOK
   const [
      standings,
      setStandings,
      cachedStandings,
      tournamentRounds,
      notification,
      {
         subscribeStandingsViewer,
         getCachedStandings,
         getStandings,
         getTournamentRounds,
      },
   ] = useStandingsHandler();

   useEffect(() => {
      subscribeStandingsViewer(1);
   }, []);

   return <StandingsPreview data={standings} />;
}
