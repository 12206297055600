import React from "react";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import white from "../../images/mana/white.png";
import blue from "../../images/mana/blue.png";
import red from "../../images/mana/red.png";
import green from "../../images/mana/green.png";
import black from "../../images/mana/black.png";
import colorless from "../../images/mana/colorless.png";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function ManaIcons({ center = false, width, mana }) {
   return (
      <Stack
         direction='row'
         spacing={0.5}
         justifyContent={center ? "center" : "flex-start"}
         alignItems={center ? "center" : "flex-start"}
         sx={{ width: center ? "100%" : "auto" }}
      >
         <Box
            sx={{
               width: width,
               height: width,
               opacity: parseInt(mana.IsWhite) ? 1 : 0.15,
            }}
         >
            <img src={white} width='100%' />
         </Box>

         <Box
            sx={{
               width: width,
               height: width,
               opacity: parseInt(mana.IsBlue) ? 1 : 0.15,
            }}
         >
            <img src={blue} width='100%' />
         </Box>

         <Box
            sx={{
               width: width,
               height: width,
               opacity: parseInt(mana.IsBlack) ? 1 : 0.15,
            }}
         >
            <img src={black} width='100%' />
         </Box>

         <Box
            sx={{
               width: width,
               height: width,
               opacity: parseInt(mana.IsRed) ? 1 : 0.15,
            }}
         >
            <img src={red} width='100%' />
         </Box>
         <Box
            sx={{
               width: width,
               height: width,
               opacity: parseInt(mana.IsGreen) ? 1 : 0.15,
            }}
         >
            <img src={green} width='100%' />
         </Box>

         <Box
            sx={{
               width: width,
               height: width,
               opacity: parseInt(mana.IsColorless) ? 1 : 0.15,
            }}
         >
            <img src={colorless} width='100%' />
         </Box>
      </Stack>
   );
}
