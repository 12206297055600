import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ImagePreloader from "../../components/layout/ImagePreloader";
import CardImage from "../../components/decklists/CardImage";
import ManaIcons from "../../components/decklists/ManaIcons";
import WrapperViewer from "../../components/layout/WrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

//HOOKS
import useDecklistHandler from "../../hooks/useDecklistHandler";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

function preloadImage(src) {
   return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
         resolve(img);
      };
      img.onerror = img.onabort = function () {
         reject(src);
      };
      img.src = src;
   });
}

export default function DecklistPreviewDraft({
   iframeScale,
   preview,
   controls = false,
   decklistId,
   sideboardVisible,
   setZoomCard,
   setZoomSideboardCard,
   zoomCard,
   zoomSideboardCard,
   zoomCardPos,
   setZoomCardPos,
}) {
   const theme = useTheme();

   const [preloadSuccess, setPreloadSuccess] = useState(false);
   const [preloadImageObj, setPreloadImageObj] = useState();

   const [creatureGroups, setCreatureGroups] = useState();
   const [spellGroups, setSpellGroups] = useState();

   // USE HOOK

   const [
      liveData,
      decklist,
      decklistCards,
      sideboardCards,
      allDecklists,
      { getDecklist, getAllDecklists },
   ] = useDecklistHandler();

   useEffect(() => {
      if (decklistId) {
         getDecklist(decklistId);
      }
   }, [decklistId]);

   useEffect(() => {
      if (decklist) {
         const creatureGroupObj = _.groupBy(
            decklist.Creatures,
            (card) => card.CMC
         );
         const spellGroupObj = _.groupBy(decklist.Spells, (card) => card.CMC);

         if (creatureGroupObj) {
            Object.keys(creatureGroupObj).map((groupKey) => {
               if (groupKey > 6) {
                  if (creatureGroupObj["6"]) {
                     creatureGroupObj["6"] = [
                        ...creatureGroupObj["6"],
                        ...creatureGroupObj[groupKey],
                     ];
                  } else {
                     creatureGroupObj["6"] = [...creatureGroupObj[groupKey]];
                  }
                  delete creatureGroupObj[groupKey];
               }
            });

            setCreatureGroups(creatureGroupObj);
         }

         if (spellGroupObj) {
            Object.keys(spellGroupObj).map((groupKey) => {
               if (groupKey > 6) {
                  if (spellGroupObj["6"]) {
                     spellGroupObj["6"] = [
                        ...spellGroupObj["6"],
                        ...spellGroupObj[groupKey],
                     ];
                  } else {
                     spellGroupObj["6"] = [...spellGroupObj[groupKey]];
                  }
                  delete spellGroupObj[groupKey];
               }
            });

            setSpellGroups(spellGroupObj);
         }

         let preloadObject = {};
         decklist.MainDeck.map((card) => {
            if (card.FileName) {
               preloadObject[card.CardSearchString] = {
                  loaded: false,
                  fileName: card.FileName,
               };
            }
         });
         setPreloadImageObj(preloadObject);
      }
   }, [decklist]);

   useEffect(() => {
      // console.log("CREATURE:", creatureGroups);
   }, [creatureGroups]);

   useEffect(() => {
      // console.log("SPELL:", spellGroups);
   }, [spellGroups]);

   useEffect(() => {
      // console.log("PRELOAD:", preloadImageObj);
   }, [preloadImageObj]);

   useEffect(() => {
      // console.log("PRELOADED:", preloadSuccess);
   }, [preloadSuccess]);

   const handlePreload = (val) => {
      setPreloadSuccess(val);
   };

   return (
      <>
         <Box
            id='previewWindow'
            sx={{
               position: "absolute",
               top: 0,
               left: 0,
               width: 1920,
               height: 1080,
               zIndex: 10,
            }}
         >
            <Box
               sx={{
                  position: "absolute",
                  top: 40,
                  right: 100,
                  height: 200,
               }}
            >
               <img src={SERVER_URL + "/images/ui/logo-800.png"} width='125' />
            </Box>

            {decklist && (
               <WrapperViewer>
                  <AnimatePresence exitBeforeEnter>
                     {decklist && preloadSuccess && (
                        <Box
                           sx={{
                              position: "absolute",
                              top: 0,
                              width: 1920,
                              height: 1080,
                           }}
                           key={"draft-" + decklistId}
                           as={motion.div}
                           initial={{ opacity: 0 }}
                           animate={{ opacity: 1 }}
                           exit={{ opacity: 0 }}
                        >
                           <Stack
                              sx={{
                                 position: "absolute",
                                 top: 25,
                                 left: 100,
                                 height: 200,
                              }}
                              justifyContent='center'
                              component={motion.div}
                              initial={{
                                 opacity: 0,
                              }}
                              animate={{
                                 opacity: 1,
                                 transition: {
                                    easing: "easeOut",
                                    delay: 0,
                                    duration:
                                       JSON.parse(preview) === true ? 0 : 0.5,
                                 },
                              }}
                              exit={{
                                 opacity: 0,
                                 transition: {
                                    easing: "easeOut",
                                    delay: 0,
                                    duration:
                                       JSON.parse(preview) === true ? 0 : 0.5,
                                 },
                              }}
                           >
                              <Typography
                                 variant='h1Viewer'
                                 component='div'
                                 sx={{ textTransform: "uppercase" }}
                              >
                                 {decklist.PlayerName}
                              </Typography>
                           </Stack>

                           {/* CARDS */}
                           <Grid
                              key='creatureGroups'
                              container
                              spacing={2}
                              alignItems='flex-start'
                              sx={{
                                 position: "absolute",
                                 top: 220,
                                 left: 100,
                                 fontSize: 24,
                                 height: 550,
                                 width: 1440,
                                 borderRadius: 5,
                                 mask: "linear-gradient(180deg, rgba(0,0,0,1) 85%, rgba(25,25,25,0) 100%) bottom center",
                              }}
                              component={motion.div}
                              initial={{
                                 opacity: 0,
                              }}
                              animate={{
                                 opacity: 1,
                                 transition: {
                                    easing: "easeOut",
                                    delay: 0,
                                    duration:
                                       JSON.parse(preview) === true ? 0 : 0.5,
                                 },
                              }}
                              exit={{
                                 opacity: 0,
                                 transition: {
                                    easing: "easeIn",
                                    duration:
                                       JSON.parse(preview) === true ? 0 : 0.5,
                                    delay: 0,
                                 },
                              }}
                           >
                              {creatureGroups &&
                                 [...Array(6).keys()].map((i) => (
                                    <Grid item xs={2} key={"creatureGroup" + i}>
                                       <Stack>
                                          {creatureGroups &&
                                             creatureGroups[i + 1] &&
                                             _.orderBy(
                                                creatureGroups[i + 1],
                                                ["Color", "CardName"],
                                                ["asc", "asc"]
                                             ).map((card, o) => (
                                                <React.Fragment
                                                   key={"creatureCard" + o}
                                                >
                                                   {[
                                                      ...Array(
                                                         card.Quantity
                                                      ).keys(),
                                                   ].map((q) => (
                                                      <Box
                                                         sx={{
                                                            height: 36,
                                                            position:
                                                               "relative",
                                                         }}
                                                         key={
                                                            "creatureCard" +
                                                            o +
                                                            "-" +
                                                            q
                                                         }
                                                      >
                                                         <Box
                                                            sx={{
                                                               borderRadius: 3,
                                                               overflow:
                                                                  "hidden",
                                                               width: "100%",
                                                            }}
                                                         >
                                                            <img
                                                               src={
                                                                  SERVER_URL +
                                                                  "/cardimages/jpg/" +
                                                                  encodeURIComponent(
                                                                     card.FileName
                                                                  ) +
                                                                  ".jpg"
                                                               }
                                                               width='100%'
                                                               style={{
                                                                  display:
                                                                     "block",
                                                               }}
                                                            />
                                                         </Box>
                                                      </Box>
                                                   ))}
                                                </React.Fragment>
                                             ))}
                                       </Stack>
                                    </Grid>
                                 ))}
                           </Grid>

                           {/* SPELL GROUPS */}
                           <Grid
                              key='spellGroups'
                              container
                              spacing={2}
                              alignItems='flex-start'
                              sx={{
                                 position: "absolute",
                                 top: 670,
                                 left: 100,
                                 fontSize: 24,
                                 height: 400,
                                 width: 1440,
                                 borderRadius: 5,
                                 mask: "linear-gradient(180deg, rgba(0,0,0,1) 85%, rgba(25,25,25,0) 100%) bottom center",
                              }}
                              component={motion.div}
                              initial={{
                                 opacity: 0,
                              }}
                              animate={{
                                 opacity: 1,
                                 transition: {
                                    easing: "easeOut",
                                    delay: 0,
                                    duration:
                                       JSON.parse(preview) === true ? 0 : 0.5,
                                 },
                              }}
                              exit={{
                                 opacity: 0,
                                 transition: {
                                    easing: "easeIn",
                                    duration:
                                       JSON.parse(preview) === true ? 0 : 0.5,
                                    delay: 0,
                                 },
                              }}
                           >
                              {spellGroups &&
                                 [...Array(6).keys()].map((i) => (
                                    <Grid item xs={2} key={"spellGroup" + i}>
                                       <Stack>
                                          {spellGroups &&
                                             spellGroups[i + 1] &&
                                             _.orderBy(
                                                spellGroups[i + 1],
                                                ["Color", "CardName"],
                                                ["asc", "asc"]
                                             ).map((card, o) => (
                                                <React.Fragment
                                                   key={"spellCard" + o}
                                                >
                                                   {[
                                                      ...Array(
                                                         card.Quantity
                                                      ).keys(),
                                                   ].map((q) => (
                                                      <Box
                                                         sx={{
                                                            height: 36,
                                                            position:
                                                               "relative",
                                                         }}
                                                         key={
                                                            "spellCard" +
                                                            o +
                                                            "-" +
                                                            q
                                                         }
                                                      >
                                                         <Box
                                                            sx={{
                                                               borderRadius: 3,
                                                               overflow:
                                                                  "hidden",
                                                               width: "100%",
                                                            }}
                                                         >
                                                            <img
                                                               src={
                                                                  SERVER_URL +
                                                                  "/cardimages/jpg/" +
                                                                  encodeURIComponent(
                                                                     card.FileName
                                                                  ) +
                                                                  ".jpg"
                                                               }
                                                               width='100%'
                                                               style={{
                                                                  display:
                                                                     "block",
                                                               }}
                                                            />
                                                         </Box>
                                                      </Box>
                                                   ))}
                                                </React.Fragment>
                                             ))}
                                       </Stack>
                                    </Grid>
                                 ))}
                           </Grid>

                           {/* LAND GROUP */}
                           <Stack
                              alignItems='flex-start'
                              sx={{
                                 position: "absolute",
                                 top: 220,
                                 right: 100,
                                 fontSize: 22,
                                 height: 925,
                                 width: 200,
                                 position: "absolute",
                                 borderRadius: 5,
                              }}
                              component={motion.div}
                              initial={{
                                 opacity: 0,
                              }}
                              animate={{
                                 opacity: 1,
                                 transition: {
                                    easing: "easeOut",
                                    delay: 0,
                                    duration:
                                       JSON.parse(preview) === true ? 0 : 0.5,
                                 },
                              }}
                              exit={{
                                 opacity: 0,
                                 transition: {
                                    easing: "easeIn",
                                    duration:
                                       JSON.parse(preview) === true ? 0 : 0.5,
                                    delay: 0,
                                 },
                              }}
                           >
                              <Stack>
                                 {decklist.Land &&
                                    _.orderBy(
                                       decklist.Land,
                                       ["Color", "CardName"],
                                       ["asc", "asc"]
                                    ).map((card, i) => (
                                       <Box
                                          sx={{
                                             height: 36,
                                             position: "relative",
                                          }}
                                          key={"land" + i}
                                       >
                                          <Box
                                             sx={{
                                                position: "absolute",
                                                left: 205,
                                                pt: 0.7,
                                                color: "#fff",
                                             }}
                                          >
                                             {card.Quantity}x
                                          </Box>
                                          <Box
                                             sx={{
                                                borderRadius: 3,
                                                overflow: "hidden",
                                                width: "100%",
                                             }}
                                          >
                                             <img
                                                src={
                                                   SERVER_URL +
                                                   "/cardimages/jpg/" +
                                                   encodeURIComponent(
                                                      card.FileName
                                                   ) +
                                                   ".jpg"
                                                }
                                                width='100%'
                                                style={{ display: "block" }}
                                             />
                                          </Box>
                                       </Box>
                                    ))}
                              </Stack>
                           </Stack>
                        </Box>
                     )}
                  </AnimatePresence>
               </WrapperViewer>
            )}
         </Box>
         <Box
            id='background'
            sx={{
               position: "absolute",
               top: 0,
               left: 0,
               width: 1920,
               height: 1080,
               zIndex: 1,
            }}
         />

         <ImagePreloader
            key={preloadImageObj}
            images={preloadImageObj}
            callback={handlePreload}
         />
      </>
   );
}
