import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function ImagePreloader({ images, callback }) {
   const [imagePreload, setImagePreload] = useState();
   const [preloadComplete, setPreloadComplete] = useState(false);
   const [lastLoad, setLastLoad] = useState();

   useEffect(() => {
      setPreloadComplete(false);
      setImagePreload(images);
      setLastLoad(Date.now());
   }, [images]);

   useEffect(() => {
      if (imagePreload) {
         // console.log(imagePreload);
      }
   }, [imagePreload]);

   useEffect(() => {
      if (preloadComplete) {
         // console.log("Preload complete!");
         callback(true);
      } else {
         // console.log("Preload images prepped.");
         callback(false);
      }
   }, [preloadComplete]);

   const setImageLoaded = (card) => {
      setImagePreload((prevState) => {
         let newState = { ...prevState };
         newState[card].loaded = true;
         if (Object.values(newState).every((item) => item.loaded === true)) {
            setPreloadComplete(true);
         }

         return newState;
      });
   };

   return (
      <Box sx={{ display: "none" }}>
         {imagePreload &&
            Object.entries(imagePreload).map((row, i) => (
               <img
                  src={
                     SERVER_URL +
                     "/cardimages/jpg/" +
                     encodeURIComponent(row[1].fileName) +
                     ".jpg?lastLoad=" +
                     lastLoad
                  }
                  key={"cacheCard-" + row[1].fileName}
                  width={50}
                  style={{ display: "block" }}
                  onLoad={() => setImageLoaded(row[0])}
               />
            ))}
      </Box>
   );
}
