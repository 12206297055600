import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import WrapperViewer from "../../components/layout/WrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

//HOOKS
import useStandingsHandler from "../../hooks/useStandingsHandler";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

const cardWidth = (length) => {
   switch (length) {
      case 1:
         return 600;
         break;
      case 2:
         return 600;
         break;
      case 3:
         return 470;
         break;
      case 4:
         return 370;
         break;
      case 5:
         return 310;
         break;
      case 6:
         return 325;
         break;
      default:
         return 310;
   }
};

export default function FeatureCardsPreview({ activeData, show, preview }) {
   return (
      <WrapperViewer>
         <AnimatePresence>
            <Box
               sx={{
                  position: "absolute",
                  top: 40,
                  right: 100,
                  height: 200,
               }}
            >
               <img src={SERVER_URL + "/images/ui/logo-800.png"} width='125' />
            </Box>

            {show && activeData && (
               <Stack
                  sx={{
                     position: "absolute",
                     top: 20,
                     left: 100,
                     width: 1720,
                     height: 200,
                  }}
                  alignItems='center'
                  justifyContent='center'
                  key={activeData.title}
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{
                     opacity: 1,
                     transition: { delay: 0.5 },
                  }}
                  exit={{
                     opacity: 0,
                  }}
                  transition={{
                     easing: "easeOut",
                     duration: preview ? 0 : 0.5,
                  }}
               >
                  <Typography
                     variant='h1Viewer'
                     component='div'
                     sx={{ textTransform: "uppercase" }}
                  >
                     {activeData.title}
                  </Typography>
               </Stack>
            )}
         </AnimatePresence>

         <AnimatePresence>
            {show &&
               activeData &&
               activeData.cards &&
               activeData.cards.length > 0 && (
                  <Stack
                     direction='row'
                     alignItems='center'
                     justifyContent='center'
                     spacing={3}
                     useFlexGap
                     sx={{
                        position: "absolute",
                        width: 1720,
                        height: 750,
                        top: 200,
                        left: 100,
                     }}
                     key={
                        activeData.id +
                        "-" +
                        activeData.title +
                        "-" +
                        activeData.cards
                     }
                     component={motion.div}
                     initial={{ opacity: 0 }}
                     animate={{
                        opacity: 1,
                        transition: { delay: 0.5 },
                     }}
                     exit={{
                        opacity: 0,
                     }}
                     transition={{
                        easing: "easeOut",
                        duration: preview ? 0 : 0.5,
                     }}
                     flexWrap='wrap'
                  >
                     {activeData.cards.map((card) => (
                        <>
                           <Box
                              sx={{
                                 position: "relative",
                                 width: card.rotate
                                    ? cardWidth(activeData.cards.length) * 1.2
                                    : cardWidth(activeData.cards.length),
                                 px: card.rotate
                                    ? activeData.cards.length == 1
                                       ? 8
                                       : activeData.cards.length < 6
                                       ? (7.5 - activeData.cards.length) * 3.2
                                       : (8.6 - activeData.cards.length) * 3.2
                                    : 2,
                                 transformOrigin: "center",
                                 transform: card.rotate
                                    ? "rotate(" + card.rotate + "deg)"
                                    : "",
                              }}
                           >
                              <Box
                                 sx={{
                                    overflow: "hidden",
                                 }}
                              >
                                 <img
                                    src={
                                       SERVER_URL +
                                       "/cardimages/png/" +
                                       encodeURIComponent(card.FileName) +
                                       ".png"
                                    }
                                    width='100%'
                                    style={{
                                       display: "block",
                                    }}
                                 />
                              </Box>
                           </Box>
                           {activeData.cards.length == 7 && <br />}
                        </>
                     ))}
                  </Stack>
               )}
         </AnimatePresence>
      </WrapperViewer>
   );
}
