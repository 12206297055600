import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import SortIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";

import ManaIconsFormatted from "../components/decklists/ManaIconsFormatted";
import CardImage from "../components/cardsinhand/CardImage";
import WrapperIpad from "../components/layout/WrapperIpad";

import DecklistSelect from "../components/cardsinhand/DecklistSelect";
import NotificationWidget from "../components/widgets/NotificationWidget";

//HOOKS
import useCardsInHand from "../hooks/useCardsInHand";
import useFeatureMatchHandler from "../hooks/useFeatureMatchHandler";
import useGlobals from "../hooks/useGlobals";

export default function CardsInHand() {
   let { tableId } = useParams();
   let { playerPosition } = useParams();

   let navigate = useNavigate();

   const [recent, setRecent] = useState([]);
   const [searchValue, setSearchValue] = useState("");
   const [searchResults, setSearchResults] = useState([]);
   const [filterSets, setFilterSets] = useState(false);
   const [draftFormats, setDraftFormats] = useState();
   const [playerName, setPlayerName] = useState();

   const [orderBy, setOrderBy] = useState(["CMC", "CardManaCost", "CardName"]);
   const [sort, setSort] = useState(["asc", "asc", "asc"]);

   // USE SOCKETS HOOK
   const [globals, { getGlobals }] = useGlobals();

   const [
      players,
      allPlayers,
      gameState,
      cardsInHandFM,
      update,
      lastUpdated,
      saveRequired,
      needsSave,
      { subscribeFeatureMatch, getAllPlayers },
   ] = useFeatureMatchHandler();

   const [
      allDecklists,
      decklist,
      allCardNames,
      cardsInHand,
      notification,
      {
         getCardsInHand,
         addToHand,
         removeCardInHand,
         getDecklist,
         getAllCardNames,
         getFeatureMatchDecklists,
      },
   ] = useCardsInHand();

   useEffect(() => {
      subscribeFeatureMatch(tableId);
      getFeatureMatchDecklists(tableId);
      getCardsInHand(tableId, playerPosition);
      getAllCardNames();
      getGlobals();
   }, []);

   useEffect(() => {
      const formats = _.find(globals, { id: "DraftSets" });
      if (formats) {
         setDraftFormats(_.sortBy(formats.json));
      }
   }, [globals]);

   useEffect(() => {
      getFeatureMatchDecklists(tableId);
   }, [players]);

   useEffect(() => {
      getCardsInHand(tableId, playerPosition);
   }, [playerPosition]);

   useEffect(() => {
      if (gameState) {
         const player = _.find(players, {
            id: gameState["PlayerIdP" + playerPosition],
         });

         if (player && player.PlayerName) {
            setPlayerName(player.PlayerName);
         } else {
            setPlayerName();
         }
      }
   }, [players, playerPosition]);

   useEffect(() => {
      if (searchValue && searchValue.length >= 3) {
         const search = (searchValue) =>
            allCardNames.filter(
               ({ CardSearchString, Set }) =>
                  CardSearchString.toLowerCase().includes(
                     searchValue.toLowerCase().replace(/[^A-Za-z0-9]/g, "")
                  ) &&
                  (filterSets ? draftFormats.includes(Set.toLowerCase()) : true)
            );
         let result = search(searchValue);
         result = _.orderBy(result, ["CardName"], ["asc"]);

         setSearchResults(result.slice(0, 30));
      }
   }, [searchValue]);

   const handleActiveDecklist = (decklistId) => {
      getDecklist(decklistId);

      if (decklistId == "search") {
         setSearchResults([]);
      }
   };

   const handleSearch = (e) => {
      setSearchValue(e.target.value);
   };

   const addToRecent = (card) => {
      if (card.type === "image") {
         setRecent((prevState) => {
            let oldState = [...prevState];
            let newState;

            const exists = _.find(oldState, function (row) {
               return row.cardName === card.cardName;
            });

            if (!exists) {
               newState = [card, ...oldState];
            } else {
               newState = oldState;
            }

            newState.map((row, i) => {
               row.id = i;
               return row;
            });
            return newState.slice(0, 15);
         });
      }
   };

   const showRecent = () => {
      let recents = recent.map((row) => {
         row = row.card;
         return row;
      });

      recents = _.orderBy(recents, ["CardName"], ["asc"]);
      setSearchResults(recents);
   };

   const clearSearch = () => {
      setSearchValue("");
   };

   const toggleFilterSets = () => {
      setFilterSets(!filterSets);
   };

   const handleToggleDelirium = (playerNum) => {
      update(
         tableId,
         {
            ["DeliriumP" + playerPosition]:
               !gameState["DeliriumP" + playerPosition],
         },
         true
      );
   };

   return (
      <WrapperIpad>
         <Stack
            direction='row'
            alignItems='flex-end'
            spacing={3}
            sx={{
               height: "auto",
               width: "100%",
               backgroundColor: "#ccc",
               px: 4,
               py: 2,
            }}
            key={"cardsInHandController" + playerPosition}
         >
            <Stack
               justifyContent='flex-start'
               sx={{
                  width: "100%",
               }}
            >
               <Typography
                  variant='h5'
                  component='div'
                  sx={{
                     textTransform: "uppercase",
                     whiteSpace: "nowrap",
                     color: "#999",
                     px: 1,
                     py: 1,
                  }}
               >
                  CARDS IN HAND | Player {playerPosition}
               </Typography>
               {allDecklists && (
                  <DecklistSelect
                     defaultSelect={"search"}
                     decklists={allDecklists}
                     callback={handleActiveDecklist}
                  />
               )}
            </Stack>

            <Button
               onClick={() => {
                  if (playerPosition == 2) {
                     navigate("/cardsinhand/1/1");
                  } else {
                     navigate("/cardsinhand/1/2");
                  }
               }}
               variant='outlined'
               color='secondary'
               size='small'
            >
               Switch to Player {playerPosition === "2" ? 1 : 2}
            </Button>

            <Button
               onClick={() => navigate("/draftipad/1")}
               variant='outlined'
               color='warning'
               size='small'
            >
               Switch to Draft Controller
            </Button>
         </Stack>

         <Stack
            direction='row'
            sx={{ width: "100%", height: "calc(100% - 100px)", py: 4, px: 3 }}
            alignItems='flex-start'
            justifyContent='flex-start'
            spacing={2}
         >
            <Stack
               spacing={1.5}
               sx={{
                  width: "50%",
                  height: "100%",
                  mb: 8,
                  px: 2,
                  overflowY: "auto",
                  overflowX: "hidden",
               }}
            >
               <Box key={"playerName" + playerPosition}>
                  <Typography
                     variant='h5'
                     component='div'
                     sx={{ textAlign: "center", textTransform: "uppercase" }}
                     key={"playerName" + playerPosition}
                  >
                     Player {playerPosition}
                  </Typography>
                  <Typography
                     variant='h4'
                     component='div'
                     sx={{ textAlign: "center", textTransform: "uppercase" }}
                  >
                     {playerName}
                  </Typography>
               </Box>

               <Stack pb={2}>
                  <Button
                     variant={
                        gameState && gameState["DeliriumP" + playerPosition]
                           ? "contained"
                           : "outlined"
                     }
                     color={
                        gameState && gameState["DeliriumP" + playerPosition]
                           ? "warning"
                           : "secondary"
                     }
                     onClick={handleToggleDelirium}
                  >
                     Delirium
                  </Button>
               </Stack>

               <Typography
                  variant='h4'
                  component='div'
                  sx={{
                     textAlign: "center",
                     textTransform: "uppercase",
                     backgroundColor: "#ccc",
                     color: "#333",
                     py: 1.5,
                  }}
               >
                  CURRENT HAND
               </Typography>
               {cardsInHand &&
                  cardsInHand.map((row, i) => (
                     <Button
                        variant='ipadButtonCIH'
                        color='delete'
                        fullWidth
                        id={row.id}
                        onClick={() =>
                           removeCardInHand(tableId, playerPosition, row.id)
                        }
                        key={"cardRow" + "-" + row.id}
                     >
                        <Stack
                           direction='row'
                           sx={{ width: "100%" }}
                           alignItems='center'
                           justifyContent='flex-end'
                        >
                           <Box
                              sx={{
                                 width: "100%",
                                 textAlign: "left",
                                 overflow: "hidden",
                                 whiteSpace: "nowrap",
                              }}
                           >
                              {row.card.Name}
                           </Box>
                           <ManaIconsFormatted
                              iconWidth={15}
                              mana={row.card.Mana}
                           />
                        </Stack>
                     </Button>
                  ))}
            </Stack>
            <Stack
               spacing={1.5}
               sx={{
                  width: "50%",
                  height: "100%",
                  mb: 8,
                  px: 2,
                  overflowY: "auto",
                  overflowX: "hidden",
               }}
            >
               {!decklist && (
                  <>
                     <Box>
                        <Typography
                           variant='h4'
                           component='div'
                           sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                           }}
                        >
                           Card Search
                        </Typography>
                        <Typography
                           variant='h6'
                           component='div'
                           sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                           }}
                        >
                           Max. Result 30. Type at least 3 characters
                        </Typography>
                     </Box>

                     <Button
                        onClick={toggleFilterSets}
                        variant={filterSets ? "contained" : "outlined"}
                        color={filterSets ? "error" : "secondary"}
                        size='small'
                     >
                        DRAFT MODE ({draftFormats && draftFormats.join(", ")})
                     </Button>
                     <Box>
                        <TextField
                           fullWidth
                           placeholder='Enter text here'
                           value={searchValue}
                           inputProps={{
                              style: { fontSize: 24, textAlign: "center" },
                           }}
                           onClick={(event) => {
                              event.target.select();
                           }}
                           onBlur={clearSearch}
                           onChange={handleSearch}
                        />
                     </Box>
                     <Button
                        onClick={showRecent}
                        sx={{ mr: 4 }}
                        variant='outlined'
                        color='success'
                        size='large'
                     >
                        SHOW RECENT CARDS
                     </Button>

                     <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                        sx={{ color: "#fff" }}
                     >
                        <Typography
                           variant='h5'
                           component='div'
                           sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                              my: 2,
                           }}
                        >
                           {searchResults.length} Results
                        </Typography>

                        <Button
                           onClick={() => setSearchResults([])}
                           sx={{ ml: 4 }}
                           variant='outlined'
                           color='error'
                        >
                           CLEAR
                        </Button>
                     </Stack>

                     {searchResults &&
                        searchResults.map((card, i) => (
                           <Button
                              variant='ipadButtonCIH'
                              color='add'
                              fullWidth
                              id={card.CardSearchString}
                              onClick={() => {
                                 addToHand(
                                    tableId,
                                    playerPosition,
                                    card,
                                    i,
                                    cardsInHand.length
                                 );
                                 addToRecent({
                                    type: "image",
                                    cardName: card.CardName,
                                    value: card.FileName,
                                    card: card,
                                 });
                              }}
                              key={"cardRow" + i + "-" + card.CardName}
                           >
                              <Stack
                                 direction='row'
                                 sx={{ width: "100%" }}
                                 alignItems='center'
                                 justifyContent='flex-end'
                                 spacing={3}
                              >
                                 <Box
                                    sx={{
                                       width: 100,
                                       height: 70,
                                       position: "relative",
                                       overflow: "hidden",
                                    }}
                                 >
                                    <CardImage
                                       key={"card" + i + "-" + card.ID}
                                       id={card.CardSearchString}
                                       name={card.CardName}
                                       borderRadius={0.5}
                                       showName={false}
                                       fileName={card.FileName}
                                    />
                                 </Box>
                                 <Stack
                                    spacing={0.5}
                                    sx={{
                                       width: "100%",
                                    }}
                                 >
                                    <Box
                                       sx={{
                                          width: "100%",
                                          textAlign: "left",
                                       }}
                                    >
                                       {card.CardName}
                                    </Box>
                                    <ManaIconsFormatted
                                       iconWidth={12}
                                       mana={card.CardManaCost}
                                    />
                                 </Stack>
                              </Stack>
                           </Button>
                        ))}
                  </>
               )}

               {decklist && (
                  <>
                     <Box sx={{ mb: 2 }}>
                        <Typography
                           variant='h4'
                           component='div'
                           sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                           }}
                        >
                           {decklist.PlayerName}
                        </Typography>
                        <Typography
                           variant='h5'
                           component='div'
                           sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                           }}
                        >
                           {decklist.Archetype} | {decklist.Format}
                        </Typography>
                     </Box>

                     <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='center'
                        sx={{ py: 2 }}
                     >
                        <Button
                           size='large'
                           variant={orderBy.join() == "CMC,CardManaCost,CardName" ? "contained" : "outlined"}
                           color={orderBy.join() == "CMC,CardManaCost,CardName" ? "error" : "primary"}
                           onClick={() => setOrderBy(["CMC", "CardManaCost", "CardName"])}
                        >
                           CMC
                        </Button>

                        <Button
                           size='large'
                           variant={
                              orderBy == "CardName,CMC,CardManaCost" ? "contained" : "outlined"
                           }
                           color={orderBy == "CardName,CMC,CardManaCost" ? "error" : "primary"}
                           onClick={() => setOrderBy(["CardName", "CMC", "CardManaCost"])}
                        >
                           Card Name
                        </Button>

                        {sort.join() == "asc,asc,asc" && (
                           <IconButton
                              size='large'
                              variant={"outlined"}
                              onClick={() => setSort(["desc", "desc", "desc"])}
                              color='primary'
                           >
                              <SortIcon
                                 sx={{
                                    transform: "rotate(180deg)",
                                 }}
                              />
                           </IconButton>
                        )}

                        {sort.join() == "desc,desc,desc" && (
                           <IconButton
                              size='small'
                              variant={"outlined"}
                              onClick={() => setSort(["asc", "asc", "asc"])}
                              color='primary'
                           >
                              <SortIcon />
                           </IconButton>
                        )}
                     </Stack>

                     <Typography
                        variant='h4'
                        component='div'
                        sx={{
                           textAlign: "center",
                           textTransform: "uppercase",
                           backgroundColor: "#ccc",
                           color: "#333",
                           py: 1.5,
                        }}
                     >
                        MAIN DECK
                     </Typography>
                     
                     {_.orderBy(decklist.MainDeck, orderBy, sort).map(
                        (card, i) => (
                           <Button
                              variant='ipadButtonCIH'
                              color='add'
                              fullWidth
                              id={card.CardSearchString}
                              onClick={() => {
                                 addToHand(
                                    tableId,
                                    playerPosition,
                                    card,
                                    i,
                                    cardsInHand.length
                                 );
                                 addToRecent(card);
                              }}
                              key={"cardRow" + i + "-" + card.CardName}
                           >
                              <Stack
                                 direction='row'
                                 sx={{ width: "100%" }}
                                 alignItems='center'
                                 justifyContent='flex-end'
                                 spacing={3}
                              >
                                 <Box
                                    sx={{
                                       width: 100,
                                       height: 70,
                                       position: "relative",
                                       overflow: "hidden",
                                    }}
                                 >
                                    <CardImage
                                       key={"card" + i + "-" + card.ID}
                                       id={card.CardSearchString}
                                       name={card.CardName}
                                       borderRadius={0.5}
                                       showName={false}
                                       fileName={card.FileName}
                                    />
                                 </Box>
                                 <Stack
                                    spacing={0.5}
                                    sx={{
                                       width: "100%",
                                    }}
                                 >
                                    <Box
                                       sx={{
                                          width: "100%",
                                          textAlign: "left",
                                       }}
                                    >
                                       {card.CardName}
                                    </Box>
                                    <ManaIconsFormatted
                                       iconWidth={12}
                                       mana={card.CardManaCost}
                                    />
                                 </Stack>
                              </Stack>
                           </Button>
                        )
                     )}

                     <Typography
                        variant='h4'
                        component='div'
                        sx={{
                           textAlign: "center",
                           textTransform: "uppercase",
                           backgroundColor: "#ccc",
                           color: "#333",
                           py: 1.5,
                        }}
                     >
                        SIDEBOARD
                     </Typography>
                     {_.orderBy(decklist.Sideboard, orderBy, sort).map(
                        (card, i) => (
                           <Button
                              variant='ipadButtonCIH'
                              color='addSideboard'
                              fullWidth
                              id={card.CardSearchString}
                              onClick={() => {
                                 addToHand(
                                    tableId,
                                    playerPosition,
                                    card,
                                    i,
                                    cardsInHand.length
                                 );
                                 addToRecent(card);
                              }}
                              key={"cardRow" + i + "-" + card.CardName}
                           >
                              <Stack
                                 direction='row'
                                 sx={{ width: "100%" }}
                                 alignItems='center'
                                 justifyContent='flex-end'
                                 spacing={2}
                              >
                                 <Box
                                    sx={{
                                       width: 100,
                                       height: 70,
                                       position: "relative",
                                       overflow: "hidden",
                                    }}
                                 >
                                    <CardImage
                                       key={"card" + i + "-" + card.ID}
                                       id={card.CardSearchString}
                                       name={card.CardName}
                                       borderRadius={0.5}
                                       showName={false}
                                       fileName={card.FileName}
                                    />
                                 </Box>
                                 <Stack
                                    spacing={0.5}
                                    sx={{
                                       width: "100%",
                                    }}
                                 >
                                    <Box
                                       sx={{
                                          width: "100%",
                                          textAlign: "left",
                                       }}
                                    >
                                       {card.CardName}
                                    </Box>
                                    <ManaIconsFormatted
                                       iconWidth={12}
                                       mana={card.CardManaCost}
                                    />
                                 </Stack>
                              </Stack>
                           </Button>
                        )
                     )}
                  </>
               )}
            </Stack>
         </Stack>

         {notification && (
            <NotificationWidget
               notification={notification.message}
               key={"notification" + notification.date}
            />
         )}
      </WrapperIpad>
   );
}
