import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function useBracketHandler() {
   const socket = useContext(SocketContext);

   const [allPlayers, setAllPlayers] = useState([]);
   const [top8MovingOn, setTop8MovingOn] = useState({
      1: "",
      2: "",
      3: "",
      4: "",
      5: "",
      6: "",
      7: "",
      8: "",
   });
   const [top8MovingOnLeaderboard, setTop8MovingOnLeaderboard] = useState([]);
   const [show, setShow] = useState(true);
   const [runTimestamp, setRunTimestamp] = useState(Date.now());

   const handlers = useMemo(
      () => ({
         getAllPlayers: () => {
            socket.emit("getAllPlayers", (response) => {
               if (response) {
                  setAllPlayers(response);
               }
            });
         },
         getTop8MovingOn: () => {
            socket.emit("getTop8MovingOn", (response) => {
               if (response) {
                  setTop8MovingOn(response);
               }
            });
         },
         getTop8MovingOnLeaderboard: () => {
            socket.emit("getTop8MovingOnLeaderboard", (response) => {
               if (response) {
                  // console.log(response);
                  setTop8MovingOnLeaderboard(response);
               }
            });
         },
         update: (slot, playerId) => {
            setTop8MovingOn((prevState) => {
               let newState = { ...prevState };
               newState[slot] = playerId;
               socket.emit("updateTop8MovingOn", newState);
               return newState;
            });
         },
         load: () => {
            socket.emit("top8MovingOnViewerLoad");
         },
         showHide: (action) => {
            socket.emit("top8MovingOnViewerShowHide", action);
         },
         subscribeTop8MovingOnViewer: () => {
            socket.emit("subscribeTop8MovingOnViewer", (response) => {
               console.log(response);
            });

            socket.on("top8MovingOnViewerLoad", (data) => {
               setTop8MovingOnLeaderboard(data);
            });

            socket.on("top8MovingOnViewerShowHide", (action) => {
               switch (action) {
                  case "show":
                     setRunTimestamp(Date.now());
                     setShow(true);
                     break;
                  case "hide":
                     setShow(false);
                     break;
                  case "toggle":
                     setShow((prevState) => {
                        return !prevState;
                     });
                     break;
               }
            });
         },
      }),
      []
   );

   return [
      allPlayers,
      top8MovingOn,
      top8MovingOnLeaderboard,
      show,
      runTimestamp,
      handlers,
   ];
}
