import React, { useState, useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function CardImage({
   id,
   width,
   name,
   fileName,
   borderRadius = 2.8,
   showName = true,
}) {
   return (
      <>
         {!fileName && (
            <>
               {showName && (
                  <Typography
                     variant='h5DecklistNoImage'
                     component='div'
                     sx={{
                        fontSize: 12,
                        width: "80%",
                        zIndex: 100,
                        top: "50%",
                        left: "50%",
                        transform: "translateX(-50%) translateY(-50%)",
                        position: "absolute",
                        color: "#fff",
                        fontWeight: "bold",
                     }}
                  >
                     {name}
                  </Typography>
               )}
               <img
                  width='100'
                  src={SERVER_URL + "/images/card-back.png"}
                  style={{ display: "block" }}
               />
            </>
         )}

         {fileName && (
            <img
               src={
                  SERVER_URL +
                  "/cardimages/thumbs/" +
                  encodeURIComponent(fileName) +
                  ".jpg"
               }
               style={{
                  width: "100%",
                  position: "absolute",
                  top: -4,
                  left: 0,
                  display: "block",
               }}
            />
         )}
      </>
   );
}
