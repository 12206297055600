import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";

import PlayerSelect from "./PlayerSelect";
import AdjustDraftTitle from "./AdjustDraftTitle";
import AdjustValue from "./AdjustValue";

import ManaIconsFormatted from "../../components/decklists/ManaIconsFormatted";
import CardImage from "../../components/draft/CardImage";

//HOOKS
import useDraftHandler from "../../hooks/useDraftHandler";
import useCardHandler from "../../hooks/useCardHandler";
import useGlobals from "../../hooks/useGlobals";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   width: "80%",
   transform: "translate(-50%, -50%)",
   bgcolor: "#151515",
   border: "2px solid #333",
   boxShadow: 24,
   p: 4,
   textAlign: "center",
};

export default function DashboardDraftTable({ draftId }) {
   let navigate = useNavigate();

   const columnWidth = useRef();
   const [previewOverlay, setPreviewOverlay] = useState(false);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   // USE HOOKS
   const [globals, { getGlobals }] = useGlobals();

   const [
      player,
      allPlayers,
      allCardNames,
      gameState,
      picks,
      update,
      lastUpdated,
      data,
      loaded,
      setLoaded,
      visible,
      rotation,
      {
         subscribeDraft,
         refreshFMPhotos,
         getAllPlayers,
         addPick,
         show,
         removePick,
         getAllCardNames,
         toggleDraftVisibility,
         toggleDraftRotation,
         draftOverlayDataLoad,
      },
   ] = useDraftHandler();

   const [allCards, notification, { getAllCards }] = useCardHandler();

   const [recent, setRecent] = useState([]);
   const [searchValue, setSearchValue] = useState("");
   const [searchResults, setSearchResults] = useState([]);
   const [filterSets, setFilterSets] = useState(true);
   const [draftFormats, setDraftFormats] = useState();

   // MODAL
   const [openRoundTitle, setOpenRoundTitle] = useState(false);
   const handleOpenRoundTitle = () => setOpenRoundTitle(true);
   const handleCloseRoundTitle = () => setOpenRoundTitle(false);

   useEffect(() => {
      subscribeDraft(1);
      getAllPlayers();
      getAllCardNames();
      getGlobals();
   }, []);

   useEffect(() => {
      const formats = _.find(globals, { id: "DraftSets" });
      if (formats) {
         setDraftFormats(_.sortBy(formats.json));
      }
   }, [globals]);

   useEffect(() => {
      // console.log(draftFormats);
   }, [draftFormats]);

   useEffect(() => {
      // console.log(allPlayers);
   }, [allPlayers]);

   useEffect(() => {
      // console.log(player);
   }, [player]);

   useEffect(() => {
      // console.log(picks);
   }, [picks]);

   const handleTextfieldKeyPress = (e) => {
      if (e.keyCode === 13) {
         e.target.blur();
      }
   };

   const updateRoundTitle = (value) => {
      update(1, { ["RoundTitle"]: value });
      handleCloseRoundTitle();
   };

   const handleAddPick = (draftId, card) => {
      addPick(draftId, card);
   };

   useEffect(() => {
      if (searchValue && searchValue.length >= 3) {
         const search = (searchValue) =>
            allCardNames.filter(
               ({ CardSearchString, Set }) =>
                  CardSearchString.toLowerCase().includes(
                     searchValue.toLowerCase().replace(/[^A-Za-z0-9]/g, "")
                  ) &&
                  (filterSets ? draftFormats.includes(Set.toLowerCase()) : true)
            );
         let result = search(searchValue);
         result = _.orderBy(result, ["CardName"], ["asc"]);

         setSearchResults(result.slice(0, 30));
      }
   }, [searchValue]);

   const addToRecent = (card) => {
      if (card.type === "image") {
         setRecent((prevState) => {
            let oldState = [...prevState];
            let newState;

            const exists = _.find(oldState, function (row) {
               return row.cardName === card.cardName;
            });

            if (!exists) {
               newState = [card, ...oldState];
            } else {
               newState = oldState;
            }

            newState.map((row, i) => {
               row.id = i;
               return row;
            });
            return newState.slice(0, 14);
         });
      }
   };

   const showRecent = () => {
      let recents = recent.map((row) => {
         row = row.card;
         return row;
      });

      recents = _.orderBy(recents, ["CardName"], ["asc"]);
      setSearchResults(recents);
   };

   const clearSearch = () => {
      setSearchValue("");
   };

   const handleSearch = (e) => {
      setSearchValue(e.target.value);
   };

   const toggleFilterSets = () => {
      setFilterSets(!filterSets);
   };

   return (
      <>
         <Grid container>
            <Grid item xs={12} sx={{ height: 110 }}>
               <Stack
                  direction='row'
                  alignItems='flex-end'
                  sx={{
                     width: "100%",
                     backgroundColor: "#444",
                     px: 4,
                     py: 2,
                  }}
               >
                  <Stack
                     justifyContent='flex-start'
                     sx={{
                        width: "100%",
                     }}
                  >
                     <Typography
                        variant='h5'
                        component='div'
                        sx={{
                           textTransform: "uppercase",
                           whiteSpace: "nowrap",
                           color: "#ccc",
                           py: 1,
                        }}
                     >
                        DRAFT CONTROLLER
                     </Typography>
                     <Box sx={{ width: "100%", textTransform: "uppercase" }}>
                        <PlayerSelect
                           allPlayersList={allPlayers}
                           selected={gameState && gameState.PlayerId}
                           draftId={draftId}
                           update={update}
                           disabled
                        />
                     </Box>
                  </Stack>

                  <Button
                     onClick={() => navigate("/cardsinhand/1/1")}
                     variant='outlined'
                     color='warning'
                     size='small'
                     sx={{ width: "40%" }}
                  >
                     Switch to Cards In Hand Controller
                  </Button>
               </Stack>
            </Grid>

            <Grid
               item
               xs={6}
               sx={{
                  height: window.innerHeight - 110,
                  px: 3,
                  py: 2,
                  overflowY: "scroll",
               }}
            >
               {gameState && picks && (
                  <Stack spacing={1}>
                     <Typography
                        variant='h4'
                        component='div'
                        sx={{
                           textAlign: "center",
                           textTransform: "uppercase",
                           backgroundColor: "#ccc",
                           color: "#333",
                           py: 1.5,
                        }}
                     >
                        DRAFT PICKS
                        <br />
                        <Button
                           onClick={() =>
                              handleAddPick(draftId, {
                                 CardManaCost: "",
                                 CardName: "-",
                                 CardSearchString: "",
                                 FileName: "",
                                 Set: "",
                              })
                           }
                           variant='outlined'
                           color='secondary'
                           size='small'
                        >
                           + Placeholder
                        </Button>
                     </Typography>
                     {gameState &&
                        picks &&
                        picks.map((row, i) => (
                           <React.Fragment key={"pickRow" + row.id}>
                              {picks.length - i == 28 ||
                              picks.length - i == 14 ? (
                                 <Typography
                                    variant='h5'
                                    component='div'
                                    sx={{
                                       textAlign: "center",
                                       textTransform: "uppercase",
                                       backgroundColor: "#222",
                                       color: "#ccc",
                                       py: 1.5,
                                    }}
                                 >
                                    PACK
                                 </Typography>
                              ) : (
                                 ""
                              )}
                              <Stack spacing={2} direction='row'>
                                 <Box
                                    sx={{
                                       fontSize: "1em",
                                       backgroundColor: "#333",
                                       color: "#ccc",
                                       width: "100%",
                                       borderRadius: 1,
                                    }}
                                    key={"cardRow-" + row.id}
                                 >
                                    <Stack
                                       direction='row'
                                       sx={{ width: "100%" }}
                                       alignItems='center'
                                       justifyContent='flex-end'
                                       spacing={2}
                                    >
                                       <Box
                                          sx={{
                                             fontWeight: "bold",
                                             pl: 2,
                                             textAlign: "center",
                                          }}
                                       >
                                          {picks.length - i}
                                       </Box>
                                       <Box
                                          sx={{
                                             width: 100,
                                             height: 70,
                                             position: "relative",
                                             overflow: "hidden",
                                          }}
                                       >
                                          <CardImage
                                             key={"cardImage-" + row.card.id}
                                             id={row.card.CardSearchString}
                                             name={row.card.CardName}
                                             borderRadius={0.5}
                                             showName={false}
                                             fileName={row.card.FileName}
                                          />
                                       </Box>
                                       <Stack
                                          spacing={0.5}
                                          sx={{
                                             width: "100%",
                                          }}
                                       >
                                          <Box
                                             sx={{
                                                width: "100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                             }}
                                          >
                                             {row.card.CardName}
                                          </Box>
                                          <ManaIconsFormatted
                                             iconWidth={12}
                                             mana={row.card.CardManaCost}
                                          />
                                       </Stack>
                                    </Stack>
                                 </Box>
                                 {/* <Button
                                    variant='outlined'
                                    color='error'
                                    onClick={() => removePick(draftId, row.id)}
                                 >
                                    X
                                 </Button> */}
                              </Stack>
                           </React.Fragment>
                        ))}
                  </Stack>
               )}
            </Grid>
            <Grid
               item
               xs={6}
               sx={{
                  height: window.innerHeight - 180,
                  px: 3,
                  py: 2,
                  overflowY: "scroll",
               }}
            >
               <Stack spacing={1.5} sx={{ mb: 2, width: "100%" }}>
                  <Typography
                     variant='h4'
                     component='div'
                     sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                        backgroundColor: "#ccc",
                        color: "#333",
                        py: 1.5,
                     }}
                  >
                     Card Search
                  </Typography>
                  <Typography
                     variant='h6'
                     component='div'
                     sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                     }}
                  >
                     Max. Result 30. Type at least 3 characters
                  </Typography>

                  <Button
                     onClick={toggleFilterSets}
                     variant={filterSets ? "contained" : "outlined"}
                     color={filterSets ? "error" : "secondary"}
                     size='small'
                     fullWidth
                  >
                     DRAFT MODE ({draftFormats && draftFormats.join(", ")})
                  </Button>

                  <Box>
                     <TextField
                        fullWidth
                        placeholder='Enter text here'
                        value={searchValue}
                        inputProps={{
                           style: { fontSize: 24, textAlign: "center" },
                        }}
                        onClick={(event) => {
                           event.target.select();
                        }}
                        onBlur={clearSearch}
                        onChange={handleSearch}
                     />
                  </Box>
               </Stack>

               <Button
                  onClick={showRecent}
                  variant='outlined'
                  color='success'
                  size='large'
                  fullWidth
               >
                  SHOW RECENT CARDS
               </Button>

               <Stack
                  direction='row'
                  alignItems='stretch'
                  justifyContent='center'
                  sx={{ color: "#fff", mb: 2, my: 2 }}
               >
                  <Typography
                     variant='h5'
                     component='div'
                     sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                        my: 2,
                     }}
                  >
                     {searchResults.length} Results
                  </Typography>

                  <Button
                     onClick={() => setSearchResults([])}
                     sx={{ ml: 4 }}
                     variant='outlined'
                     color='error'
                  >
                     CLEAR
                  </Button>
               </Stack>
               <Stack spacing={1}>
                  {searchResults &&
                     searchResults.map((card, i) => (
                        <Stack
                           spacing={2}
                           direction='row'
                           key={"searchResult" + i}
                        >
                           <Box
                              sx={{
                                 fontSize: "1em",
                                 backgroundColor: "#333",
                                 color: "#ccc",
                                 width: "100%",
                                 borderRadius: 1,
                              }}
                              key={"cardSearchRow-" + card.id}
                           >
                              <Stack
                                 direction='row'
                                 sx={{ width: "100%" }}
                                 alignItems='center'
                                 justifyContent='flex-end'
                                 spacing={3}
                              >
                                 <Box
                                    sx={{
                                       width: 100,
                                       height: 70,
                                       position: "relative",
                                       overflow: "hidden",
                                    }}
                                 >
                                    <CardImage
                                       key={"cardSearchImage-" + card.ID}
                                       id={card.CardSearchString}
                                       name={card.CardName}
                                       borderRadius={0.5}
                                       showName={false}
                                       fileName={card.FileName}
                                    />
                                 </Box>
                                 <Stack
                                    spacing={0.5}
                                    sx={{
                                       width: "100%",
                                    }}
                                 >
                                    <Box
                                       sx={{
                                          width: "100%",
                                          textAlign: "left",
                                          fontWeight: "bold",
                                       }}
                                    >
                                       {card.CardName}
                                    </Box>
                                    <ManaIconsFormatted
                                       iconWidth={12}
                                       mana={card.CardManaCost}
                                    />
                                 </Stack>
                              </Stack>
                           </Box>
                           <Button
                              id={card.CardSearchString}
                              variant='contained'
                              color='success'
                              onClick={() => {
                                 handleAddPick(draftId, card);
                                 addToRecent({
                                    type: "image",
                                    cardName: card.CardName,
                                    value: card.FileName,
                                    card: card,
                                 });
                              }}
                              sx={{ width: "15%" }}
                           >
                              Add
                           </Button>
                        </Stack>
                     ))}
               </Stack>
            </Grid>
         </Grid>

         <Modal open={openRoundTitle} onClose={handleCloseRoundTitle}>
            <Box sx={style}>
               <AdjustDraftTitle
                  save={updateRoundTitle}
                  cancel={handleCloseRoundTitle}
               />
            </Box>
         </Modal>
      </>
   );
}
