import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import DebugOverlay from "../components/widgets/DebugOverlay";
import NotificationWidget from "../components/widgets/NotificationWidget";
import DataGrid from "../components/standings/DataGrid";

import Wrapper from "../components/layout/Wrapper";

import Downloader from "../components/widgets/Downloader";
import StandingsPreview from "./viewers/StandingsPreview";
import CopyToClipboardButton from "../components/widgets/CopyToClipboardButton";

//HOOKS
import useStandingsHandler from "../hooks/useStandingsHandler";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function StandingsManager({ parent }) {
   let { id } = useParams();

   const [live, setLive] = useState(false);
   const [previewStandingsData, setPreviewStandingsData] = useState();

   const columnWidth = useRef();

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   // USE HOOK
   const [
      standings,
      setStandings,
      cachedStandings,
      tournamentRounds,
      notification,
      { getCachedStandings, getStandings, standingsViewerLoad },
   ] = useStandingsHandler();

   useEffect(() => {
      getStandings(id);
   }, []);

   useEffect(() => {
      console.log(tournamentRounds);
   }, [tournamentRounds]);

   useEffect(() => {
      // console.log(live, previewStandingsData);
      if (live && previewStandingsData) {
         loadStandings(previewStandingsData.start, previewStandingsData.numRow);
      }
   }, [live]);

   useEffect(() => {
      // console.log(previewStandingsData);
   }, [previewStandingsData]);

   const loadCachedStandings = (e) => {
      const standingsId = e.target.value;
      getStandings(standingsId);
   };

   const previewStandings = (start, numRow) => {
      let standingRows = standings.rows.slice(start, start + numRow);
      setPreviewStandingsData({
         key: standings.key + start,
         rows: standingRows,
         start: start,
         numRow: numRow,
      });

      if (live) {
         loadStandings(start, numRow);
      }
   };

   const loadStandings = (start, numRow) => {
      standingsViewerLoad(id, start, numRow);

      // UPDATE PREVIEW
      let standingRows = standings.rows.slice(start, start + numRow);
      setPreviewStandingsData({
         key: standings.key + start,
         rows: standingRows,
         start: start,
         numRow: numRow,
      });
   };

   const datagridCallback = () => {
      // console.log("callback");
   };

   return (
      <Wrapper>
         {standings && (
            <>
               <Stack
                  direction='row'
                  alignItems='center'
                  sx={{ width: "100%", mb: 2 }}
               >
                  <Typography
                     variant='h3'
                     component='div'
                     sx={{
                        textTransform: "uppercase",
                        mt: 4,
                        mb: 2,
                     }}
                  >
                     {standings.description}
                  </Typography>
                  <CopyToClipboardButton
                     text={"/viewer/standings"}
                     sx={{ ml: "auto", mr: 0 }}
                  />
               </Stack>

               <Grid container spacing={4}>
                  <Grid item xs={12} ref={columnWidth}>
                     {standings.rows && standings.rows.length > 0 && (
                        <DataGrid
                           data={standings.rows}
                           type='standings'
                           disableColumnFilter={false}
                           callback={datagridCallback}
                           height={600}
                        />
                     )}
                  </Grid>
                  <Grid
                     item
                     xs={9}
                     ref={columnWidth}
                     sx={{
                        height: 1080 * iframeScale,
                        position: "relative",
                        mb: 4,
                     }}
                  >
                     <Box
                        sx={{
                           position: "relative",
                           width: 1920,
                           height: 1080,
                           transform: "scale(" + iframeScale + ")",
                           transformOrigin: "top left",
                           overflow: "hidden",
                           outline: live
                              ? "10px solid red"
                              : "10px solid green",
                        }}
                        className='transparentBG'
                     >
                        {standings &&
                           standings.rows &&
                           standings.rows.length > 0 && (
                              <>
                                 {previewStandingsData && (
                                    <StandingsPreview
                                       data={previewStandingsData}
                                    />
                                 )}
                              </>
                           )}
                     </Box>
                  </Grid>

                  <Grid item xs={3}>
                     {standings &&
                        standings.rows &&
                        standings.rows.length > 0 && (
                           <Stack spacing={2} sx={{ width: "100%" }}>
                              <Box sx={{ mb: 4 }}>
                                 <Downloader
                                    enabled={
                                       previewStandingsData ? true : false
                                    }
                                    downloadName={
                                       previewStandingsData &&
                                       previewStandingsData.rows.length > 0
                                          ? "Standings_Rows-" +
                                            previewStandingsData.rows[0].Rank +
                                            "-" +
                                            (parseInt(
                                               previewStandingsData.rows[0].Rank
                                            ) -
                                               1 +
                                               previewStandingsData.rows
                                                  .length) +
                                            "_" +
                                            Date.now()
                                          : ""
                                    }
                                 >
                                    {previewStandingsData && (
                                       <StandingsPreview
                                          data={previewStandingsData}
                                       />
                                    )}
                                 </Downloader>
                              </Box>
                              {parent != "producer" && (
                                 <>
                                    {!live && (
                                       <Button
                                          variant='outlined'
                                          color='success'
                                          onClick={() => setLive(true)}
                                          sx={{
                                             height: 50,
                                             whiteSpace: "nowrap",
                                             p: 2,
                                          }}
                                          fullWidth
                                       >
                                          PREVIEW MODE
                                       </Button>
                                    )}

                                    {live && (
                                       <Button
                                          variant='contained'
                                          color='error'
                                          onClick={() => setLive(false)}
                                          sx={{
                                             height: 50,
                                             whiteSpace: "nowrap",
                                             p: 2,
                                          }}
                                          fullWidth
                                       >
                                          LIVE MODE
                                       </Button>
                                    )}
                                 </>
                              )}

                              <Button
                                 variant='outlined'
                                 color='success'
                                 onClick={() => previewStandings(0, 16)}
                              >
                                 LOAD 1-16
                              </Button>
                              <Button
                                 variant='outlined'
                                 color='success'
                                 onClick={() => previewStandings(16, 16)}
                              >
                                 LOAD 17-32
                              </Button>
                              <Button
                                 variant='outlined'
                                 color='success'
                                 onClick={() => previewStandings(32, 16)}
                              >
                                 LOAD 33-48
                              </Button>
                              <Button
                                 variant='outlined'
                                 color='success'
                                 onClick={() => previewStandings(48, 16)}
                              >
                                 LOAD 49-64
                              </Button>
                           </Stack>
                        )}
                  </Grid>
               </Grid>
            </>
         )}

         <NotificationWidget notification={notification} />
      </Wrapper>
   );
}
