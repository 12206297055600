import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Uploader from "./UploaderPlayerPhotos";
import AllPlayerSelect from "./AllPlayerSelect";
import AllPhotoSelect from "./AllPhotoSelect";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { saveAs } from "file-saver";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DownloadIcon from "@mui/icons-material/Download";

import NotificationWidget from "../../components/widgets/NotificationWidget";
import ReplaceModal from "./ReplaceModal";

//HOOKS
import usePlayerPhotos from "../../hooks/usePlayerPhotos";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function FileList({ parent }) {
   // USE HOOK
   const [
      playerList,
      fileList,
      replaceFilename,
      setReplaceFilename,
      lastFileUpdate,
      setLastFileUpdate,
      notification,
      {
         getPlayerList,
         getFileList,
         refreshPlayerPhotos,
         clearPlayerSelection,
         updatePlayerPhoto,
         deleteFile,
      },
   ] = usePlayerPhotos();

   const [pageSize, setPageSize] = useState(20);
   const [pageSizeLibrary, setPageSizeLibrary] = useState(20);
   const [hasPhotoFilter, setHasPhotoFilter] = useState(false);

   const handleDownload = (e) => {
      saveAs(
         SERVER_URL + "/uploads/playerphotos/" + e.target.value,
         e.target.value
      );
   };

   const getUserInfo = (fileList) => {
      fileList.map((row, i) => {
         const foundUser = _.find(playerList, { playerId: row.playerId });
         return foundUser
            ? (row.playerName = foundUser.PlayerNameLastFirst)
            : (row.playerName = "");
      });
      return fileList;
   };

   const findMissing = (playerList, fileList) => {
      // GET ARRAY OF playerId FROM FILES ON SERVER
      const assignedPhotos = fileList
         .map((row) => {
            return {
               playerId: row.playerId,
               fileName: row.fileName,
            };
         })
         .filter(function (element) {
            return element.playerId !== undefined;
         });

      // ADD hasPhoto PROPERTY TO PLAYER LIST
      const playersWithPhotos = playerList.map((row) => {
         const photoAssigned = _.find(assignedPhotos, function (photo) {
            return photo.playerId == row.id;
         });

         if (photoAssigned) {
            row.hasPhoto = true;
            row.fileName = photoAssigned.fileName;
         } else {
            row.hasPhoto = false;
         }
         return row;
      });

      // console.log(playersWithPhotos);
      return playersWithPhotos;
   };

   let columns;
   let columnsPlayers;
   let initialState;
   let initialStatePlayers;

   columnsPlayers = [
      {
         field: "id",
         headerName: "Player ID",
         editable: false,
         width: 100,
      },
      {
         field: "image",
         headerName: "Image",
         editable: false,
         width: 300,
         filterable: false,
         renderCell: (params) => (
            <>
               {params.row.hasPhoto && (
                  <Box
                     sx={{
                        height: "100%",
                        py: 1,
                        textAlign: "center",
                        width: "100%",
                        position: "relative",
                     }}
                     key={encodeURIComponent(params.row.fileName)}
                  >
                     <Box
                        sx={{
                           height: "100%",
                        }}
                        className='transparentBG'
                     >
                        <Box
                           sx={{
                              position: "absolute",
                              left: 0,
                              width: "50%",
                              height: "100%",
                              borderRight: "1px dashed rgba(255,0,0,.5)",
                           }}
                        />
                        <Box
                           sx={{
                              position: "absolute",
                              left: "25%",
                              width: "50%",
                              height: "30%",
                              borderBottom: "1px dashed rgba(255,0,0,.5)",
                           }}
                        />
                        <img
                           src={
                              SERVER_URL +
                              "/uploads/playerphotos/" +
                              encodeURIComponent(params.row.fileName) +
                              "?" +
                              params.row.updatedAt
                           }
                           height='100%'
                        />
                     </Box>
                  </Box>
               )}
            </>
         ),
      },
      {
         field: "PlayerNameLastFirst",
         headerName: "Player Name",
         editable: false,
         width: 300,
         renderCell: (params) => (
            <>
               {params.value && <>{params.value}</>}

               {!params.value && (
                  <b style={{ color: "red" }}>
                     Sorry no Player Name for this row!
                  </b>
               )}
            </>
         ),
      },
      {
         field: "hasPhoto",
         headerName: "Has Photo?",
         editable: false,
         width: 100,
      },
      {
         field: "photo",
         headerName: "Photo",
         editable: false,
         width: 500,
         renderCell: (params) => (
            <>
               {!params.row.hasPhoto && (
                  <AllPhotoSelect
                     label='Photos'
                     playerId={params.row.id}
                     photos={_.orderBy(
                        _.filter(fileList, (o) => o.playerId === null),
                        [(photo) => photo.fileName.toLowerCase()],
                        ["asc"]
                     )}
                     callback={(fileName) =>
                        handleUpdatePlayerPhoto(params.row.id, fileName)
                     }
                  />
               )}

               {params.row.hasPhoto && (
                  <Stack
                     spacing={1}
                     sx={{ width: "100%" }}
                     alignItems='flex-start'
                  >
                     <Box sx={{ mr: 2 }}>{params.row.fileName}</Box>
                     <Stack direction='row' spacing={2}>
                        <Button
                           color='success'
                           startIcon={<DownloadIcon />}
                           onClick={handleDownload}
                           value={params.row.fileName}
                        >
                           Download
                        </Button>

                        {parent != "producer" && (
                           <Button
                              color='warning'
                              startIcon={<ChangeCircleIcon />}
                              onClick={() =>
                                 clearPlayerSelection(params.row.fileName)
                              }
                           >
                              Clear Selection
                           </Button>
                        )}
                        {/* <Button
                           color='warning'
                           startIcon={<ChangeCircleIcon />}
                           onClick={() =>
                              setReplaceFilename(params.row.fileName)
                           }
                        >
                           Replace
                        </Button>
                        <Button
                           color='error'
                           startIcon={<DeleteIcon />}
                           onClick={() => handleDeleteFile(params.row.fileName)}
                        >
                           Delete
                        </Button> */}
                     </Stack>
                  </Stack>
               )}

               {!params.row.id && (
                  <b style={{ color: "red" }}>
                     Sorry no Player ID for this row, can't associate!
                  </b>
               )}
            </>
         ),
      },
   ];

   columns = [
      {
         field: "image",
         headerName: "Image",
         editable: false,
         width: 300,
         filterable: false,
         renderCell: (params) => (
            <Box
               sx={{
                  height: "100%",
                  py: 1,
                  textAlign: "center",
                  width: "100%",
                  position: "relative",
               }}
               key={encodeURIComponent(params.row.fileName)}
            >
               <Box
                  sx={{
                     height: "100%",
                  }}
                  className='transparentBG'
               >
                  <Box
                     sx={{
                        position: "absolute",
                        left: 0,
                        width: "50%",
                        height: "100%",
                        borderRight: "1px dashed rgba(255,0,0,.5)",
                     }}
                  />
                  <Box
                     sx={{
                        position: "absolute",
                        left: "25%",
                        width: "50%",
                        height: "30%",
                        borderBottom: "1px dashed rgba(255,0,0,.5)",
                     }}
                  />
                  <img
                     src={
                        SERVER_URL +
                        "/uploads/playerphotos/" +
                        encodeURIComponent(params.row.fileName) +
                        "?" +
                        params.row.updatedAt
                     }
                     height='100%'
                  />
               </Box>
            </Box>
         ),
      },
      {
         field: "playerName",
         headerName: "Player Name",
         editable: false,
         width: 500,
         renderCell: (params) => (
            <Stack
               direction='row'
               spacing={3}
               sx={{ width: "100%" }}
               alignItems='center'
            >
               <Box>{params.row.playerName}</Box>
               <AllPlayerSelect
                  label='Players'
                  selected={params.row.playerId}
                  playername={params.row.playerName}
                  players={playerList}
                  callback={(playerId) =>
                     handleUpdatePlayerPhoto(playerId, params.row.fileName)
                  }
               />
            </Stack>
         ),
      },
      {
         field: "fileName",
         headerName: "File Name",
         editable: false,
         width: 400,
         renderCell: (params) => (
            <Stack spacing={1} sx={{ width: "100%" }} alignItems='flex-start'>
               <Box sx={{ mr: 2 }}>{params.value}</Box>
               <Stack direction='row' spacing={2}>
                  <Button
                     color='success'
                     startIcon={<DownloadIcon />}
                     onClick={handleDownload}
                     value={params.value}
                  >
                     Download
                  </Button>
                  <Button
                     color='warning'
                     startIcon={<ChangeCircleIcon />}
                     onClick={() => setReplaceFilename(params.value)}
                  >
                     Replace
                  </Button>
                  <Button
                     color='error'
                     startIcon={<DeleteIcon />}
                     onClick={() => handleDeleteFile(params.value)}
                  >
                     Delete
                  </Button>
               </Stack>
            </Stack>
         ),
      },
   ];

   initialState = {
      sorting: {
         sortModel: [{ field: "PlayerNameLastFirst", sort: "asc" }],
      },
   };

   initialStatePlayers = {
      sorting: {
         sortModel: [{ field: "PlayerNameLastFirst", sort: "asc" }],
      },
      columns: {
         columnVisibilityModel: {
            id: true,
            hasPhoto: false,
         },
      },
   };

   const onFileUpload = () => {
      setReplaceFilename("");
      setLastFileUpdate(Date.now());
   };

   useEffect(() => {
      getPlayerList();
      getFileList();
   }, []);

   useEffect(() => {
      if (lastFileUpdate) {
         refreshPlayerPhotos();
         getFileList();
      }
   }, [lastFileUpdate]);

   // useEffect(() => {
   //    console.log(fileList);
   // }, [fileList]);

   const handleUpdatePlayerPhoto = (playerId, fileName) => {
      updatePlayerPhoto(playerId, fileName);
   };

   const handleDeleteFile = (fileName) => {
      if (fileName) {
         if (
            window.confirm(
               "Are you sure you want to delete '" + fileName + "'?"
            )
         ) {
            deleteFile(fileName);
         }
      }
   };

   return (
      <Box>
         <Stack direction='row' spacing={2} alignItems='center' sx={{ mb: 2 }}>
            <Typography
               variant='h4'
               component='div'
               sx={{
                  whiteSpace: "nowrap",
                  textTransform: "uppercase",
               }}
            >
               Tournament Players
            </Typography>

            {parent != "producer" && (
               <Box sx={{ width: "100%" }}>
                  <Uploader callback={onFileUpload} />
               </Box>
            )}

            <Button
               variant={hasPhotoFilter === "" ? "contained" : "outlined"}
               color={hasPhotoFilter == "" ? "success" : "secondary"}
               onClick={() => setHasPhotoFilter("")}
            >
               ALL
            </Button>
            <Button
               sx={{ width: 200 }}
               variant={hasPhotoFilter === "true" ? "contained" : "outlined"}
               color={hasPhotoFilter === "true" ? "success" : "secondary"}
               onClick={() => setHasPhotoFilter("true")}
            >
               ASSOCIATED
            </Button>
            <Button
               sx={{ width: 200 }}
               variant={hasPhotoFilter === "false" ? "contained" : "outlined"}
               color={hasPhotoFilter === "false" ? "success" : "secondary"}
               onClick={() => setHasPhotoFilter("false")}
            >
               MISSING
            </Button>
         </Stack>

         <Box sx={{ height: 850, width: "100%" }}>
            {playerList.length > 0 && playerList && fileList && (
               <>
                  {hasPhotoFilter ? (
                     <DataGrid
                        density='compact'
                        getRowId={(row) => row.id}
                        initialState={initialStatePlayers}
                        sx={{ backgroundColor: "#efefef" }}
                        rows={findMissing(playerList, fileList)}
                        rowHeight={150}
                        columns={columnsPlayers}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                           setPageSize(newPageSize)
                        }
                        filterModel={{
                           items: [
                              {
                                 columnField: "hasPhoto",
                                 operatorValue: "equals",
                                 value: hasPhotoFilter,
                              },
                           ],
                        }}
                        rowsPerPageOptions={[20, 100]}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                           toolbar: {
                              printOptions: {
                                 disableToolbarButton: true,
                              },
                           },
                        }}
                        disableSelectionOnClick
                     />
                  ) : (
                     <DataGrid
                        density='compact'
                        getRowId={(row) => row.id}
                        initialState={initialStatePlayers}
                        sx={{ backgroundColor: "#efefef" }}
                        rows={findMissing(playerList, fileList)}
                        rowHeight={150}
                        columns={columnsPlayers}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                           setPageSize(newPageSize)
                        }
                        rowsPerPageOptions={[20, 100]}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                           toolbar: {
                              printOptions: {
                                 disableToolbarButton: true,
                              },
                           },
                        }}
                        disableSelectionOnClick
                     />
                  )}
               </>
            )}

            <ReplaceModal
               fileName={replaceFilename}
               open={replaceFilename ? true : false}
               callback={onFileUpload}
            />
         </Box>

         {/* <Stack direction='row' justifyContent='center' alignItems='center'>
            <Box
               sx={{
                  width: "100%",
               }}
            >
               <Typography
                  variant='h4'
                  component='div'
                  sx={{
                     textTransform: "uppercase",
                     width: "100%",
                  }}
               >
                  Photo Library
               </Typography>

               <Typography
                  variant='h5'
                  component='div'
                  sx={{
                     textTransform: "uppercase",
                     width: "100%",
                     mb: 2,
                  }}
               >
                  Master library of all player photos
               </Typography>
            </Box>
         </Stack>

         <Box sx={{ height: 600, width: "100%" }}>
            {fileList.length > 0 && playerList && (
               <DataGrid
                  getRowId={(row) => row.fileName}
                  initialState={initialState}
                  sx={{ backgroundColor: "#efefef" }}
                  rows={getUserInfo(fileList)}
                  rowHeight={150}
                  columns={columns}
                  pageSize={pageSizeLibrary}
                  onPageSizeChange={(newPageSizeLibrary) =>
                     setPageSizeLibrary(newPageSizeLibrary)
                  }
                  rowsPerPageOptions={[20, 100]}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                     toolbar: {
                        printOptions: {
                           disableToolbarButton: true,
                        },
                     },
                  }}
                  disableSelectionOnClick
               />
            )}
         </Box> */}

         {notification && (
            <NotificationWidget
               notification={notification.message}
               key={"notification" + notification.date}
            />
         )}
      </Box>
   );
}
