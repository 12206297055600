import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import CheckActive from "./CheckActive";
import PlayerSelect from "./PlayerSelect";

import ManaIcons from "../../components/decklists/ManaIcons";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function PlayerDecklists({ data }) {
   const theme = useTheme();

   return (
      <>
         {data && (
            <Box
               sx={{
                  width: "100%",
               }}
            >
               <Box>
                  <Typography
                     variant='h4profiledecklist'
                     component='div'
                     sx={{ mb: 2 }}
                  >
                     {data.Archetype}
                  </Typography>

                  <Box>
                     <ManaIcons
                        center
                        width={65}
                        mana={{
                           IsWhite: data.IsWhite,
                           IsBlue: data.IsBlue,
                           IsBlack: data.IsBlack,
                           IsRed: data.IsRed,
                           IsGreen: data.IsGreen,
                           IsColorless: data.IsColorless,
                        }}
                     />
                  </Box>
               </Box>
            </Box>
         )}
      </>
   );
}
