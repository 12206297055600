import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useTheme } from "@mui/material/styles";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function ScorebarLifetotal({
   playerPosition,
   lifetotal,
   topBottom,
}) {
   const theme = useTheme();

   return (
      <Box
         sx={{
            position: "absolute",
            left: "calc(50% - 70px)",
            width: "auto",
            [topBottom]: 4,
            height: 84,
         }}
      >
         <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            sx={{ height: 92 }}
         >
            <Box
               sx={{
                  width: "auto",
                  minWidth: 140,
                  height: 92,
                  textAlign: "center",
                  clipPath: "inset(0% 0% 10% 0% round 26px)",
                  backgroundImage:
                     "url(" +
                     SERVER_URL +
                     "/images/ui/bkgnd-texture-score.png)",
                  backgroundSize: "cover",
               }}
            >
               <Typography
                  variant='fmLifeTotal'
                  component='div'
                  sx={{
                     pt: 0.8,
                     height: 82,
                     lineHeight: 1,
                     backgroundColor: "#1a1a1a",
                     clipPath: "inset(6px round 20px)",
                     position: "relative",
                     boxSizing: "border-box",
                  }}
               >
                  <Box
                     key={"p" + playerPosition + "lifetotal" + lifetotal}
                     component={motion.div}
                     initial={{
                        opacity: 0,
                        scale: 2,
                     }}
                     animate={{
                        opacity: 1,
                        scale: 1,
                        transition: { delay: 0.2 },
                     }}
                     exit={{ opacity: 0 }}
                     sx={{
                        width: "100%",
                        position: "absolute",
                     }}
                  >
                     {lifetotal}
                  </Box>
               </Typography>
            </Box>
         </Stack>
      </Box>
   );
}
