import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function useLEDPlayers() {
   const socket = useContext(SocketContext);

   const [title, setTitle] = useState("");
   const [allPlayers, setAllPlayers] = useState([]);
   const [LEDPlayers, setLEDPlayers] = useState();

   const [show, setShow] = useState(true);
   const [runTimestamp, setRunTimestamp] = useState(Date.now());

   const handlers = useMemo(
      () => ({
         getAllPlayers: () => {
            socket.emit("getAllPlayers", (response) => {
               if (response) {
                  setAllPlayers(response);
               }
            });
         },
         getLEDPlayers: () => {
            socket.emit("getLEDPlayers", (response) => {
               if (response) {
                  setTitle(response.title);
                  setLEDPlayers(response.players);
               }
            });
         },
         updateTitle: (id, data) => {
            socket.emit("updateGlobal", id, data);
         },
         update: (id, playerId) => {
            socket.emit("updateLEDPlayers", { id: id, playerId: playerId });
         },
         load: () => {
            socket.emit("LEDPlayersViewerLoad");
         },
         showHide: (action) => {
            socket.emit("LEDPlayersViewerShowHide", action);
         },
         subscribeLEDPlayersViewer: () => {
            socket.emit("subscribeLEDPlayersViewer", (response) => {
               console.log(response);
            });

            socket.on("LEDPlayersViewerLoad", (players) => {
               handlers.getLEDPlayers();
            });

            socket.on("LEDPlayersViewerShowHide", (action) => {
               switch (action) {
                  case "show":
                     setRunTimestamp(Date.now());
                     setShow(true);
                     break;
                  case "hide":
                     setShow(false);
                     break;
                  case "toggle":
                     setShow((prevState) => {
                        return !prevState;
                     });
                     break;
               }
            });
         },
      }),
      []
   );

   return [title, allPlayers, LEDPlayers, show, runTimestamp, handlers];
}
