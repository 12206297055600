import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { saveAs } from "file-saver";

import DownloadIcon from "@mui/icons-material/Download";
import ManaIconsFormatted from "../decklists/ManaIconsFormatted";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function DataGridContainer({
   data,
   height,
   type,
   disableColumnFilter,
   handleLoadIframe,
   callback,
   preview,
}) {
   let columns;
   let initialState;

   columns = [
      {
         field: "id",
         headerName: "Card Id",
         editable: false,
         width: 50,
         filterable: false,
      },
      {
         field: "FileName",
         headerName: "Image",
         editable: false,
         width: 120,
         filterable: false,
         renderCell: (params) => (
            <Box
               sx={{
                  height: "100%",
                  p: 1,
                  backgroundColor: "#fff",
               }}
            >
               <img
                  src={
                     SERVER_URL +
                     "/cardimages/thumbs/" +
                     encodeURIComponent(params.row.FileName) +
                     ".jpg"
                  }
                  height='100%'
               />
            </Box>
         ),
      },
      {
         field: "CardName",
         headerName: "Card Name",
         editable: false,
         width: 300,
      },
      {
         field: "CardManaCost",
         headerName: "Mana Cost",
         editable: false,
         width: 100,
         renderCell: (params) => (
            <ManaIconsFormatted iconWidth={15} mana={params.row.CardManaCost} />
         ),
      },
      {
         field: "controls",
         headerName: "Controls",
         editable: false,
         width: 200,
         filterable: false,
         renderCell: (params) => (
            <Button
               variant='outlined'
               onClick={() =>
                  handleCallback({
                     type: "image",
                     value: params.row.FileName,
                     card: params.row,
                  })
               }
            >
               Load
            </Button>
         ),
      },
   ];

   initialState = {
      sorting: {
         sortModel: [{ field: "Name", sort: "asc" }],
      },
      columns: {
         columnVisibilityModel: {
            id: false,
            CardManaCost: false,
         },
      },
   };

   const handleCallback = (card) => {
      callback(card);
   };

   const handlePreview = (e) => {
      preview(e.target.id);
   };

   const handleDownload = (e) => {
      saveAs(SERVER_URL + "/cardimages/png/" + e.target.value, e.target.value);
   };

   return (
      <>
         <Box sx={{ height: height ? height : 400, width: "100%" }}>
            <DataGrid
               getRowId={(row) => row.ID}
               initialState={initialState}
               sx={{ backgroundColor: "#efefef" }}
               rows={data}
               rowHeight={
                  type === "cardOverview" || type === "cardOverlay" ? 120 : 40
               }
               columns={columns}
               rowsPerPageOptions={[100, 200, 300, 1000, 5000]}
               components={{ Toolbar: GridToolbar }}
               componentsProps={{
                  toolbar: {
                     csvOptions: { disableToolbarButton: true },
                     printOptions: {
                        disableToolbarButton: true,
                     },
                  },
               }}
            />
         </Box>
      </>
   );
}
