import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useTheme } from "@mui/material/styles";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function PlayerStandings({
   players,
   playerPosition,
   gameState,
}) {
   const theme = useTheme();

   const [standings, setStandings] = useState();

   useEffect(() => {
      const playerData = _.find(players, {
         id: gameState["PlayerIdP" + playerPosition],
      });
      if (playerData && playerData.Standings) {
         setStandings(playerData.Standings);
      }
   }, [players]);

   return <>{standings && <>{standings.Record}</>}</>;
}
