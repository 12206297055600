import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import WrapperViewer from "../../components/layout/WrapperViewer";
import Top8Player from "../../components/layout/Top8Player";

//HOOKS
import useBracketHandler from "../../hooks/useBracketHandler";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function Top8({ parent = "" }) {
   let navigate = useNavigate();

   const variants = {
      enter: {
         opacity: 1,
         transition: {
            easing: "easeOut",
            duration: 1,
            staggerChildren: 0.08,
         },
      },
      exit: {
         opacity: 0,
      },
   };

   const lineVariants = {
      enter: {
         opacity: 1,
         transition: {
            easing: "easeOut",
         },
      },
      exit: {
         opacity: 0,
      },
   };

   // USE HOOK
   const [
      bracket,
      visible,
      standings,
      runTimestamp,
      { updateBracket, getTop8, bracketViewerLoad, subscribeBracketViewer },
   ] = useBracketHandler();

   useEffect(() => {
      getTop8();
      subscribeBracketViewer();
   }, []);

   useEffect(() => {
      // console.log(standings);
   }, [standings]);

   useEffect(() => {
      // console.log(bracket);
   }, [bracket]);

   const playerData = (PlayerId) => {
      let player = {};

      if (standings) {
         player = _.find(standings, function (o) {
            return o.PlayerId === PlayerId;
         });
      }

      return player;
   };

   return (
      <WrapperViewer>
         <Box
            sx={{
               position: "absolute",
               top: 40,
               right: 100,
               height: 200,
            }}
         >
            <img src={SERVER_URL + "/images/ui/logo-800.png"} width='125' />
         </Box>

         <Typography
            variant='h1Viewer'
            sx={{ position: "absolute", left: 110, top: 95 }}
         >
            Top 8 Bracket
         </Typography>

         <svg class='svg' style={{ opacity: 0 }}>
            <clipPath id='wins-clip-path' clipPathUnits='objectBoundingBox'>
               <path d='M1,1 H0.053 S0.04,0.728,0.216,0.396 C0.367,0.114,0.507,0,0.507,0 H1'></path>
            </clipPath>
         </svg>

         <Box key={runTimestamp}>
            <AnimatePresence>
               {bracket && standings && standings.length > 0 && (
                  <Box
                     sx={{
                        position: "absolute",
                        left: 110,
                        top: 210,
                        height: 800,
                     }}
                     variants={variants}
                     initial='exit'
                     animate={visible ? "enter" : "exit"}
                     component={motion.div}
                  >
                     <Stack
                        spacing={0}
                        direction='row'
                        alignItems='center'
                        sx={{ height: "100%" }}
                     >
                        {/* QUARTERFINALS */}
                        <Typography
                           variant='h6Viewer'
                           sx={{ position: "absolute", top: -15 }}
                        >
                           Quarterfinals
                        </Typography>

                        <Stack spacing={4} sx={{ width: 535 }}>
                           <Stack spacing={0}>
                              {bracket.qf.map((row, i) => (
                                 <>
                                    {i < 2 && (
                                       <Top8Player
                                          player={row && playerData(row.player)}
                                          index={i}
                                          wins={row && row.wins}
                                          winner={row && row.winner}
                                          bracketPoints={
                                             bracket.qf[0] && bracket.qf[1]
                                                ? bracket.qf[0].wins +
                                                  bracket.qf[1].wins
                                                : null
                                          }
                                       />
                                    )}
                                 </>
                              ))}
                           </Stack>

                           <Stack spacing={0}>
                              {bracket.qf.map((row, i) => (
                                 <React.Fragment key={"qf" + i}>
                                    {i >= 2 && i < 4 && (
                                       <Top8Player
                                          player={row && playerData(row.player)}
                                          index={i}
                                          wins={row && row.wins}
                                          winner={row && row.winner}
                                          bracketPoints={
                                             bracket.qf[2] && bracket.qf[3]
                                                ? bracket.qf[2].wins +
                                                  bracket.qf[3].wins
                                                : null
                                          }
                                       />
                                    )}
                                 </React.Fragment>
                              ))}
                           </Stack>

                           <Stack spacing={0}>
                              {bracket.qf.map((row, i) => (
                                 <React.Fragment key={"qf" + i}>
                                    {i >= 4 && i < 6 && (
                                       <Top8Player
                                          player={row && playerData(row.player)}
                                          index={i}
                                          wins={row && row.wins}
                                          winner={row && row.winner}
                                          bracketPoints={
                                             bracket.qf[4] && bracket.qf[5]
                                                ? bracket.qf[4].wins +
                                                  bracket.qf[5].wins
                                                : null
                                          }
                                       />
                                    )}
                                 </React.Fragment>
                              ))}
                           </Stack>

                           <Stack spacing={0}>
                              {bracket.qf.map((row, i) => (
                                 <React.Fragment key={"qf" + i}>
                                    {i >= 6 && (
                                       <Top8Player
                                          player={row && playerData(row.player)}
                                          index={i}
                                          wins={row && row.wins}
                                          winner={row && row.winner}
                                          bracketPoints={
                                             bracket.qf[6] && bracket.qf[7]
                                                ? bracket.qf[6].wins +
                                                  bracket.qf[7].wins
                                                : null
                                          }
                                       />
                                    )}
                                 </React.Fragment>
                              ))}
                           </Stack>
                        </Stack>

                        {/* BARS */}
                        <Box
                           sx={{ width: 30 }}
                           variants={lineVariants}
                           component={motion.div}
                        >
                           <Stack sx={{ width: 30, height: "100%" }}>
                              <Box
                                 sx={{
                                    height: 194,
                                    borderTop: "1px solid #fff",
                                    borderRight: "1px solid #fff",
                                    borderBottom: "1px solid #fff",
                                 }}
                              />
                              <Box sx={{ height: 190 }} />
                              <Box
                                 sx={{
                                    height: 194,
                                    borderTop: "1px solid #fff",
                                    borderRight: "1px solid #fff",
                                    borderBottom: "1px solid #fff",
                                 }}
                              />
                           </Stack>
                        </Box>

                        <Box
                           sx={{ width: 30 }}
                           variants={lineVariants}
                           component={motion.div}
                        >
                           <Box
                              sx={{
                                 height: 385,
                                 width: 30,
                                 borderTop: "1px solid #fff",
                                 borderBottom: "1px solid #fff",
                              }}
                           />
                        </Box>

                        {/* SEMIFINALS */}
                        <Box sx={{ width: 535 }}>
                           <Typography
                              variant='h6Viewer'
                              sx={{ position: "absolute", top: 80 }}
                           >
                              Semifinals
                           </Typography>

                           <Stack
                              spacing={4}
                              sx={{ height: 545 }}
                              alignItems='center'
                           >
                              <Stack spacing={0} sx={{ height: "65%" }}>
                                 {bracket.sf.map((row, i) => (
                                    <React.Fragment key={"sf" + i}>
                                       {i < 2 && (
                                          <Top8Player
                                             player={
                                                row && playerData(row.player)
                                             }
                                             index={i}
                                             wins={row && row.wins}
                                             winner={row && row.winner}
                                             bracketPoints={
                                                bracket.sf[0] && bracket.sf[1]
                                                   ? bracket.sf[0].wins +
                                                     bracket.sf[1].wins
                                                   : null
                                             }
                                          />
                                       )}
                                    </React.Fragment>
                                 ))}
                              </Stack>

                              <Stack spacing={0}>
                                 {bracket.sf.map((row, i) => (
                                    <React.Fragment key={"sf" + i}>
                                       {i >= 2 && (
                                          <Top8Player
                                             player={
                                                row && playerData(row.player)
                                             }
                                             index={i}
                                             wins={row && row.wins}
                                             winner={row && row.winner}
                                             bracketPoints={
                                                bracket.sf[2] && bracket.sf[3]
                                                   ? bracket.sf[2].wins +
                                                     bracket.sf[3].wins
                                                   : null
                                             }
                                          />
                                       )}
                                    </React.Fragment>
                                 ))}
                              </Stack>
                           </Stack>
                        </Box>

                        {/* BARS */}
                        <Box
                           sx={{ width: 30 }}
                           variants={lineVariants}
                           component={motion.div}
                        >
                           <Stack sx={{ width: 30, height: "100%" }}>
                              <Box
                                 sx={{
                                    height: 386,
                                    borderTop: "1px solid #fff",
                                    borderRight: "1px solid #fff",
                                    borderBottom: "1px solid #fff",
                                 }}
                              />
                           </Stack>
                        </Box>

                        <Box
                           sx={{ width: 30 }}
                           variants={lineVariants}
                           component={motion.div}
                        >
                           <Box
                              sx={{
                                 height: 1,
                                 width: 30,
                                 borderTop: "1px solid #fff",
                              }}
                           />
                        </Box>

                        {/* FINALS */}
                        <Box sx={{ width: 535 }}>
                           <Typography
                              variant='h6Viewer'
                              sx={{ position: "absolute", top: 270 }}
                           >
                              Finals
                           </Typography>

                           <Stack spacing={4} alignItems='center'>
                              <Stack spacing={0}>
                                 {bracket.f.map((row, i) => (
                                    <React.Fragment key={"f" + i}>
                                       <Top8Player
                                          player={row && playerData(row.player)}
                                          index={i}
                                          wins={row && row.wins}
                                          winner={row && row.winner}
                                          bracketPoints={
                                             bracket.f[0] && bracket.f[1]
                                                ? bracket.f[0].wins +
                                                  bracket.f[1].wins
                                                : null
                                          }
                                       />
                                    </React.Fragment>
                                 ))}
                              </Stack>
                           </Stack>
                        </Box>
                     </Stack>
                  </Box>
               )}
            </AnimatePresence>
         </Box>
      </WrapperViewer>
   );
}
