import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import LEDWrapperViewer from "../../components/layout/LEDWrapperViewer";
import LEDTop8Player from "../../components/layout/LEDTop8Player";

//HOOKS
import useLEDBracketHandler from "../../hooks/useLEDBracketHandler";

const SERVER_URL = window.location.protocol + "//" + window.location.host;

export default function LEDBracketViewer({ parent = "" }) {
   let navigate = useNavigate();

   const variants = {
      enter: {
         opacity: 1,
         transition: {
            easing: "easeOut",
            duration: 1,
            staggerChildren: 0.08,
         },
      },
      exit: {
         opacity: 0,
      },
   };

   const lineVariants = {
      enter: {
         opacity: 1,
         transition: {
            easing: "easeOut",
         },
      },
      exit: {
         opacity: 0,
      },
   };

   // USE HOOK
   const [
      bracket,
      visible,
      standings,
      runTimestamp,
      { showHide, getTop8, subscribeLEDBracketViewer },
   ] = useLEDBracketHandler();

   useEffect(() => {
      getTop8();
      subscribeLEDBracketViewer();
   }, []);

   useEffect(() => {
      // console.log(standings);
   }, [standings]);

   useEffect(() => {
      // console.log(bracket);
   }, [bracket]);

   const playerData = (PlayerId) => {
      let player = {};

      if (standings) {
         player = _.find(standings, function (o) {
            return o.PlayerId === PlayerId;
         });
      }

      return player;
   };

   return (
      <>
         <svg class='svg' style={{ opacity: 0, position: "absolute" }}>
            <clipPath id='wins-clip-path' clipPathUnits='objectBoundingBox'>
               <path d='M1,1 H0 S-0.017,0.728,0.227,0.396 C0.434,0.114,0.628,0,0.628,0 H1'></path>
            </clipPath>
         </svg>
         <LEDWrapperViewer>
            <Box
               sx={{
                  position: "absolute",
                  top: 100,
                  left: "50%",
                  transform: "translateX(-50%)",
               }}
            >
               <img src={SERVER_URL + "/images/ui/logo-800.png"} width='180' />
            </Box>
            <Box
               sx={{
                  position: "absolute",
                  bottom: "-160px",
                  left: "50%",
                  transform: "translateX(-50%)",
               }}
            >
               <img src={SERVER_URL + "/images/led-trophy.png"} width='250' />
            </Box>

            <AnimatePresence>
               {bracket && standings && standings.length > 0 && (
                  <Box
                     variants={variants}
                     initial='exit'
                     animate={visible ? "enter" : "exit"}
                     component={motion.div}
                  >
                     <Stack
                        spacing={"60px"}
                        direction='row'
                        alignItems='center'
                        sx={{
                           height: "1080px",
                           width: "100%",
                           px: "30px",
                           m: 0,
                        }}
                     >
                        <Stack
                           spacing={10}
                           sx={{ position: "relative", width: "100%" }}
                        >
                           {/* QUARTERFINALS */}
                           <Typography
                              variant='h5Viewer'
                              sx={{
                                 textAlign: "center",
                                 width: "100%",
                                 position: "absolute",
                                 textTransform: "uppercase",
                                 fontSize: "2em",
                              }}
                           >
                              Quarterfinals
                           </Typography>

                           {/* <Box
                              sx={{
                                 position: "absolute",
                                 top: 80,
                                 width: 30,
                                 right: -30,
                              }}
                              variants={lineVariants}
                              component={motion.div}
                           >
                              <Box
                                 sx={{
                                    height: 240,
                                    borderTop: "1px solid #fff",
                                    borderRight: "1px solid #fff",
                                    borderBottom: "1px solid #fff",
                                 }}
                              />
                           </Box> */}

                           <Stack spacing={0}>
                              {bracket.qf.map((row, i) => (
                                 <>
                                    {i < 2 && (
                                       <LEDTop8Player
                                          player={row && playerData(row.player)}
                                          index={i}
                                          wins={row && row.wins}
                                          winner={row && row.winner}
                                          bracketPoints={
                                             bracket.qf[0] && bracket.qf[1]
                                                ? bracket.qf[0].wins +
                                                  bracket.qf[1].wins
                                                : null
                                          }
                                       />
                                    )}
                                 </>
                              ))}
                           </Stack>
                           <Stack spacing={0}>
                              {bracket.qf.map((row, i) => (
                                 <React.Fragment key={"qf" + i}>
                                    {i >= 2 && i < 4 && (
                                       <LEDTop8Player
                                          player={row && playerData(row.player)}
                                          index={i}
                                          wins={row && row.wins}
                                          winner={row && row.winner}
                                          bracketPoints={
                                             bracket.qf[2] && bracket.qf[3]
                                                ? bracket.qf[2].wins +
                                                  bracket.qf[3].wins
                                                : null
                                          }
                                       />
                                    )}
                                 </React.Fragment>
                              ))}
                           </Stack>
                        </Stack>
                        <Stack
                           spacing={10}
                           sx={{ position: "relative", width: "100%" }}
                        >
                           {/* SEMIFINALS */}
                           <Typography
                              variant='h5Viewer'
                              sx={{
                                 width: "100%",
                                 textAlign: "center",
                                 position: "absolute",
                                 textTransform: "uppercase",
                                 fontSize: "2em",
                              }}
                           >
                              Semifinals
                           </Typography>

                           {/* <Box
                              sx={{
                                 position: "absolute",
                                 top: 80,
                                 width: 30,
                                 left: -30,
                              }}
                              variants={lineVariants}
                              component={motion.div}
                           >
                              <Box
                                 sx={{
                                    height: 1,
                                    borderTop: "1px solid #fff",
                                 }}
                              />
                           </Box>

                           <Box
                              sx={{
                                 position: "absolute",
                                 top: 80,
                                 width: 30,
                                 right: -30,
                              }}
                              variants={lineVariants}
                              component={motion.div}
                           >
                              <Box
                                 sx={{
                                    height: 1,
                                    borderTop: "1px solid #fff",
                                 }}
                              />
                           </Box> */}

                           <Stack spacing={0}>
                              {bracket.sf.map((row, i) => (
                                 <React.Fragment key={"sf" + i}>
                                    {i < 2 && (
                                       <LEDTop8Player
                                          player={row && playerData(row.player)}
                                          index={i}
                                          wins={row && row.wins}
                                          winner={row && row.winner}
                                          bracketPoints={
                                             bracket.sf[0] && bracket.sf[1]
                                                ? bracket.sf[0].wins +
                                                  bracket.sf[1].wins
                                                : null
                                          }
                                       />
                                    )}
                                 </React.Fragment>
                              ))}
                           </Stack>
                        </Stack>

                        <Stack
                           spacing={10}
                           sx={{ position: "relative", width: "100%" }}
                        >
                           {/* FINALS */}
                           <Typography
                              variant='h5Viewer'
                              sx={{
                                 width: "100%",
                                 textAlign: "center",
                                 position: "absolute",
                                 textTransform: "uppercase",
                                 fontSize: "2em",
                              }}
                           >
                              Finals
                           </Typography>

                           {/* <Box
                              sx={{
                                 position: "absolute",
                                 top: 80,
                                 width: 30,
                                 left: -30,
                              }}
                              variants={lineVariants}
                              component={motion.div}
                           >
                              <Box
                                 sx={{
                                    height: 1,
                                    borderTop: "1px solid #fff",
                                 }}
                              />
                           </Box>

                           <Box
                              sx={{
                                 position: "absolute",
                                 top: 80,
                                 width: 30,
                                 right: -30,
                              }}
                              variants={lineVariants}
                              component={motion.div}
                           >
                              <Box
                                 sx={{
                                    height: 1,
                                    borderTop: "1px solid #fff",
                                 }}
                              />
                           </Box> */}

                           <Stack spacing={0}>
                              {bracket.f.map((row, i) => (
                                 <React.Fragment key={"f" + i}>
                                    <LEDTop8Player
                                       player={row && playerData(row.player)}
                                       index={i}
                                       wins={row && row.wins}
                                       winner={row && row.winner}
                                       bracketPoints={
                                          bracket.f[0] && bracket.f[1]
                                             ? bracket.f[0].wins +
                                               bracket.f[1].wins
                                             : null
                                       }
                                    />
                                 </React.Fragment>
                              ))}
                           </Stack>
                        </Stack>

                        <Stack
                           spacing={10}
                           sx={{ position: "relative", width: "100%" }}
                        >
                           {/* SEMIFINALS */}
                           <Typography
                              variant='h5Viewer'
                              sx={{
                                 width: "100%",
                                 textAlign: "center",
                                 position: "absolute",
                                 textTransform: "uppercase",
                                 fontSize: "2em",
                              }}
                           >
                              Semifinals
                           </Typography>
                           {/* <Box
                              sx={{
                                 position: "absolute",
                                 top: 80,
                                 width: 30,
                                 left: -30,
                              }}
                              variants={lineVariants}
                              component={motion.div}
                           >
                              <Box
                                 sx={{
                                    height: 1,
                                    borderTop: "1px solid #fff",
                                 }}
                              />
                           </Box>

                           <Box
                              sx={{
                                 position: "absolute",
                                 top: 80,
                                 width: 30,
                                 right: -30,
                              }}
                              variants={lineVariants}
                              component={motion.div}
                           >
                              <Box
                                 sx={{
                                    height: 1,
                                    borderTop: "1px solid #fff",
                                 }}
                              />
                           </Box> */}
                           <Stack spacing={0}>
                              {bracket.sf.map((row, i) => (
                                 <React.Fragment key={"sf" + i}>
                                    {i >= 2 && (
                                       <LEDTop8Player
                                          player={row && playerData(row.player)}
                                          index={i}
                                          wins={row && row.wins}
                                          winner={row && row.winner}
                                          bracketPoints={
                                             bracket.sf[2] && bracket.sf[3]
                                                ? bracket.sf[2].wins +
                                                  bracket.sf[3].wins
                                                : null
                                          }
                                       />
                                    )}
                                 </React.Fragment>
                              ))}
                           </Stack>
                        </Stack>
                        <Stack
                           spacing={10}
                           sx={{ position: "relative", width: "100%" }}
                        >
                           {/* QUARTERFINALS */}
                           <Typography
                              variant='h5Viewer'
                              sx={{
                                 width: "100%",
                                 textAlign: "center",
                                 position: "absolute",
                                 textTransform: "uppercase",
                                 fontSize: "2em",
                              }}
                           >
                              Quarterfinals
                           </Typography>

                           {/* <Box
                              sx={{
                                 position: "absolute",
                                 top: 80,
                                 width: 30,
                                 left: -30,
                              }}
                              variants={lineVariants}
                              component={motion.div}
                           >
                              <Box
                                 sx={{
                                    height: 240,
                                    borderTop: "1px solid #fff",
                                    borderLeft: "1px solid #fff",
                                    borderBottom: "1px solid #fff",
                                 }}
                              />
                           </Box> */}

                           <Stack spacing={0}>
                              {bracket.qf.map((row, i) => (
                                 <>
                                    {i >= 4 && i < 6 && (
                                       <LEDTop8Player
                                          player={row && playerData(row.player)}
                                          index={i}
                                          wins={row && row.wins}
                                          winner={row && row.winner}
                                          bracketPoints={
                                             bracket.qf[4] && bracket.qf[5]
                                                ? bracket.qf[4].wins +
                                                  bracket.qf[5].wins
                                                : null
                                          }
                                       />
                                    )}
                                 </>
                              ))}
                           </Stack>
                           <Stack spacing={0}>
                              {bracket.qf.map((row, i) => (
                                 <React.Fragment key={"qf" + i}>
                                    {i >= 6 && i < 8 && (
                                       <LEDTop8Player
                                          player={row && playerData(row.player)}
                                          index={i}
                                          wins={row && row.wins}
                                          winner={row && row.winner}
                                          bracketPoints={
                                             bracket.qf[6] && bracket.qf[7]
                                                ? bracket.qf[6].wins +
                                                  bracket.qf[7].wins
                                                : null
                                          }
                                       />
                                    )}
                                 </React.Fragment>
                              ))}
                           </Stack>
                        </Stack>
                     </Stack>
                  </Box>
               )}
            </AnimatePresence>
         </LEDWrapperViewer>
      </>
   );
}
